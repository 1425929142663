import axios from './axios-service';

async function getAll(body) {
  let data = await axios.get(`/admin/report/?${body ?? ''}`);
  return data
}

async function getSessionReportAll(body) {
  let data = await axios.get(`/admin/report/getAppointmentReports?${body ?? ''}`);
  return data
}

async function exportSessionReport(body) {
  let data = await axios.get(`/admin/report/exportAppointmentReports?type=${body}`);
  return data
}

async function exportExpertReport(type) {
  let data = await axios.get(`/admin/report/exportExperts?type=${type}`);
  return data
}

export default {
  getAll,
  getSessionReportAll,
  exportSessionReport,
  exportExpertReport
}
