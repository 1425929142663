import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { checkPermission} from 'helpers/supportFunction';
import ExpertAmbassadorDatatable from 'components/sidekick-ambassadors/datatable';
import toast from 'helpers/toast';

class Appointment extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '2',
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("ambassador", "read")) {
      toast.warning("You do not have permission to manage ambassadors", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sidekick Ambassadors | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody style={{minHeight: '600px'}}>
                <Row>
                  <Col xl="12" className="d-flex align-items-end pb-2">
                    <h4 className="text-primary mb-0">Manage Sidekick Ambassadors</h4>
                  </Col>
                  <ExpertAmbassadorDatatable/>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Appointment
