import React, { Component } from "react"
import {
  Row,
  Col,
  Button,
  Label,
  Input,
  Modal,
  ModalHeader,
} from "reactstrap"
import { Link } from "react-router-dom"
import expertService from "../../services/expert"
import { checkPermission, customUrlParams } from "helpers/supportFunction"
import moment from "moment"
import ModalConfirm from "components/modals/modalConfirm"
import toast from "helpers/toast"
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

class RejectedRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      experts: [],
      isReject: false,
      isApprove: false,
      reasonReject: "",
      expertAction: null,
      search: "",
      dxStore: this.createDxStore()
    }
  }

  componentDidMount = async () => {
    if (localStorage.getItem("userSidekick") && !checkPermission("expert", "read")) {
      toast.warning( "You do not have permission to manage sidekicks", "Invalid Permission")
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      await this.loadData()
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = "is_requesting=1&status=rejected"
        if (this.state.search) {
          url_params += `&search=${this.state.search}`
        }
        let params = customUrlParams(loadOptions, url_params, 'name', 'asc')
        var response = await expertService.getAll(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  linkEdit = (e) => {
    let expert = e.data;
    return (
      <>
        <Link to={`/sidekicks-request/${expert.id}/detail`}>
          <Button className="mx-1 btn-custom-width">View Detail</Button>
        </Link>
      </>
    )
  }

  formatCreatedAt = (e) => {
    let expert = e.data;
    return (
      <span>
        {expert.created_at
          ? moment(expert.created_at).format(
              process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT
            )
          : ""}
      </span>
    )
  }

  formatRejectedAt = (e) => {
    let expert = e.data;
    return (
      <span>
        {expert.rejected_at
          ? moment(expert.rejected_at).format(
              process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT
            )
          : ""}
      </span>
    )
  }

  customUser = (e) => {
    let expert = e.data;
    return (
      <Link to={`/sidekicks-request/${expert.id}/detail`}
      >
        {expert.user_name}
      </Link>
    )
  }

  customName = (e) => {
    let expert = e.data;
    return (
      <Link to={`/sidekicks-request/${expert.id}/detail`}
      >
        {expert.name}
      </Link>
    )
  }

 

  onCloseModalApprove = () => {
    this.setState({
      isApprove: false,
      expertAction: null,
    })
  }

  onActionApprove = async () => {
    let response = await expertService.approveExpert({
      expert_id: this.state.expertAction.id,
    })
    if (response.success) {
      toast.success("Approved success", "Approved")
      this.onCloseModalApprove()
      this.loadData()
    }
  }

  onCloseModalReject = () => {
    this.setState({
      isReject: false,
      reasonReject: "",
      expertAction: null,
    })
  }

  onActionReject = async () => {
    if (!this.state.reasonReject) {
      toast.warning("Please enter the reason for the reject", "Invalid")
    } else {
      let response = await expertService.unapproveExpert({
        expert_id: this.state.expertAction.id,
        reason_reject: this.state.reasonReject,
      })
      if (response.success) {
        toast.success("Rejected success", "Rejected")
        this.onCloseModalReject()
        this.loadData()
      }
    }
  }

  handleInputSearch = e => {
    this.setState({ search: e }, () => this.loadData(null, null))
  }

  render() {
    return (
      <>
        <Row>
          <Col xl="12" className="d-flex align-items-center pb-2">
            <h4 className="mb-0 text-primary">Sidekicks Rejected</h4>
          </Col>
          <Col xl="4" className="pt-2 pb-2">
            <Input
              type="search"
              id="search"
              className="form-control"
              placeholder="Search..."
              value={this.state.search}
              onChange={e => {
                this.handleInputSearch(e.target.value)
              }}
            />
          </Col>
        </Row>
        <Row className="pt-1">
          <Col xl="12">
            <DataGrid
              dataSource={this.state.dxStore}
              showBorders={true}
              remoteOperations={true}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
            >
              <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80}></Column>
              <Column dataField="name" name="name" caption="Full Name" cellRender={this.customName}></Column>
              <Column dataField="user_name" name="user_name" caption="User Name" cellRender={this.customUser}></Column>
              <Column dataField="email" name="email" caption="Email"></Column>
              <Column dataField="created_at" name="created_at" caption="Request Date" cellRender={this.formatCreatedAt} ></Column>
              <Column dataField="rejected_at" name="rejected_at" caption="Rejected At" cellRender={this.formatRejectedAt} ></Column>
              <Column dataField="reason_reject" name="reason_reject" caption="Reason Reject"></Column>
              <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'} width={120}></Column>

              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
                allowedPageSizes={[10, 25, 50, 100]}
              />
            </DataGrid>
          </Col>
        </Row>
        <Modal
          role="dialog"
          isOpen={this.state.isReject}
          autoFocus={true}
          centered
          data-toggle="modal"
          backdrop={"static"}
          toggle={() => this.onCloseModalReject()}
        >
          <div>
            <ModalHeader
              className="border-bottom-0"
              toggle={() => this.onCloseModalReject()}
            ></ModalHeader>
          </div>
          <div className="modal-body" style={{ paddingTop: "0" }}>
            <div className=" mb-4">
              <Row className=" justify-content-center">
                <Col xl="11">
                  <h4 className="text-primary text-center mb-4">
                    Confirmation Reject
                  </h4>
                  <Row>
                    <Label
                      for="reason_reject pt-3"
                      style={{ paddingLeft: "0" }}
                    >
                      Please enter the reason for the reject
                    </Label>
                    <div className="input-group rounded bg-light mb-3">
                      <Input
                        type="textarea"
                        id="reason_reject"
                        name="reason_reject"
                        className="form-control bg-transparent border-0"
                        placeholder="Reason reject"
                        value={this.state.reasonReject}
                        onChange={e => {
                          this.setState({
                            reasonReject: e.target.value,
                          })
                        }}
                      />
                    </div>

                    <Button
                      className="mt-3 d-block mx-auto"
                      color="danger"
                      type="button"
                      id="button-create"
                      disabled={
                        !this.state.reasonReject || !this.state.expertAction
                      }
                      onClick={() => {
                        this.onActionReject()
                      }}
                    >
                      Reject
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
        <ModalConfirm
          isOpen={this.state.isApprove}
          title={'Confirmation Approve'}
          message={'Are you sure approve ' + (this.state.expertAction ? 'for ' + this.state.expertAction.name : "") + '?'}
          textButton={'Approve'}
          colorButton={'primary'}
          onCloseModal={() => {
            this.onCloseModalApprove();
          }}
          onActionConfirm={ async () => {
            await this.onActionApprove()
          }}
        />
      </>
    )
  }
}

export default RejectedRequest
