import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Button
} from "reactstrap"
import Select from 'react-select'
import { Link } from "react-router-dom";
import appointmentService from "../../services/appointment"
import { 
  checkPermission, 
  customUrlParams, 
  getAppointmentStatusLabel, 
  formatAmountUs, 
  capitalizeFirstLetter,
  formatDurationTime,
  getAppointmentStatuses
} from 'helpers/supportFunction';
import moment from "moment";
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

const statuses = getAppointmentStatuses();

class TableExpertAppointments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterData: {
        status: '',
      },
      waiting: false,
      dxStore: this.createDxStore()
    }
  }

  componentDidMount = async () => {
    if (localStorage.getItem('userSidekick') && !checkPermission("appointment", "read") && this.props.expert_id) {
      toast.warning("You do not have permission to manage sessions", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      await this.loadData();
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = `&expert_id=${this.props.expert_id}`;
        if (this.state.filterData) {
          if (this.state.filterData.status) {
            url_params += `&status=${this.state.filterData.status}`;
          }
        }
        this.setState({ waiting: true })
        this.waiting = true;
        let params = customUrlParams(loadOptions, url_params, 'id', 'desc')
        var response = await appointmentService.getAppointmentsCompletedByExpert(params);
        this.setState({ waiting: false })
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          this.setState({ expertCategories: response.data});
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  linkEdit = (e) => {
    let appointment = e.data
    return (
      <Link to={`/sessions/${appointment.id}/detail`}>
        <Button className="btn-custom-width">
          View Detail
        </Button>
      </Link>
    )
  }

  formatStartTime = (e) => {
    let appointment = e.data
    return (
      <span>
        { moment(appointment.start_time).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)}
      </span>
    )
  }
   formatEndTime = (e) => {
    let appointment = e.data
    return (
      <span>
        { moment(appointment.end_time).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)}
      </span>
    )
  }

  formatPrice = (e) => {
    let appointment = e.data
    return (
      <span>
        { appointment ? formatAmountUs(appointment.price) : ''}
      </span>
    )
  }

  formatAmount = (e) => {
    let appointment = e.data
    return (
      <div className="text-right">
        { appointment ? formatAmountUs(appointment.amount) : ''}
      </div>
    )
  }

  formatMyProfit = (e) => {
    let appointment = e.data
    return (
      <div className="text-right">
        { appointment && appointment.amount_profit ? formatAmountUs(appointment.amount_profit) : ''}
      </div>
    )
  }

  formatDuration = (e) => {
    return (
      <span>
        { formatDurationTime(e.data.duration) }
      </span>
    )
  }

  customId = (e) => {
    let appointment = e.data
    return (
      <Link to={`/sessions/${appointment.id}/detail`}
      >
        { appointment.id }
      </Link>
    )
  }

  customStatus = (e) => {
    let appointment = e.data;
    return (
      <span>
      { getAppointmentStatusLabel(appointment.status) }
      </span>
    )
  }

  transactionError = (e) => {
    let appointment = e.data;
    return (
      <span className="text-danger">
      { appointment.error }
      </span>
    )
  }

  transactionStatus = (e) => {
    let appointment = e.data;
    return (
      <span className="text-danger">
        { appointment.transaction_status ?
          capitalizeFirstLetter(appointment.transaction_status).replaceAll("_", " ")
        : '' }
      </span>
    )
  }

  handleInput = (type, value) => {
    let filterData = { ...this.state.filterData }
    filterData[[type]] = value
    this.setState({ filterData: filterData }, () => {
      this.loadData();
    });
  }

  render() {
    return (
      <div className={`${this.state.waiting ? 'waitLoadingData' : ''}` }>
        <Row>
          <Col xl="3" md="6" xs="12" className="pb-2">
            <label className="mb-1 fw-bold me-2">Status:</label>
            <Select
              classNamePrefix="select"
              defaultValue={this.state.filterData.status}
              value={statuses.find((item) => {return item.value == this.state.filterData.status})}
              isClearable={false}
              isSearchable={false}
              options={statuses}  
              onChange={e => {this.handleInput("status", e.value);}}
              placeholder="Select Status"
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row className="pt-2">
          <Col xl="12">
            <DataGrid
              dataSource={this.state.dxStore}
              showBorders={true}
              remoteOperations={true}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
            >
              <Column dataField="id" name="id" caption="Session ID" alignment={'center'} width={100} cellRender={this.customId}></Column>
              <Column dataField="start_time" name="start_time" caption="Start Time" cellRender={this.formatStartTime}></Column>
              <Column dataField="end_time" name="end_time" caption="End Time" cellRender={this.formatEndTime}></Column>
              <Column dataField="duration" name="duration" caption="Duration" cellRender={this.formatDuration} alignment={'left'}></Column>
              {
                !this.state.filterData.status && <Column dataField="status" name="status" caption="Status" alignment={'center'} cellRender={this.customStatus}></Column>
              }
              <Column dataField="amount" name="amount" caption="Session Price" cellRender={this.formatAmount}></Column>
              <Column dataField="amount_profit" name="amount_profit" caption="My Profit" cellRender={this.formatMyProfit}></Column>
              <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'left'} width={120}></Column>
              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
                allowedPageSizes={[10, 25, 50, 100]}
              />
            </DataGrid>
          </Col>
        </Row>
      </div>
    )
  }
}

TableExpertAppointments.propTypes = {
  expert_id: PropTypes.any.isRequired
}

export default TableExpertAppointments
