import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { Link } from "react-router-dom";
import imageUser from "../../assets/images/image-user.jpg";
import { checkImage } from 'helpers/checkImage';
import { checkPermission, formatAmountUs } from 'helpers/supportFunction';
import TableExpertCategories from "./tableExpertCategories";
import TableExpertSkills from "./tableExpertSkills";
import TableExpertAppointments from "./tableExpertAppointments";
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';

class SideKickDetail extends Component {
  constructor(props) {
    super(props)
    let { detailExpert } = props;
    this.state = {
      isUpdateCommission: false,
      isSubmit: false,
      commission_update: null,
      currentExpert: detailExpert
    }
  }

  customActionMedia = (e) => {
    return <a title="Open" alt="Open" target={"blank"}
      href={e.data.file_url ? (e.data.file_url.includes('http') ? e.data.file_url : process.env.REACT_APP_BASE_URL + e.data.file_url) : '#'}
    >View</a>
  }

  render() {
    return (
      <>
        {this.state.currentExpert && typeof this.state.currentExpert.id != "undefined" ?
          <>
            {
              !this.state.currentExpert.is_requesting && this.state.currentExpert && this.state.currentExpert.total_appointments_report ?
                <Card className="border-card mb-3">
                  <CardBody className="pb-2">
                    <Row>
                      <div className="d-col-1-in-7 col-md-6 col-xs-6 col-12 text-center">
                        <Label className="active-statistics font-size-24 text-primary">
                          <span>${this.state.currentExpert.total_appointments_report.total_profit ?? 0}</span>
                        </Label>
                        <br></br>
                        <Label className="font-weight-bold">Total Profit</Label>
                      </div>
                      <div className="d-col-1-in-7 col-md-6 col-xs-6 col-12 text-center">
                        <Label className="active-statistics font-size-24">
                          <span>{this.state.currentExpert.total_appointments_report.completed ?? 0}</span>
                        </Label>
                        <br></br>
                        <Label className="font-weight-bold">Completed Sessions</Label>
                      </div>
                      <div className="d-col-1-in-7 col-md-6 col-xs-6 col-12 text-center">
                        <Label className="warning-statistics font-size-24">
                          <span>{this.state.currentExpert.total_appointments_report.pending ?? 0}</span>
                        </Label>
                        <br></br>
                        <Label className="font-weight-bold">Reserved Sessions</Label>
                      </div>
                      <div className="d-col-1-in-7 col-md-6 col-xs-6 col-12 text-center">
                        <Label className="active-statistics text-secondary font-size-24">
                          <span>{this.state.currentExpert.total_appointments_report.expired ?? 0}</span>
                        </Label>
                        <br></br>
                        <Label className="font-weight-bold">InCompleted Sessions</Label>
                      </div>
                      <div className="d-col-1-in-7 col-md-6 col-xs-6 col-12 text-center">
                        <Label className="inactive-statistics font-size-24">
                          <span>{this.state.currentExpert.total_appointments_report.cancelled ?? 0}</span>
                        </Label>
                        <br></br>
                        <Label className="font-weight-bold">Cancelled Sessions</Label>
                      </div>
                      <div className="d-col-1-in-7 col-md-6 col-xs-6 col-12 text-center">
                        <Label className="active-statistics text-deepskyblue font-size-24">
                          <span>{this.state.currentExpert.total_share_profile ?? 0}</span>
                        </Label>
                        <br></br>
                        <Label className="font-weight-bold">Total Share Profile</Label>
                      </div>
                      <div className="d-col-1-in-7 col-md-6 col-xs-6 col-12 text-center">
                        <Label className="inactive-statistics text-mediumseagreen font-size-24">
                          <span>{this.state.currentExpert.total_view_profile ?? 0}</span>
                        </Label>
                        <br></br>
                        <Label className="font-weight-bold">Total View Profile</Label>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                : ''
            }
            <Card className="border-card mb-3">
              <CardBody>
                <Row>
                  <Col xl="3" xs="12" md="12">
                    <div className="d-block text-center">
                      <br />
                      <img
                        id={`image_user_detail_${this.state.currentExpert && typeof this.state.currentExpert.id != "undefined" ? this.state.currentExpert.id : ''}`}
                        src={
                          this.state.currentExpert && this.state.currentExpert.image ?
                            checkImage(this.state.currentExpert.image, `image_user_detail_${this.state.currentExpert && typeof this.state.currentExpert.id != "undefined" ? this.state.currentExpert.id : ''}`, imageUser)
                            : imageUser
                        }
                        className="img-avatar"
                      />
                      {
                        checkPermission('expert', 'update') ?
                          <>
                            <br></br>
                            <Link to={`/${this.state.currentExpert.is_requesting ? 'sidekicks-request' : 'sidekicks'}/${this.state.currentExpert.id}/edit`} className="mb-0">
                              <Button
                                className="btn-custom-width mx-2 mt-3"
                                color="primary"
                              >
                                Update
                              </Button>
                            </Link>
                          </>
                          : ''
                      }
                    </div>
                  </Col>
                  <Col xl="4" xs="12" md="6" className="pt-3">
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">User ID: </label>
                      {this.state.currentExpert && this.state.currentExpert.user_id
                        ? <Link to={`/users/${this.state.currentExpert.user_id}/detail`} className="mb-0">{this.state.currentExpert.user_id}</Link>
                        : ""}
                    </div>
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Email: </label>
                      {
                        this.state.currentExpert && this.state.currentExpert.email
                          ? <a className="mb-0" rel="noreferrer" target="_blank" href={"mailto:" + this.state.currentExpert.email}>{this.state.currentExpert.email}</a>
                          : ""
                      }
                    </div>
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">User Name: </label>
                      <p className="mb-0">
                        {this.state.currentExpert && this.state.currentExpert.user_name
                          ? <Link to={`/users/${this.state.currentExpert.user_id}/detail`} className="mb-0">{this.state.currentExpert.user_name}</Link>
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Name: </label>
                      <p className="mb-0">
                        {this.state.currentExpert && this.state.currentExpert.name
                          ? this.state.currentExpert.name
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Ambassador: </label>
                      <p className="mb-0">
                        {this.state.currentExpert.ambassador_id && checkPermission('user', 'read')
                          ? <Link to={`/sidekick-ambassadors/${this.state.currentExpert.ambassador_id}/detail`} className="mb-0">
                            {this.state.currentExpert.ambassador_name}
                          </Link>
                          : this.state.currentExpert.ambassador_name}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Active: </label>
                      <p className="mb-0">
                        {this.state.currentExpert && this.state.currentExpert.is_active
                          ? "Active"
                          : "Inactive"}
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" xs="4" md="6" className="pt-3">
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Price: </label>
                      <p className="mb-0">
                        {this.state.currentExpert && this.state.currentExpert.price
                          ? formatAmountUs(this.state.currentExpert.price)
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Commission Rate: </label>
                      <p className="mb-0">
                        {this.state.currentExpert && this.state.currentExpert.commission
                          ? parseFloat(this.state.currentExpert.commission)
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Availability: </label>
                      <p className="mb-0">
                        {this.state.currentExpert && this.state.currentExpert.availability
                          ? this.state.currentExpert.availability === 1
                            ? "Active"
                            : "Inactive"
                          : ""}
                      </p>
                    </div>

                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Stripe Status: </label>
                      <p className="mb-0">
                        {this.state.currentExpert && this.state.currentExpert.stripe_active
                          ? "Active"
                          : "Inactive"}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Rating Total: </label>
                      <p className="mb-0">
                        {this.state.currentExpert && this.state.currentExpert.rating_total
                          ? this.state.currentExpert.rating_total
                          : "0"}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Rating: </label>
                      <p className="mb-0">
                        {this.state.currentExpert && this.state.currentExpert.rating
                          ? this.state.currentExpert.rating
                          : "0"}
                      </p>
                    </div>
                  </Col>
                  <Col xl="3" xs="0" md="0"></Col>
                  <Col xl="8" xs="12" md="12">
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Headline: </label>
                      <p className="mb-0">
                        {this.state.currentExpert && this.state.currentExpert.title
                          ? this.state.currentExpert.title
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex">
                      <label className=" fw-bold me-2">Description: </label>
                      <p className="mb-0">
                        {this.state.currentExpert && this.state.currentExpert.description
                          ? this.state.currentExpert.description
                          : ""}
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row>
              <Col xl="6" md="12" className="pb-3">
                <Card className="border-card height-100">
                  <CardBody className="pb-0">
                    <h4 className="text-primary text-center mb-3">Contacts</h4>
                    {this.state.currentExpert && this.state.currentExpert.contacts ? (
                      <DataGrid
                        id="dataGridContact"
                        dataSource={this.state.currentExpert.contacts}
                        showBorders={true}
                        remoteOperations={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        hoverStateEnabled={true}
                      >
                        <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80}></Column>
                        <Column dataField="type" name="type" caption="Type"></Column>
                        <Column dataField="value" name="Value" caption="Description"></Column>

                        <Paging defaultPageSize={10} />
                        <Pager
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                          allowedPageSizes={[10, 25, 50, 100]}
                        />
                      </DataGrid>
                    ) : (
                      <></>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6" md="12" className="pb-3">
                <Card className="border-card height-100">
                  <CardBody className="pb-0">
                    <h4 className="text-primary text-center mb-3">Media</h4>
                    {this.state.currentExpert && this.state.currentExpert.media ? (
                      <DataGrid
                        id="dataGridMedia"
                        dataSource={this.state.currentExpert.media}
                        showBorders={true}
                        remoteOperations={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        hoverStateEnabled={true}
                      >
                        <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80}></Column>
                        <Column dataField="file_type" name="file_type" caption="File Type"></Column>
                        <Column dataField="file_url" name="file_url" caption="Action" cellRender={this.customActionMedia}></Column>
                        <Paging defaultPageSize={10} />
                      </DataGrid>
                    ) : (
                      <></>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12" xl="6" className="pb-3">
                <Card className="border-card height-100">
                  <CardBody>
                    <h4 className="text-primary text-center">Skills</h4>
                    {this.state.currentExpert && this.state.currentExpert.id ? (
                      <TableExpertSkills expert_id={this.state.currentExpert.id} />
                    ) : (
                      <></>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="6" className="pb-3">
                <Card className="border-card height-100">
                  <CardBody>
                    <h4 className="text-primary text-center">Categories</h4>
                    {this.state.currentExpert && this.state.currentExpert.id ? (
                      <TableExpertCategories expert_id={this.state.currentExpert.id} />
                    ) : (
                      <></>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {
              checkPermission("appointment", "read") && !this.state.currentExpert.is_requesting && this.state.currentExpert &&
              <Card className="border-card">
                <CardBody>
                  <h4 className="text-primary text-center">Sessions</h4>
                  <TableExpertAppointments expert_id={this.state.currentExpert.id}></TableExpertAppointments>
                </CardBody>
              </Card>
            }
          </>
          :
          ''}
      </>
    )
  }
}

SideKickDetail.propTypes = {
  detailExpert: PropTypes.any
}

export default SideKickDetail
