import React, { Component } from "react"
import PropTypes from "prop-types"
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import expertService from "services/expert"
import MetaTags from "react-meta-tags"
import Detail from "components/sidekicks/detailExpert";
import { checkPermission } from 'helpers/supportFunction';
import toast from 'helpers/toast';

class ExpertDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expert_id: null,
      detailExpert: null,
    }
  }

  async componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("expert", "read")) {
      toast.warning("You do not have permission to manage sidekicks", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      if (this.props.match && this.props.match.params && this.props.match.params.id) {
        this.setState({ expert_id: this.props.match.params.id }, () => 
          this.loadData()
        );
      }
    }
  }

  loadData = async () => {
    let response = await expertService.getDetailExpert(this.state.expert_id);
    if (response && response.data) {
      if (response.data.is_requesting) {
        this.setState({
          detailExpert: response.data,
        })
      } else {
        window.location = '/sidekicks/' + response.data.id + '/detail';
      }
    } else {
      setTimeout(() => {
        window.location = '/sidekicks-request';
      }, 1000);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sidekick Requesting Detail | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="6">
                    <h4 className="mt-2 mb-0 text-primary">Sidekick Detail</h4>
                  </Col>
                  <Col xl="6" className="text-right">
                    <Link to="/sidekicks-request">
                      <Button className="btn-custom-width"> Back </Button>
                    </Link>
                  </Col>
                  <Col xl="12" className="pt-2">
                    {this.state.detailExpert && typeof this.state.detailExpert.id != "undefined" ? 
                      <Detail
                        detailExpert={this.state.detailExpert}
                      />
                      : ''
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ExpertDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any.isRequired
    })
  })
}

export default ExpertDetail
