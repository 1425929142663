import axios from '../services/axios-service';

async function getAll(body) {
  let data = await axios.get(`/admin/skill?${body ?? ''}`);
  return data
}

async function createSkill(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/skill/create", urlencoded);
  return data
}

async function deleteSkill(body) {
  var urlencoded = new URLSearchParams({ skill_id: body })
  let data = await axios.post("/admin/skill/delete", urlencoded);
  return data;
}

async function forceDeleteSkill(body) {
  var urlencoded = new URLSearchParams({ skill_id: body })
  let data = await axios.post("/admin/skill/forceDelete", urlencoded);
  return data;
}


async function updateSkill(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post("/admin/skill/update", urlencoded);
  return data;
}

async function getDetailSkill(body) {
  let url = `/admin/skill/detail?skill_id=${body}`;
  let data = await axios.get(url);
  return data
}
export default {
  getAll: getAll,
  createSkill: createSkill,
  deleteSkill: deleteSkill,
  forceDeleteSkill: forceDeleteSkill,
  getDetailSkill: getDetailSkill,
  updateSkill: updateSkill,
}
