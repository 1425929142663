import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Card,
  CardBody
} from "reactstrap";
import { Link } from "react-router-dom";
import paymentService from "../../services/payment";
import MetaTags from "react-meta-tags";
import { 
  checkPermission,
  customUrlParams,
  capitalizeFirstLetter,
  customLinkToStripe,
  formatTotalCharge,
  formatStripeFee,
  formatTransfer,
  formatRefund,
  customExpert,
  customExpertCategories,
  customCustomer
} from 'helpers/supportFunction';
import moment from "moment";
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging, Selection } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      dxStore: this.createDxStore()
    }
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("payment", "read")) {
      toast.warning("You do not have permission to manage payments", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.loadData()
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = ""
        if (this.state.search) {
          url_params += `&search=${this.state.search}`;
        }
        let params = customUrlParams(loadOptions, url_params, 'id', 'desc')
        var response = await paymentService.getAll(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  formatCreatedAt= (e) => {
    let payment = e.data;
    return (
      <span
      >
        { moment(payment.created_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)}
      </span>
    )
  }

  customId = (e) => {
    let payment = e.data;
    return (
      <Link to={`/payments/${payment.id}/detail`}
      >
        {payment.id}
      </Link>
    )
  }

  customIsAvailable = (e) => {
    let payment = e.data
    return (
      <span> {payment.is_available == 0 ? 'Pending' : 'Available'} </span>
    )
  }

  customChargedAt = (e) => {
    let payment = e.data
    return (
      <span> {payment.charged_at ? moment(payment.charged_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''} </span>
    )
  }

  customDateAvailable = (e) => {
    let payment = e.data;
    const format = "MM/DD/YYYY" + (payment.is_available == 0 ? "" : " HH:mm"); 
    return (
      <span> {payment.commission_available_date ? moment(payment.commission_available_date).format(format) : ''} </span>
    )
  }

  customRefundedAt = (e) => {
    let payment = e.data
    return (
      <span> {payment.refunded_at ? moment(payment.refunded_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''} </span>
    )
  }

  linkEdit = (e) => {
    let payment = e.data;
    return <>
      <Link to={`/payments/${payment.id}/detail`}>
        <Button className="mx-1 btn-custom-width">
          View Detail
        </Button>
      </Link>
    </>
  }

  handleInputSearch = e => {
    this.setState({ search: e }, () => 
      this.loadData(null, null)
    );
  }

  customStatus = (e) => {
    let payment = e.data;
    return (
      <span>
      { payment.status ? capitalizeFirstLetter(payment.status) : ''}
      </span>
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Payments | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col sm="12" className="d-flex align-items-end pb-2">
                    <h4 className="mb-0 text-primary">Manage Payments</h4>
                  </Col>
                  <Col xl="4" className="pt-2 pb-2">
                    <Input
                      type="search"
                      id="search"
                      className="form-control"
                      placeholder="Search..."
                      value={this.state.search}
                      onChange={e => {
                        this.handleInputSearch(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
                <Row className="pt-1">
                  <Col xl="12">
                    <DataGrid
                      dataSource={this.state.dxStore}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                    >
                      <Selection
                        mode="multiple"
                        selectAllMode={'page'}
                        showCheckBoxesMode={'always'}
                      />
                      <Column dataField="id" name="id" caption="Payment ID" alignment={'center'} width={100} cellRender={this.customId}></Column>
                      <Column dataField="payment_intent_id" name="payment_intent_id" caption="Stripe Payment ID" cellRender={customLinkToStripe}></Column>
                      <Column dataField="customer.name" name="customer.name" caption="User" cellRender={customCustomer}></Column>
                      <Column dataField="expert.name" name="expert.name" caption="Sidekick" cellRender={customExpert}></Column>
                      <Column dataField="expert.categories" name="expert.categories" caption="Categories" cellRender={customExpertCategories}></Column>
                      <Column dataField="total_charge" name="total_charge" caption="Total Charge"cellRender={formatTotalCharge}></Column>
                      <Column dataField="stripe_fee" name="stripe_fee" caption="Stripe Fee"cellRender={formatStripeFee}></Column>
                      <Column dataField="transfer" name="transfer" caption="Transfer" cellRender={formatTransfer}></Column>
                      <Column dataField="refund" name="refund" caption="Refund" cellRender={formatRefund}></Column>
                      <Column dataField="charged_currency_code" name="charged_currency_code" caption="Currency Code" alignment={'center'}></Column>
                      <Column dataField="status" name="status" caption="Status" cellRender={this.customStatus}></Column>
                      <Column dataField="is_available" name="is_available" caption="Balance Transaction Status" alignment={'left'} cellRender={this.customIsAvailable}></Column>
                      <Column dataField="commission_available_date" name="commission_available_date" caption="Available At" cellRender={this.customDateAvailable}></Column>
                      <Column dataField="charged_at" name="charged_at" caption="Charged At" cellRender={this.customChargedAt}></Column>
                      <Column dataField="refunded_at" name="refunded_at" caption="Refunded At" cellRender={this.customRefundedAt}></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'}></Column>
                      <Paging defaultPageSize={10} />
                      <Pager
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        allowedPageSizes={[10, 25, 50, 100]}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Payment
