import axios from '../services/axios-service';

async function getAll(body) {
  let data = await axios.get(`/admin/account?${body ?? ''}`);
  return data
}

async function createAdmin(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/account/create", urlencoded);
  return data
}

async function deleteAdmin(body) {
  var urlencoded = new URLSearchParams({ admin_id: body })
  let data = await axios.post("/admin/account/delete", urlencoded);
  return data;
}

async function updateAdmin(body) {  
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post("/admin/account/update", urlencoded);
  return data;
}

async function getDetailAdmin(body) {
  let url = `/admin/account/getDetail?admin_id=${body ?? ''}`;
  let data = await axios.get(url);
  return data
}

async function changePassword(body) {  
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post("/admin/account/change-password", urlencoded);
  return data;
}

export default {
  getAll: getAll,
  createAdmin: createAdmin,
  deleteAdmin: deleteAdmin,
  updateAdmin: updateAdmin,
  getDetailAdmin: getDetailAdmin,
  changePassword: changePassword
}
