import axios from './axios-service';

async function getAll(body) {
  let data = await axios.get(`/admin/ambassador?${body ?? ''}`);
  return data
}

async function create(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/ambassador/create", urlencoded);
  return data
}

async function update(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/ambassador/update", urlencoded);
  return data
}

async function getDetail(body) {
  let data = await axios.get(`/admin/ambassador/detail?${body ?? ''}`);
  return data
}

async function getAmbassadorReport(body) {
  let data = await axios.get(`/admin/report/getAmbassadorReports?${body ?? ''}`);
  return data
}

async function exportAmbassadorReport(body) {
  let data = await axios.get(`/admin/report/exportAmbassadorReports?${body}`);
  return data
}

export default {
  getAll: getAll,
  create: create,
  update: update,
  getDetail: getDetail,
  getAmbassadorReport: getAmbassadorReport,
  exportAmbassadorReport: exportAmbassadorReport
}
