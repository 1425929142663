import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Label,
  Input,
  Modal,
  ModalHeader,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap"
import Select from 'react-select'
import { Link } from "react-router-dom"
import expertService from "../../services/expert"
import expertAmbassadorService from "../../services/ambassador";
import MetaTags from "react-meta-tags"
import { checkPermission, customUrlParams } from "helpers/supportFunction"
import ModalConfirm from "components/modals/modalConfirm"
import RejectedRequest from "components/sidekicks/rejectedRequest"
import moment from "moment"
import toast from "helpers/toast"
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

class Expert extends Component {
  constructor(props) {
    super(props)
    this.refRejectedRequest = React.createRef();
    this.state = {
      experts: [],
      isReject: false,
      isApprove: false,
      reasonReject: "",
      currentData: null,
      search: "",
      dxStore: this.createDxStore(),
      currentData: null,
      isAddAmbassador: false,
      isSubmit: false,
      waiting: false,
    }
  }

  componentDidMount = async () => {
    if (localStorage.getItem("userSidekick") && !checkPermission("expert", "read")) {
      toast.warning(
        "You do not have permission to manage sidekicks",
        "Invalid Permission"
      )
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.setState({ waiting: true });
      this.loadData()
      let ambassadors = [];
      var response = await expertAmbassadorService.getAll('status=active&limit=-1&offset=0');
      if(response.success) {
        ambassadors = response.data.map(item => { item.value = item.id; item.label = item.name; return item  });
      }
      this.setState({ambassadorOptions: ambassadors, waiting: false});
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = "is_requesting=1&status=pending"
        if (this.state.search) {
          url_params += `&search=${this.state.search}`
        }
        let params = customUrlParams(loadOptions, url_params, 'name', 'asc')
        var response = await expertService.getAll(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  linkEdit = (e) => {
    let expert = e.data;
    return (
      <>
        <Link to={`/sidekicks-request/${expert.id}/detail`}>
          <Button className="mx-1 btn-custom-width">View Detail</Button>
        </Link>
        {checkPermission("expert", "update") ? (
          <>
            <Button
              className="mx-1 btn-custom-width"
              color="primary"
              onClick={async () => {
                this.setState({
                  isApprove: true,
                  currentData: expert,
                })
              }}
            >
              Approve
            </Button>
            {
              expert.rejected_at ? '' :
              <Button
                className="mx-1 btn-danger btn-custom-width"
                onClick={async () => {
                  this.setState({
                    isReject: true,
                    reasonReject: "",
                    currentData: expert,
                  })
                }}
              >
                Reject
              </Button>
            }
          </>
        ) : (
          <></>
        )}
      </>
    )
  }

  formatCreatedAt = (e) => {
    let expert = e.data;
    return (
      <span>
        {expert.created_at
          ? moment(expert.created_at).format(
              process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT
            )
          : ""}
      </span>
    )
  }

  customUser = (e) => {
    let expert = e.data;
    return (
      <Link to={`/sidekicks-request/${expert.id}/detail`}
      >
        {expert.user_name}
      </Link>
    )
  }

  customName = (e) => {
    let expert = e.data;
    return (
      <Link to={`/sidekicks-request/${expert.id}/detail`}
      >
        {expert.name}
      </Link>
    )
  }

  customAmbassador = (e) => {
    let data = e.data;
    return (
      checkPermission("ambassador", "read") && data.ambassador_id ? 
        <>
          <Link to={`/sidekick-ambassadors/${data.ambassador_id}/detail`}>
            { data.ambassador_name }
          </Link>
        </> :
        <span> {data.ambassador_name} </span>
    )
  }

  onCloseModalReject = () => {
    this.setState({
      isReject: false,
      reasonReject: "",
      currentData: null,
    })
  }

  onActionReject = async () => {
    if (!this.state.reasonReject) {
      toast.warning("Please enter the reason for the reject", "Invalid")
    } else {
      let response = await expertService.unapproveExpert({
        expert_id: this.state.currentData.id,
        reason_reject: this.state.reasonReject,
      })
      if (response.success) {
        toast.success("Rejected success", "Rejected")
        this.onCloseModalReject()
        this.loadData()
        if (this.refRejectedRequest && this.refRejectedRequest.current) {
          this.refRejectedRequest.current.loadData();
        }
      }
    }
  }

  onCloseModalApprove = () => {
    this.setState({
      isApprove: false,
      currentData: null,
    })
  }

  onActionApprove = async () => {
    let response = await expertService.approveExpert({
      expert_id: this.state.currentData.id,
    })
    if (response.success) {
      toast.success("Approved success", "Approved")
      this.onCloseModalApprove()
      this.loadData()
    }
  }
  handleInputSearch = e => {
    this.setState({ search: e }, () => this.loadData())
  }

  handleInput = (type, value) => {
    let currentData = { ...this.state.currentData }
    currentData[[type]] = value
    this.setState({ currentData: currentData })
  }

  onActionDeleteAmbassador = async () => {
    await this.onClickAddAmbassador();
  }

  onClickAddAmbassador = async () => {
    this.setState({isSubmit: true, messageError: ''});
    if (this.state.currentData.ambassador_id) {
      this.setState({ waiting: true });
      let dataForm = {
        expert_id: this.state.currentData.id,
        availability: this.state.currentData.availability ? 1 : 0,
        price: this.state.currentData.price ? parseFloat(this.state.currentData.price).toFixed(2) : '0',
        commission: this.state.currentData.commission ? parseFloat(this.state.currentData.commission) : '0',
        // minimum_charge: this.state.data.minimum_charge ? parseFloat(this.state.data.minimum_charge) : '0',
        title: this.state.currentData.title ? this.state.currentData.title : '',
        description: this.state.currentData.description ? this.state.currentData.description : '',
        ambassador_id: this.state.currentData.ambassador_id ? this.state.currentData.ambassador_id : '',
      };
      if (this.state.isDeleteAmbassador) {
        dataForm.ambassador_id = '';
      }
      let response = await expertService.updateExpert(dataForm);
      if (response.success) {
        toast.success((this.state.isDeleteAmbassador ? 'Delete' : 'Add') + " ambassador success");
        this.loadData();
        this.onCloseModal();
      } else {
        let error = (this.state.isDeleteAmbassador ? 'Delete' : 'Add') + " ambassador failed";
        if (response.error) {
          error = response.error
        }
        this.setState({messageError: error})
      }
      this.setState({isSubmit: false, waiting: false});
    }
  }

  onCloseModal = () => {
    this.setState({
      isDeleteAmbassador: false,
      isAddAmbassador: false,
      currentData: null,
      isSubmit: false,
      waiting: false,
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sidekicks Requesting | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card className={`${this.state.waiting ? 'waitLoadingData' : ''}` }>
              <CardBody>
                <Row>
                  <Col xl="12" className="d-flex align-items-center pb-2">
                    <h4 className="mb-0 text-primary">Manage Sidekicks Requesting</h4>
                  </Col>
                  <Col xl="4" className="pt-2 pb-2">
                    <Input
                      type="search"
                      id="search"
                      className="form-control"
                      placeholder="Search..."
                      value={this.state.search}
                      onChange={e => {
                        this.handleInputSearch(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
                <Row className="pt-1">
                  <Col xl="12">
                    <DataGrid
                      dataSource={this.state.dxStore}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                    >
                      <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80}></Column>
                      <Column dataField="name" name="name" caption="Full Name" cellRender={this.customName}></Column>
                      <Column dataField="user_name" name="user_name" caption="User Name" cellRender={this.customUser}></Column>
                      <Column dataField="email" name="email" caption="Email"></Column>
                      <Column dataField="ambassador_name" name="ambassador_name" caption="Ambassador" cellRender={this.customAmbassador}></Column>
                      <Column dataField="created_at" name="created_at" caption="Request Date" cellRender={this.formatCreatedAt} ></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'}></Column>

                      <Paging defaultPageSize={10} />
                      <Pager
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        allowedPageSizes={[10, 25, 50, 100]}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <RejectedRequest ref={this.refRejectedRequest}></RejectedRequest>
              </CardBody>
            </Card>
            <Modal
              role="dialog"
              isOpen={this.state.isReject}
              autoFocus={true}
              centered
              data-toggle="modal"
              backdrop={"static"}
              toggle={() => this.onCloseModalReject()}
            >
              <div>
                <ModalHeader
                  className="border-bottom-0"
                  toggle={() => this.onCloseModalReject()}
                ></ModalHeader>
              </div>
              <div className="modal-body" style={{ paddingTop: "0" }}>
                <div className=" mb-4">
                  <Row className=" justify-content-center">
                    <Col xl="11">
                      <h4 className="text-primary text-center mb-4">
                        Confirmation Reject
                      </h4>
                      <Row>
                        <Label
                          for="reason_reject pt-3"
                          style={{ paddingLeft: "0" }}
                        >
                          Please enter the reason for the reject
                        </Label>
                        <div className="input-group rounded bg-light mb-3">
                          <Input
                            type="textarea"
                            id="reason_reject"
                            name="reason_reject"
                            className="form-control bg-transparent border-0"
                            placeholder="Reason reject"
                            value={this.state.reasonReject}
                            onChange={e => {
                              this.setState({
                                reasonReject: e.target.value,
                              })
                            }}
                          />
                        </div>

                        <Button
                          className="mt-3 d-block mx-auto"
                          color="danger"
                          type="button"
                          id="button-create"
                          disabled={
                            !this.state.reasonReject || !this.state.currentData
                          }
                          onClick={() => {
                            this.onActionReject()
                          }}
                        >
                          Reject
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Modal>
            <ModalConfirm
              isOpen={this.state.isApprove}
              title={'Confirmation Approve'}
              message={'Are you sure approve ' + (this.state.currentData ? 'for ' + this.state.currentData.name : "") + '?'}
              textButton={'Approve'}
              colorButton={'primary'}
              onCloseModal={() => {
                this.onCloseModalApprove();
              }}
              onActionConfirm={ async () => {
                await this.onActionApprove()
              }}
            />
             <ModalConfirm
              isOpen={this.state.isDeleteAmbassador}
              title='Delete Ambassador'
              message={'Are you sure want to delete ambassador ' + (this.state.currentData ? 'for ' + this.state.currentData.name : "") + '?'}
              textButton='Delete Ambassador'
              colorButton='danger'
              onCloseModal={() => {
                this.onCloseModal();
              }}
              onActionConfirm={ async () => {
                await this.onActionDeleteAmbassador()
              }}
            />
            <Modal
              isOpen={this.state.isAddAmbassador}
              role="dialog"
              autoFocus={true}
              centered
              data-toggle="modal"
              backdrop='static'
              toggle={() => {
                this.onCloseModal()
              }}
            >
              <div>
                <ModalHeader
                  className="border-bottom-0"
                  toggle={() => {
                    this.onCloseModal()
                  }}
                ></ModalHeader>
              </div>
              <div className="modal-body pt-0">
                <div className="text-center mb-4">
                  <Row className=" justify-content-center">
                    {this.state.currentData ? 
                      <Col xl="11">
                        <h4 className="text-primary mb-3">Add Ambassador</h4>
                        <Row>
                          <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                            <Label for="user" style={{"paddingLeft": "0"}}>Ambassador</Label>
                            <Select
                              name="user"
                              classNamePrefix="select"
                              defaultValue={this.state.currentData.ambassador_id ? this.state.currentData.ambassador_id : null}
                              value={ this.state.ambassadorOptions.find((item) => {return item.value == this.state.currentData?.ambassador_id}) }
                              isClearable={false}
                              isSearchable={true}
                              options={this.state.currentData && this.state.currentData.user_id ? 
                                this.state.ambassadorOptions.filter(item => { return item.user_id != this.state.currentData.user_id}) 
                                : this.state.ambassadorOptions
                              }
                              onChange={e => {this.handleInput("ambassador_id", e.value)}}
                              placeholder="Select Ambassador"
                              className={this.state.isSubmit && !this.state.currentData.ambassador_id? 'is-invalid' : ''}
                            />
                              {
                                this.state.isSubmit && !this.state.currentData.ambassador_id ? 
                                  <FormFeedback type="invalid" className="invalid-inline">
                                    Ambassador is required
                                  </FormFeedback>
                                : ''
                              }
                          </Col>
                        </Row>
                        { this.state.messageError ? 
                          <Row className="pb-2 text-danger">
                            <Col xl="12">
                              {this.state.messageError}
                            </Col>
                          </Row>
                        : ''}
                        <Button
                          className="mt-3 d-block mx-auto"
                          color="primary"
                          type="button"
                          id="button-create"
                          onClick={() => {
                            this.onClickAddAmbassador()
                          }}
                        >
                          Add Ambassador
                        </Button>
                      </Col>
                    : ''
                    }
                  </Row>
                </div>
              </div>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Expert
