import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  UncontrolledTooltip
} from "reactstrap"
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags"
import { checkPermission, formatter, dataDashboardDefault } from 'helpers/supportFunction';
import dashboardService from "services/dashboard";
import CountTo from 'react-count-to';

class Admin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      countToSpeed: 1500,
      data: dataDashboardDefault
    }
  }

  async componentDidMount() {
    if (localStorage.getItem('userSidekick') && checkPermission("expert", "read")) {
      await this.loadData()
    }
  }

  loadData = async () => {
    var response = await dashboardService.getData();
    if (response && response.success && response.data.users) {
      this.setState({ data: response.data })
    }
  }

  renderInfoDescription (description, key = Math.random()) {
    return <>
      <i 
        className='mx-1 mdi mdi-information-outline text-warning cursor-pointer'
        id={`tooltip-${key}`}
        style={{fontSize: '16px'}}
      ></i>
      <UncontrolledTooltip
        placement="auto"
        target={`tooltip-${key}`}
      >
        {description}
      </UncontrolledTooltip>
    </> 
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Sidekick </title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12" className="d-flex align-items-center mb-3">
                    <h4 className="mb-0 me-3 text-primary">Dashboard</h4>
                  </Col>
                </Row>
                {checkPermission('appointment', 'read') && this.state.data.revenue ?
                  <Row>
                    <Col xl="12">
                      <Row className="mx-0">
                        <Col xl="2" md="4" xs="6" className="px-1 pl-0">
                          <Card className="border-gray-custom card-statistic">
                            <CardBody className="text-center font-size-16 px-2">
                              <div className="title-card">
                                <span className="font-weight-bold">Total Revenue
                                </span>
                                { this.state.data.revenue.total_revenue.description && this.renderInfoDescription(this.state.data.revenue.total_revenue.description, "total_revenue")}
                                <p className="font-size-10">The overall revenue generated</p>
                              </div>
                              <div className="data-card">
                                <span className="font-size-18 text-success">
                                  <span>{this.state.data.revenue.total_revenue.value ? formatter.format(this.state.data.revenue.total_revenue.value) : 0}</span>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl="2" md="4" xs="6" className="px-1">
                          <Card className="border-gray-custom card-statistic">
                            <CardBody className="text-center font-size-16 px-2">
                              <div className="title-card">
                                <span className="font-weight-bold">ARPS</span>
                                { this.state.data.revenue.avg_revenue_sidekick.description && this.renderInfoDescription(this.state.data.revenue.avg_revenue_sidekick.description, "avg_revenue_sidekick")}
                                <p className="font-size-10">Average Revenue Per Sidekick</p>
                              </div>
                              <div className="data-card">
                                <span className="font-size-18 text-success font-weight-500"> 
                                  <span>{this.state.data.revenue.avg_revenue_sidekick.value ? formatter.format(this.state.data.revenue.avg_revenue_sidekick.value) : 0}</span>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl="2" md="4" xs="6" className="px-1">
                          <Card className="border-gray-custom card-statistic">
                            <CardBody className="text-center font-size-16 px-2">
                              <div className="title-card">
                                <span className="font-weight-bold">ARPPS</span>
                                { this.state.data.revenue.avg_revenue_sidekick_have_least_one_session.description && this.renderInfoDescription(this.state.data.revenue.avg_revenue_sidekick_have_least_one_session.description, "avg_revenue_sidekick_have_least_one_session")}
                                <p className="font-size-10">Average Revenue Per Paid Sidekick</p>
                              </div>
                              <div className="data-card">
                                <span className="font-size-18 text-success"> 
                                  <span>{this.state.data.revenue.avg_revenue_sidekick_have_least_one_session.value ? formatter.format(this.state.data.revenue.avg_revenue_sidekick_have_least_one_session.value) : 0}</span>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl="2" md="4" xs="6" className="px-1">
                          <Card className="border-gray-custom card-statistic">
                            <CardBody className="text-center font-size-16 px-2">
                              <div className="title-card">
                                <span className="font-weight-bold">APPU</span>
                                { this.state.data.revenue.avg_revenue_user.description && this.renderInfoDescription(this.state.data.revenue.avg_revenue_user.description, "avg_revenue_user")}
                                <p className="font-size-10">Average Pay Per User</p>
                              </div>
                              <div className="data-card">
                                <span className="font-size-18 text-success">
                                  <span>{this.state.data.revenue.avg_revenue_user.value ? formatter.format(this.state.data.revenue.avg_revenue_user.value) : 0}</span>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl="2" md="4" xs="6" className="px-1">
                          <Card className="border-gray-custom card-statistic">
                            <CardBody className="text-center font-size-16 px-2">
                              <div className="title-card">
                                <span className="font-weight-bold">APPPU</span>
                                { this.state.data.revenue.avg_revenue_user_have_least_one_session.description && this.renderInfoDescription(this.state.data.revenue.avg_revenue_user_have_least_one_session.description, "avg_revenue_user_have_least_one_session")}
                                <p className="font-size-10">Average Pay Per Paid User</p>
                              </div>
                              <div className="data-card">
                                <span className="font-size-18 text-success">
                                  <span>{this.state.data.revenue.avg_revenue_user_have_least_one_session.value ? formatter.format(this.state.data.revenue.avg_revenue_user_have_least_one_session.value) : 0}</span>
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl="2" md="4" xs="6" className="px-1 pr-0">
                          { this.state.data.users && 
                            <Card className="border-gray-custom card-statistic">
                              <CardBody className="text-center font-size-16 px-2">
                                <div className="title-card">
                                  <span className="font-weight-bold">User Statistics</span>
                                  <p className="font-size-10">Total Users <span className="text-success">(Users who have taken at least one session)
                                  { this.state.data.users.have_least_one_session.description && this.renderInfoDescription(this.state.data.users.have_least_one_session.description, "user_have_least_one_session")}
                                  </span></p>
                                </div>
                                <div className="data-card">
                                  <span className="font-size-18 text-success font-weight-500">
                                    <span>{this.state.data.users.total_users.value} <span className="text-success">({this.state.data.users.have_least_one_session.value})</span></span>
                                  </span>
                                </div>
                              </CardBody>
                            </Card>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                : '' }

                <Row>
                  {checkPermission('expert', 'read') ? 
                    <Col xl="12">
                      <Card className="border-gray-custom mb-2 bg-aliceblue">
                        <CardBody>
                          <h5 className="mb-2 text-primary text-center">
                            <Link to="/sidekicks">Sidekick Statistics</Link>
                          </h5>
                          <Row>
                            <Col xl="3" md="6" xs="12" className="text-center">
                              <Label className="total-statistics">
                                <CountTo from={0} to={this.state.data.sidekicks.total_sidekicks.requested.value} speed={this.state.countToSpeed} />
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Requested Sidekicks
                              { this.state.data.sidekicks.total_sidekicks.requested.description && this.renderInfoDescription(this.state.data.sidekicks.total_sidekicks.requested.description, "total_sidekicks_requested")}
                              </Label>
                            </Col>
                            <Col xl="3" md="6" xs="12" className="text-center">
                              <Label className="active-statistics">
                                <CountTo from={0} to={this.state.data.sidekicks.total_sidekicks.approved.value} speed={this.state.countToSpeed} />
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Approved Sidekicks
                              { this.state.data.sidekicks.total_sidekicks.approved.description && this.renderInfoDescription(this.state.data.sidekicks.total_sidekicks.approved.description, "total_sidekicks_approved")}
                              </Label>
                            </Col>
                            <Col xl="3" md="6" xs="12"className="text-center">
                              <Label className="inactive-statistics">
                                <CountTo from={0} to={this.state.data.sidekicks.total_sidekicks.rejected.value} speed={this.state.countToSpeed} />
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Rejected Sidekicks
                              { this.state.data.sidekicks.total_sidekicks.rejected.description && this.renderInfoDescription(this.state.data.sidekicks.total_sidekicks.rejected.description, "total_sidekicks_rejected")}
                              </Label>
                            </Col>
                            <Col xl="3" md="6" xs="12" className="text-center">
                              <Label className="warning-statistics">
                                <CountTo from={0} to={this.state.data.sidekicks.total_sidekicks.pending.value} speed={this.state.countToSpeed} />
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Pending Sidekicks
                              { this.state.data.sidekicks.total_sidekicks.pending.description && this.renderInfoDescription(this.state.data.sidekicks.total_sidekicks.pending.description, "total_sidekicks_pending")}
                              </Label>
                            </Col>
                          </Row>
                          <hr></hr>
                          <Row>
                            <Col xl="4" md="4" xs="12" className="text-center">
                              <Label className="active-statistics">
                                <CountTo from={0} to={this.state.data.sidekicks.have_least_one_session.value} speed={this.state.countToSpeed} />
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Sidekicks that have had a session at least once
                              { this.state.data.sidekicks.have_least_one_session.description && this.renderInfoDescription(this.state.data.sidekicks.have_least_one_session.description, "sidekick_have_least_one_session")}
                              </Label>
                            </Col>

                            <Col xl="4" md="4" xs="12" className="text-center">
                              <Label className="total-statistics">
                                <CountTo from={0} to={this.state.data.sidekicks.fully_registered.value} speed={this.state.countToSpeed} />
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Sidekicks that are fully registered
                              { this.state.data.sidekicks.fully_registered.description && this.renderInfoDescription(this.state.data.sidekicks.fully_registered.description, "sidekick_fully_registered")}
                              </Label>
                            </Col>
                            <Col xl="4" md="4" xs="12" className="text-center">
                              <Label className="inactive-statistics">
                                <CountTo from={0} to={this.state.data.sidekicks.not_available.value} speed={this.state.countToSpeed} />
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Sidekicks that are not available
                              { this.state.data.sidekicks.not_available.description && this.renderInfoDescription(this.state.data.sidekicks.not_available.description, "sidekick_not_available")}
                              </Label>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  : ''}
                  { checkPermission('ambassador', 'read') ? 
                    <Col xl="12">
                      <Card className="border-gray-custom mb-2 bg-mintcream">
                        <CardBody>
                          <h5 className="mb-2 text-primary text-center">
                            <Link to="/sidekicks">Sidekick Ambassador Statistics</Link>
                          </h5>
                          <Row>
                            <Col xl="4" md="4" xs="12" className="text-center">
                              <Label className="total-statistics">
                                <CountTo from={0} to={this.state.data.ambassadors.total.value} speed={this.state.countToSpeed} />
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Total number of Ambassadors
                              { this.state.data.ambassadors.total.description && this.renderInfoDescription(this.state.data.ambassadors.total.description, "ambassadors_total")}
                              </Label>
                            </Col>
                            <Col xl="4" md="4" xs="12" className="text-center">
                              <Label className="active-statistics">
                                <CountTo from={0} to={this.state.data.ambassadors.sidekicks_by_ambassador.value} speed={this.state.countToSpeed} />
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Sidekicks by Ambassador
                              { this.state.data.ambassadors.sidekicks_by_ambassador.description && this.renderInfoDescription(this.state.data.ambassadors.sidekicks_by_ambassador.description, "sidekicks_by_ambassador")}
                              </Label>
                            </Col>
                            <Col xl="4" md="4" xs="12" className="text-center">
                              <Label className="total-statistics">
                                <span>{this.state.data.ambassadors.revenue_sidekicks_by_ambassador.value ? formatter.format(this.state.data.ambassadors.revenue_sidekicks_by_ambassador.value) : 0}</span>
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Revenue of Sidekicks by Ambassadors
                              { this.state.data.ambassadors.revenue_sidekicks_by_ambassador.description && this.renderInfoDescription(this.state.data.ambassadors.revenue_sidekicks_by_ambassador.description, "revenue_sidekicks_by_ambassador")}
                              </Label>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  : '' }
                  { checkPermission('appointment', 'read') ? 
                    <Col xl="12">
                      <Card className="border-gray-custom mb-2 bg-aliceblue">
                        <CardBody>
                          <h5 className="mb-2 text-primary text-center">
                            <Link to="/sidekicks">Session Statistics</Link>
                          </h5>
                          <Row>
                            <Col xl="2" md="2" xs="12"  className="text-center">
                              <Label className="active-statistics">
                                <CountTo from={0} to={this.state.data.sessions.total_sessions.completed.value} speed={this.state.countToSpeed} />
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Completed sessions
                              { this.state.data.sessions.total_sessions.completed.description && this.renderInfoDescription(this.state.data.sessions.total_sessions.completed.description, "total_sessions_completed")}
                              </Label>
                            </Col>
                            <Col xl="8" md="8" xs="12" className="text-center">
                              <Row>
                                <Col xl="4" md="4" xs="12">
                                  <Label className="inactive-statistics">
                                    <CountTo from={0} to={this.state.data.sessions.total_sessions.incomplete_by_sidekick.value} speed={this.state.countToSpeed} />
                                  </Label>
                                  <br></br>
                                  <Label className="font-weight-bold">Incomplete sessions by sidekick
                                  { this.state.data.sessions.total_sessions.incomplete_by_sidekick.description && this.renderInfoDescription(this.state.data.sessions.total_sessions.incomplete_by_sidekick.description, "total_sessions_incomplete_by_sidekick")}
                                  </Label>
                                </Col>
                                <Col xl="4" md="4" xs="12">
                                  <Label className="inactive-statistics">
                                    <CountTo from={0} to={this.state.data.sessions.total_sessions.incomplete_by_user.value} speed={this.state.countToSpeed} />
                                  </Label>
                                  <br></br>
                                  <Label className="font-weight-bold">Incomplete sessions by user
                                  { this.state.data.sessions.total_sessions.incomplete_by_user.description && this.renderInfoDescription(this.state.data.sessions.total_sessions.incomplete_by_user.description, "total_sessions_incomplete_by_user")}
                                  </Label>
                                </Col>
                                <Col xl="4" md="4" xs="12">
                                  <Label className="inactive-statistics">
                                    <CountTo from={0} to={this.state.data.sessions.total_sessions.incomplete_by_both.value} speed={this.state.countToSpeed} />
                                  </Label>
                                  <br></br>
                                  <Label className="font-weight-bold">Incomplete sessions by both
                                  { this.state.data.sessions.total_sessions.incomplete_by_both.description && this.renderInfoDescription(this.state.data.sessions.total_sessions.incomplete_by_both.description, "total_sessions_incomplete_by_both")}
                                  </Label>
                                </Col>
                              </Row>
                            </Col>
                            <Col xl="2" md="2" xs="12" className="text-center">
                              <Label className="total-statistics">
                                <CountTo from={0} to={this.state.data.sessions.total_sessions.reserved.value} speed={this.state.countToSpeed} />
                              </Label>
                              <br></br>
                              <Label className="font-weight-bold">Reserved sessions
                              { this.state.data.sessions.total_sessions.reserved.description && this.renderInfoDescription(this.state.data.sessions.total_sessions.reserved.description, "total_sessions_reserved")}
                              </Label>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  : '' }
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Admin
