import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Button,
  Label,
  FormFeedback,
  Modal,
  ModalHeader,
} from "reactstrap"
import Select from 'react-select'
import expertService from "../../services/expert"
import skillService from "../../services/skill"
import { checkPermission, customUrlParams } from 'helpers/supportFunction';
import toast from 'helpers/toast';
import ModalConfirm from "components/modals/modalConfirm"
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

class tableExpertSkills extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expertSkills: [],
      skillsOpt: [],
      skillsList: [],
      currentSkill: {
        skill_id: null,
        order: 1
      },
      isDelete: false,
      isNew: false,
      isSubmit: false,
      dxStore: this.createDxStore()
    }
  }

  componentDidMount = async () => {
    if (localStorage.getItem('userSidekick') && !checkPermission("skill", "read") && this.props.expert_id) {
      toast.warning("You do not have permission to manage skills", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      await this.loadData();
      await this.loadDataSkills()
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = `&expert_id=${this.props.expert_id}`;
        loadOptions.take = '-1';
        let params = customUrlParams(loadOptions, url_params, 'skill_id', 'desc')
        var response = await expertService.getExpertSkills(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          this.setState({ expertSkills: response.data});
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  loadDataSkills = async () => {
    var response = await skillService.getAll(`&sortField=id&sortType=desc&limit=-1&offset=0`);
    if (response && response.success) {
      this.setState({
        skillsList: response.data.map((item) => { item.value = item.id; item.label = item.name; return item }),
      })
    }
  }

  onClose = () => {
    this.setState({
      currentSkill: null,
      isDelete: false,
      isNew: false,
      isSubmit: false
    })
  }

  onActionRemove = async () => {
    var response = await expertService.removeExpertSkill({ expert_id: this.props.expert_id, expert_skill_id: this.state.currentSkill.id});
    if (response && response.success) {
      toast.success("Remove skill success");
      this.onClose();
      await this.loadData();
    }
  }

  onActionAdd = async () => {
    this.setState({ isSubmit: true });
    if (this.state.currentSkill.skill_id && this.props.expert_id) {
      var response = await expertService.addExpertSkill({ expert_id: this.props.expert_id, skill_id: this.state.currentSkill.skill_id, order: 1});
      if (response && response.success) {
        toast.success("Add skill success");
        this.onClose();
        await this.loadData();
      }
    }
  }

  handleInput = (type, value) => {
    let currentSkill = { ...this.state.currentSkill }
    currentSkill[[type]] = value
    this.setState({ currentSkill: currentSkill })
  }

  linkEdit = (e) => {
    let skill = e.data;
    return (
      <>
        { checkPermission("skill", "delete") ?
          <>
            <Button
              className="btn-danger mx-1"
              onClick={async () => {
                this.setState({
                  title: "Remove Skill",
                  isDelete: true,
                  currentSkill: skill,
                })
              }}
            >
              Remove
            </Button>
          </>
          : 
        ''}
      </>
    )
  }

  onOpenModelAddNew = () => {
    let skillsOpt = this.state.skillsList.filter(skill => {
      return !this.state.expertSkills.find((item) => {return item.skill_id == skill.id})
    })
    this.setState({ skillsOpt: skillsOpt }, () =>
      this.setState({
        isNew: true,
        isSubmit: false,
        currentSkill: {
          skill_id: null
        },
      })
    )
  } 

  render() {
    return (
      <>
        <Row>
          <Col xl="12" className="text-right">
            { checkPermission("skill", "create") ?
              <Button
                color="primary"
                onClick={ () => this.onOpenModelAddNew()}
              >
                Add Skill
              </Button>
            :'' }
          </Col>
        </Row>
        <Row className="pt-2">
          <Col xl="12">
            <DataGrid
              dataSource={this.state.dxStore}
              showBorders={true}
              remoteOperations={true}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
              className="max-h-350"
            >
              <Column dataField="skill_id" name="skill_id" caption="ID" alignment={'center'} width={80}></Column>
              <Column dataField="name" name="name" caption="Name"></Column>
              <Column dataField="order" name="order" caption="Order" alignment={'center'} ></Column>
              <Column dataField="description" name="description" caption="Description"></Column>
              <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'} width={95}></Column>
            </DataGrid>
          </Col>
        </Row>
        <ModalConfirm
          isOpen={this.state.isDelete}
          title={'Confirmation Remove'}
          message={'Are you sure remove skill "' + (this.state.currentSkill && this.state.currentSkill.name ? this.state.currentSkill.name : '') + '" for this sidekick?'}
          textButton={'Remove'}
          colorButton={'danger'}
          onCloseModal={() => {
            this.onClose();
          }}
          onActionConfirm={ async () => {
            await this.onActionRemove()
          }}
        />

        <Modal
          isOpen={this.state.isNew}
          role="dialog"
          autoFocus={true}
          centered
          data-toggle="modal"
          backdrop={'static'}
          toggle={() => {
            this.onClose()
          }}
        >
          <div>
            <ModalHeader className="border-bottom-0" toggle={() => { this.onClose() }}></ModalHeader>
          </div>
          <div className="modal-body">
            <div className=" mb-4">
              <div className="row justify-content-center">
                {this.state.currentSkill ? 
                  <Col xl="11">
                    <h4 className="text-primary mb-3 text-center">
                      Add New Skill For Sidekick
                    </h4>
                    <Row>
                      <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                        <Label for="skill" style={{"paddingLeft": "0"}}>Skill</Label>
                        <Select
                          name="skill"
                          classNamePrefix="select"
                          value={this.state.currentSkill && this.state.currentSkill.skill_id && this.state.skillsOpt && this.state.skillsOpt.length > 0 ? 
                            this.state.skillsOpt.find((item) => {return item.value == this.state.currentSkill.skill_id}): null }
                          isClearable={false}
                          isSearchable={true}
                          options={this.state.skillsOpt}  
                          onChange={e => {this.handleInput("skill_id", e.value)}}
                          placeholder="Select Skill"
                          className={ this.state.isSubmit && !this.state.currentSkill.skill_id ? 'is-invalid' : ''}
                        />
                          {
                            this.state.isSubmit && !this.state.currentSkill.skill_id ? 
                              <FormFeedback type="invalid" className="invalid-inline">
                                Skill is required
                              </FormFeedback>
                            : ''
                          }
                      </Col>
                    </Row>
                    <Button
                      className="mt-3 d-block mx-auto btn-custom-width"
                      color="primary"
                      type="button"
                      onClick={() => {
                        this.onActionAdd()
                      }}
                    >
                      Add
                    </Button>
                  </Col>
                  : ''
                }
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

tableExpertSkills.propTypes = {
  expert_id: PropTypes.any.isRequired
}

export default tableExpertSkills
