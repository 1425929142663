import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom";
import Select from 'react-select'
import { Row, Col, Modal, ModalHeader, Input } from "reactstrap"
import appointmentService from "../../services/appointment"
import { checkPermission, customUrlParams } from "helpers/supportFunction"
import moment from "moment"
import toast from "helpers/toast"
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

const types = [
  {value: '', label: "All"},
  {value: 'end', label: "End"},
  {value: 'dial', label: "Dial"},
  {value: 'accept', label: "Accept"}
];

class AppointmentLogs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appointmentLogDetail: null,
      typeLog: '',
      isDetail: false,
      dxStore: this.createDxStore()
    }
  }

  componentDidMount() {
    if (localStorage.getItem("userSidekick") && !checkPermission("appointment", "read")) {
      toast.warning(
        "You do not have permission to manage sessions",
        "Invalid Permission"
      )
      setTimeout(() => {
        window.location = "/page-404"
      }, 3000)
    } else {
      if (!this.props.appointment_id) {
        toast.error("Session not found", "Invalid Session")
        setTimeout(() => {
          window.location = "/sessions"
        }, 3000)
      } else {
        this.loadData()
      }
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = "appointment_id=" + this.props.appointment_id;
        if (this.state.typeLog) {
          url_params += `&type=${this.state.typeLog}`
        }
        let params = customUrlParams(loadOptions, url_params, 'id', 'desc')
        var response = await appointmentService.getAppointmentLogs(params)
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  onClose = async () => {
    this.setState({
      isDetail: false,
      appointmentLogDetail: null,
    })
  }

  setDetailLog = (appointmentLog) => {
    this.setState({
      appointmentLogDetail: appointmentLog,
      isDetail: true,
    })
  }

  linkEdit = (e) => {
    let appointmentLog = e.data
    return (
      <Link to="#"
        className="btn btn-secondary btn-custom-width"
        onClick={ () => {
          this.setDetailLog(appointmentLog)
        }}
      >
        View Detail
      </Link>
    )
  }

  formatOccurredTime = (e) => {
    let appointmentLog = e.data
    return (
      <span>
        {typeof appointmentLog["occurred_time"] != "undefined"
          ? moment(appointmentLog.occurred_time).format('MM/DD/YYYY HH:mm:ss')
          : ""}
      </span>
    )
  }

  formatCreatedAt = (e) => {
    let appointmentLog = e.data
    return (
      <span>
        {typeof appointmentLog["created_at"] != "undefined"
          ? moment(appointmentLog.created_at).format('MM/DD/YYYY HH:mm:ss')
          : ""}
      </span>
    )
  }

  handleChangeType = (value) => {
    this.setState({ typeLog: value }, () => {
      this.loadData();
    })
  }

  render() {
    return (
      <Row className="pt-2">
        <Col xl="3">
          <label className="mb-1 fw-bold me-2">Type:</label>
          <Select
            classNamePrefix="select"
            defaultValue={this.state.typeLog}
            value={ types.find((item) => {return item.value == this.state.typeLog}) }
            isClearable={false}
            isSearchable={true}
            options={types}  
            onChange={e => {
              this.handleChangeType(e.value)
            }}
            placeholder="Select Type"
            style={{ width: "100%" }}
          />
        </Col>
        <Col xl="12" className="pt-2">
          <DataGrid
            dataSource={this.state.dxStore}
            showBorders={true}
            remoteOperations={true}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            hoverStateEnabled={true}
          >
            <Column dataField="id" name="id" caption="Session Log ID" alignment={'center'}></Column>
            <Column dataField="call_id" name="call_id" caption="Call ID"></Column>
            <Column dataField="action_by" name="action_by" caption="Action By"></Column>
            <Column dataField="type" name="type" caption="Type"></Column>
            <Column dataField="occurred_time" name="occurred_time" caption="Occurred Time" cellRender={this.formatOccurredTime}></Column>
            <Column dataField="created_at" name="created_at" caption="Created At" cellRender={this.formatCreatedAt}></Column>
            <Column dataField="Actions" name="Actions" caption="Actions" allowFixing={true} allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'left'} width={135}></Column>
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
              allowedPageSizes={[10, 25, 50, 100]}
            />
          </DataGrid>
        </Col>
        <Modal
          isOpen={this.state.isDetail}
          role="dialog"
          autoFocus={true}
          centered
          data-toggle="modal"
          toggle={() => {
            this.onClose()
          }}
          size="lg"
        >
          <div>
            <ModalHeader
              className="border-bottom-0"
              toggle={() => {
                this.onClose()
              }}
            ></ModalHeader>
          </div>
          <div className="modal-body pt-0">
            <div className=" mb-4">
              <Row className=" justify-content-center">
                <Col xl="11">
                  <h4 className="text-primary mb-3 text-center">
                    Detail Session Log
                  </h4>
                  {this.state.appointmentLogDetail &&
                  this.state.appointmentLogDetail.id ? (
                    <>
                    <Row>
                      <Col xl="6">
                        <div className="d-flex mb-3">
                          <span className=" fw-bold me-2">Session Log ID:</span>
                          <p className="mb-0">
                            {this.state.appointmentLogDetail.id
                              ? this.state.appointmentLogDetail.id
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="d-flex mb-3">
                          <span className=" fw-bold me-2">Call ID:</span>
                          <p className="mb-0">
                            {this.state.appointmentLogDetail.call_id
                              ? this.state.appointmentLogDetail.call_id
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="d-flex mb-3">
                          <span className=" fw-bold me-2">Session ID:</span>
                          <p className="mb-0">
                            {this.state.appointmentLogDetail.appointment_id
                              ? this.state.appointmentLogDetail.appointment_id
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="d-flex mb-3">
                          <span className=" fw-bold me-2">Sidekick Id:</span>
                          <p className="mb-0">
                            {this.state.appointmentLogDetail.expert_id
                              ? this.state.appointmentLogDetail.expert_id
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="d-flex mb-3">
                          <span className=" fw-bold me-2">User Id:</span>
                          <p className="mb-0">
                            {this.state.appointmentLogDetail.customer_id
                              ? this.state.appointmentLogDetail.customer_id
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="d-flex mb-3">
                          <span className=" fw-bold me-2">Action By:</span>
                          <p className="mb-0">
                            {this.state.appointmentLogDetail.action_by
                              ? this.state.appointmentLogDetail.action_by
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="d-flex mb-3">
                          <span className=" fw-bold me-2">Type:</span>
                          <p className="mb-0">
                            {this.state.appointmentLogDetail.type
                              ? this.state.appointmentLogDetail.type
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="d-flex mb-3">
                          <span className=" fw-bold me-2">Occurred Time:</span>
                          <p className="mb-0">
                            {this.state.appointmentLogDetail.occurred_time
                              ? moment(this.state.appointmentLogDetail.occurred_time).format(
                                process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT
                              )
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="d-flex mb-3">
                          <span className=" fw-bold me-2">Created At:</span>
                          <p className="mb-0">
                            {this.state.appointmentLogDetail.created_at
                              ? moment(this.state.appointmentLogDetail.created_at).format(
                                process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT
                              )
                              : ""}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    </>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      </Row>
    )
  }
}

AppointmentLogs.propTypes = {
  appointment_id: PropTypes.any,
}

export default AppointmentLogs
