import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalHeader,
  FormFeedback,
  Label,
  Card,
  CardBody
} from "reactstrap"
import Select from 'react-select'
import { Link } from "react-router-dom";
import adminService from "../../services/admin"
import roleService from "../../services/role"
import MetaTags from "react-meta-tags"
import { checkPermission, customUrlParams, capitalizeFirstLetter } from 'helpers/supportFunction';
import moment from "moment";
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

const STATUS_ARRAY = [{value: 'active', label: 'Active'}, {value: 'inactive', label: 'Inactive'}];
const authUser = localStorage.getItem('userSidekick') ? JSON.parse(localStorage.getItem('userSidekick')) : null;

class Admin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      roles: [],
      isSubmit: false,
      isDetail: false,
      isEditCreate: false,
      currentAdmin: null,
      search: '',
      dxStore: this.createDxStore()
    }
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("account", "read")) {
      toast.warning("You do not have permission to manage admins", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.loadRoles();
      this.loadData();
    }
  }

  onClose = () => {
    this.setState({
      currentAdmin: null,
      isSubmit: false,
      isEditCreate: false,
      isDetail: false
    })
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = ""
        if (this.state.search) {
          url_params += `&search=${this.state.search}`;
        }
        let params = customUrlParams(loadOptions, url_params, 'id', 'desc')
        var response = await adminService.getAll(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  loadRoles = async () => {
    let response = await roleService.getAll();
    if (response && response.success) {
      this.setState({
        roles: response.data.map(item => { item.value = item.id; item.label = item.name; return item  }),
      })
    }
  }

  handleInput = (type, value) => {
    let currentAdmin = { ...this.state.currentAdmin }
    currentAdmin[[type]] = value
    this.setState({ currentAdmin: currentAdmin })
  }

  onCheckSubmitAdmin = () => {
    if (this.state.currentAdmin && !this.state.currentAdmin.id) {
      return this.state.currentAdmin && this.state.currentAdmin.email && this.state.currentAdmin.password && this.state.currentAdmin.password == this.state.currentAdmin.tmp_password && this.state.currentAdmin.role_id;
    } else {
      return this.state.currentAdmin && this.state.currentAdmin.email && this.state.currentAdmin.role_id && this.state.currentAdmin.status;
    }
  }

  onClickCreate = async () => {
    this.setState({
      isSubmit: true
    });
    if (this.onCheckSubmitAdmin() && this.isValidEmail(this.state.currentAdmin.email)) {
      delete this.state.currentAdmin.admin_id;
      let response = await adminService.createAdmin(this.state.currentAdmin)
      if (response.success) {
        toast.success("Create admin success");
        this.onClose();
        this.loadData();
      }
    }
  }

  customId = (e) => {
    let admin = e.data;
    return (
      <Link to="#" onClick={async () => {
          this.setState({
            title: "Admin Detail",
            isEditCreate: false,
            isDetail: true,
            currentAdmin: admin,
          })
        }}
      >
        {admin.id}
      </Link>
    )
  }

  isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  onClickUpdate = async admin => {
    this.setState({
      isSubmit: true
    });
    if (this.onCheckSubmitAdmin()) {
      let response = await adminService.updateAdmin({
        admin_id: admin.id,
        role_id: admin.role_id,
        status: admin.status,
        email: admin.email
      })
      if (response.success) {
        toast.success("Update admin success");
        this.onClose();
        this.loadData();
        if (authUser && authUser.id == admin.id && admin.email) {
          authUser.email = admin.email;
          localStorage.setItem("userSidekick",  JSON.stringify(authUser));
        }
      }
    }
  }

  linkEdit= (e) => {
    let admin = e.data;
    return (
      <>
        { checkPermission("account", "update") ?
          <>
            <Button
              className="mx-1 btn-custom-width"
              color="primary"
              onClick={() =>
                this.setState({
                  isEditCreate: true,
                  title: "Update Admin",
                  currentAdmin: admin
                })
              }
            >
              Update
            </Button>
          </>
        : ''}
      </>
    )
  }

  formatCreatedAt = (e) => {
    let admin = e.data;
    return (
      <span>
        { typeof admin["created_at"] != "undefined" ? moment(admin.created_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''}
      </span>
    )
  }

  formatUpdatedAt = (e) => {
    let admin = e.data;
    return (
      <span>
        { typeof admin["updated_at"] != "undefined" ? moment(admin.updated_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''}
      </span>
    )
  }

  customStatus = (e) => {
    let admin = e.data;
    return (
      <span>
      { admin.status ? capitalizeFirstLetter(admin.status) : ''}
      </span>
    )
  }

  handleInputSearch = e => {
    this.setState({ search: e }, () => 
      this.loadData(null, null)
    );
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Admins | Sidekick </title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12" className="d-flex align-items-center pb-2">
                    <h4 className="mb-0 me-3 text-primary">Manage Admins</h4>
                  </Col>
                  <Col xl="4" md="6" className="pt-2 pb-2">
                    <Input
                      type="search"
                      id="search"
                      className="form-control"
                      placeholder="Search..."
                      value={this.state.search}
                      onChange={e => {
                        this.handleInputSearch(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xl="8" md="6" className="text-right pb-2 pt-2">
                    {checkPermission("account", "create") ?
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setState({
                            title: "Create Admin",
                            isEditCreate: true,
                            currentAdmin: {
                              email: "",
                              password: "",
                              tmp_password: "",
                              role_id: "",
                            },
                          })
                        }}
                      >
                        Create Admin
                      </Button>
                    : ''}
                  </Col>
                </Row>
                <Row className="pt-1">
                  <Col xl="12">
                    <DataGrid
                      dataSource={this.state.dxStore}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                    >
                      <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80} cellRender={this.customId}></Column>
                      <Column dataField="email" name="email" caption="Email"></Column>
                      <Column dataField="role_name" name="role_name" caption="Role"></Column>
                      <Column dataField="status" name="status" caption="Status" cellRender={this.customStatus}></Column>
                      <Column dataField="created_at" name="created_at" caption="Created At" cellRender={this.formatCreatedAt}></Column>
                      <Column dataField="updated_at" name="updated_at" caption="Updated At" cellRender={this.formatUpdatedAt}></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'} width={125}></Column>

                      <Paging defaultPageSize={10} />
                      <Pager
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        allowedPageSizes={[10, 25, 50, 100]}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Modal
              role="dialog"
              isOpen={this.state.isDetail}
              autoFocus={true}
              centered
              data-toggle="modal"
              toggle={() => this.onClose()}
            >
              <div>
                <ModalHeader
                  className="border-bottom-0"
                  toggle={() => this.onClose()}
                ></ModalHeader>
              </div>
              <div className="modal-body" style={{"paddingTop": "0"}}>
                <div className="mb-4">
                  <Row className="justify-content-center">
                  { this.state.currentAdmin ? 
                    <Col xl="11">
                      <h4 className="text-primary text-center mb-4">Admin Detail</h4>
                      <Row>
                        <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                          <div className="d-flex">
                            <span className="me-2">Email:</span>
                            <p className="fw-bold"> {this.state.currentAdmin.email}</p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                          <div className="d-flex">
                            <span className="me-2">Role:</span>
                            <p className="fw-bold"> {this.state.currentAdmin.role_name}</p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                          <div className="d-flex">
                            <span className="me-2">Status:</span>
                            <p className="fw-bold"> {this.state.currentAdmin.status}</p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                          <div className="d-flex">
                            <span className="me-2">Created At:</span>
                            <p className="fw-bold">
                              { this.state.currentAdmin.created_at ? 
                                moment(this.state.currentAdmin.created_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                                : ''
                              }
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                          <div className="d-flex">
                            <span className="me-2">Updated At:</span>
                            <p className="fw-bold">
                              { this.state.currentAdmin.updated_at ? 
                                moment(this.state.currentAdmin.updated_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                                : ''
                              }
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    : 
                  ''}
                  </Row>
                </div>
              </div>
            </Modal>
            <Modal
              isOpen={this.state.isEditCreate}
              role="dialog"
              autoFocus={true}
              centered
              data-toggle="modal"
              backdrop={'static'}
              toggle={() => {
                this.onClose()
              }}
            >
              <div>
                <ModalHeader
                  className="border-bottom-0"
                  toggle={() => {
                    this.onClose()
                  }}
                ></ModalHeader>
              </div>
              <div className="modal-body">
                <div className=" mb-4">
                  <div className="row justify-content-center">
                    {this.state.currentAdmin ? 
                      <Col xl="11">
                        <h4 className="text-primary mb-3 text-center">
                          {this.state.title}
                        </h4>
                        <Input className="input-fake" name="username" type="text" autoComplete="true"/>
                        <Input className="input-fake" name="password" type="password" autoComplete="true"/>
                        {this.state.currentAdmin && authUser && authUser.id != this.state.currentAdmin.id ?
                          <Row>
                            <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                            <Label for="role" style={{"paddingLeft": "0"}}>Role</Label>
                            <Select
                              name="role"
                              classNamePrefix="select"
                              defaultValue={this.state.currentAdmin.role_id ? this.state.currentAdmin.role_id : null}
                              value={ this.state.roles.find((item) => {return item.value == this.state.currentAdmin.role_id}) }
                              isClearable={false}
                              isSearchable={true}
                              options={this.state.roles}  
                              onChange={e => {this.handleInput("role_id", e.value)}}
                              placeholder="Select Role"
                              className={ this.state.isSubmit && !this.state.currentAdmin.role_id ? 'is-invalid' : ''}
                            />
                              {
                                this.state.isSubmit && !this.state.currentAdmin.role_id ? 
                                  <FormFeedback type="invalid" className="invalid-inline">
                                    Role is required
                                  </FormFeedback>
                                : ''
                              }
                            </Col>
                          </Row>
                        : ''}
                        <Row>
                          <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                            <Label for="email" style={{"paddingLeft": "0"}}>Email</Label>
                            <div className="input-group rounded bg-light">
                              <Input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email address"
                                value={this.state.currentAdmin.email}
                                onChange={e => {
                                  this.handleInput("email", e.target.value)
                                }}
                                invalid={
                                  this.state.isSubmit &&
                                  (!this.state.currentAdmin.email || this.state.currentAdmin.email && !this.isValidEmail(this.state.currentAdmin.email))
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            {
                              this.state.isSubmit &&
                              !this.state.currentAdmin.email ? 
                                <FormFeedback type="invalid" className="invalid-inline">
                                  Email is required
                                </FormFeedback>
                              : ''
                            }
                            {
                              this.state.isSubmit && this.state.currentAdmin.email && !this.isValidEmail(this.state.currentAdmin.email) ? 
                                <FormFeedback type="invalid" className="invalid-inline">
                                  Email is invalid
                                </FormFeedback>
                              : ''
                            }
                          </Col>
                        </Row>
                        {this.state.isEditCreate && !this.state.currentAdmin.id  ? (
                          <>
                            <Row>
                              <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                                <Label for="password" style={{"paddingLeft": "0"}}>Password</Label>
                                <div className="input-group rounded bg-light">
                                  <Input
                                    id="password"
                                    name="password"
                                    value={this.state.currentAdmin.password}
                                    type="password"
                                    placeholder="Password"
                                    onChange={e => {
                                      this.handleInput("password", e.target.value)
                                    }}
                                    invalid={
                                      this.state.isSubmit &&
                                      !this.state.currentAdmin.password
                                        ? true
                                        : false
                                    }
                                  />
                                  {
                                    this.state.isSubmit && !this.state.currentAdmin.password ? 
                                      <FormFeedback type="invalid" className="invalid-inline">
                                        Password is required
                                      </FormFeedback>
                                    : ''
                                  }
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                                <Label for="tmp_password" style={{"paddingLeft": "0"}}>Confirm Password</Label>
                                <div className="input-group rounded bg-light">
                                  <Input
                                    name="tmp_password"
                                    id="tmp_password"
                                    value={this.state.currentAdmin.tmp_password}
                                    type="password"
                                    placeholder="Confirm Password"
                                    onChange={e => {
                                      this.handleInput("tmp_password", e.target.value)
                                    }}
                                    invalid={
                                      this.state.isSubmit &&
                                      !this.state.currentAdmin.password
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                                {
                                  this.state.isSubmit && !this.state.currentAdmin.tmp_password ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      Confirm Password is required
                                    </FormFeedback>
                                  : ''
                                }
                                {
                                  this.state.isSubmit && this.state.currentAdmin.tmp_password && this.state.currentAdmin.password != this.state.currentAdmin.tmp_password ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      Confirm password does not match
                                    </FormFeedback>
                                  : ''
                                }
                              </Col>
                            </Row>
                          </>
                        ) : '' }
                        {this.state.currentAdmin && this.state.currentAdmin.id && authUser && authUser.id != this.state.currentAdmin.id?
                          <Row>
                            <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                              <Label for="password" style={{"paddingLeft": "0"}}>Status</Label>
                              
                              <Select
                                name="status"
                                classNamePrefix="select"
                                defaultValue={this.state.currentAdmin.status ? this.state.currentAdmin.status : null}
                                value={ STATUS_ARRAY.find((item) => {return item.value == this.state.currentAdmin.status}) }
                                isClearable={false}
                                isSearchable={true}
                                options={STATUS_ARRAY}  
                                onChange={e => {this.handleInput("status", e.value)}}
                                placeholder="Select Status"
                                className={ this.state.isSubmit && !this.state.currentAdmin.status ? 'is-invalid' : ''}
                              />
                                {
                                  this.state.isSubmit && !this.state.currentAdmin.status ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      Status is required
                                    </FormFeedback>
                                  : ''
                                }
                            </Col>
                          </Row>
                        : ''
                        }
                        {this.state.currentAdmin && !this.state.currentAdmin.id ?
                          ( 
                            <Button
                              className="mt-3 d-block mx-auto"
                              color="primary"
                              type="button"
                              id="button-create"
                              onClick={() => {
                                this.onClickCreate()
                              }}
                            >
                              Create
                            </Button>
                          ) : 
                          (
                            <Button
                              className="mt-3  d-block mx-auto"
                              color="primary"
                              type="button"
                              id="button-update"
                              onClick={() => {
                                this.onClickUpdate(this.state.currentAdmin)
                              }}
                            >
                              Update
                            </Button>
                          ) 
                        }  
                      </Col>
                      : ''
                    }
                  </div>
                </div>
              </div>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Admin
