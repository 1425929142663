import axios from '../services/axios-service';

async function getAll(body) {
  let data = await axios.get(`/admin/role?${body ?? ''}`);
  return data
}

async function createRole(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/role/create", urlencoded);
  return data
}

async function deleteRole(body) {
  var urlencoded = new URLSearchParams({ role_id: body })
  let data = await axios.post("/admin/role/delete", urlencoded);
  return data;
}

async function updateRole(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post("/admin/role/update", urlencoded);
  return data;
}

async function getDetailRole(body) {
  let url = `/admin/role/detail?role_id=${body}`;
  let data = await axios.get(url);
  return data
}

export default {
  getAll: getAll,
  createRole: createRole,
  deleteRole: deleteRole,
  updateRole: updateRole,
  getDetailRole: getDetailRole,
}
