import React, { Component } from "react"
import {
  Row,
  Col,
  Button,
  Input,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap"
import Select from 'react-select'
import { Link } from "react-router-dom";
import expertAmbassadorService from "../../services/ambassador";
import { checkPermission, customUrlParams, capitalizeFirstLetter, formatAmountUs } from 'helpers/supportFunction';
import moment from "moment";
import toast from 'helpers/toast';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

class TableSidekickAmbassadorReport extends Component {
  constructor(props) {
    super(props)
    this.selectAmbassador = React.createRef();
    this.state = {
      title: "",
      isUpdate: false,
      isSubmit: false,
      detailAppointment: null,
      filterData: {
        start_time: '',
        end_time: '',
        search: ''
      },
      dxStore: this.createDxStore(),
      ambassadorOptions: [],
      waiting: false
    }
  }

  componentDidMount = async () => {
    this.loadData()
    let ambassadors = [];
    var response = await expertAmbassadorService.getAll('status=active&limit=-1&offset=0');
    if(response.success) {
      ambassadors = response.data.map(item => { item.value = item.id; item.label = item.name; return item  });
    }
    this.setState({ambassadorOptions: ambassadors, waiting: false});
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = ``;
        if (this.state.filterData) {
          if (this.state.filterData.start_time && this.state.filterData.end_time) {
            url_params += `&start_time=${moment(this.state.filterData.start_time).format("YYYY-MM-DD HH:mm:ss")}&end_time=${moment(this.state.filterData.end_time).format("YYYY-MM-DD HH:mm:ss")}`;
          }
          if (this.state.filterData.search) {
            url_params += `&search=${this.state.filterData.search}`;
          }
          if (this.state.filterData.ambassador_id) {
            url_params += `&ambassador_id=${this.state.filterData.ambassador_id}`;
          }
        }
        let params = customUrlParams(loadOptions, url_params, 'id', 'desc')
        var response = await expertAmbassadorService.getAmbassadorReport(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  onFilter = () => {
    if (this.state.filterData.start_time && this.state.filterData.end_time) {
      if (this.state.filterData.start_time > this.state.filterData.end_time) {
        toast.warning("Please enter start time less than or equal to end time");
        return;
      }
    }
    this.loadData()
  }

  onReset = () => {
    this.setState({
      filterData: {
        start_time: '',
        end_time: '',
        search: '',
        ambassador_id: null
      }
    }, () => {
      this.selectAmbassador.current.clearValue();
      this.loadData();
    }
    );
  }

  customUserId = (e) => {
    let data = e.data;
    return (
      checkPermission("user", "read") ? 
        <>
          <Link to={`/users/${data.user_id}/detail`}>
            { data.user_id }
          </Link>
        </> :
        <span> {data.user_id} </span>
    )
  }

  customName = (e) => {
    let data = e.data;
    return (
      checkPermission("user", "read") ? 
        <>
          <Link to={`/users/${data.user_id}/detail`}>
            { data.name }
          </Link>
        </> :
        <span> {data.name} </span>
    )
  }

  customStatus = (e) => {
    let data = e.data;
    return (
      <span> { capitalizeFirstLetter(data.status)} </span>
    )
  }

  formatStartAt = (e) => {
    let data = e.data
    return (
      <span>
        { data.started_at ? moment(data.started_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''}
      </span>
    )
  }

  formatEndAt = (e) => {
    let data = e.data
    return (
      <span>
        { data.ended_at ? moment(data.ended_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''}
      </span>
    )
  }

  onExportFile = async (type) => {
    let response = await expertAmbassadorService.exportAmbassadorReport(`type=${type}`);
    if (response && response.success) {
       window.open(process.env.REACT_APP_BASE_URL +'/'+ response.data);
    }
  }

  customSidekicks = (e) => {
    let data = e.data;
    return <table className="table-full-width">
        <tbody>
          { 
            data.experts.map((item, index) => {
              return <tr 
                key={index}
                className={`max-height-35 ${index < data.experts.length - 1 ? 'border-bt-table' : ''}`}
              >
                <td>
                {
                  checkPermission("ambassador", "read") ? 
                    <Link className="p-2" to={`/sidekicks/${item.id}/detail`}>
                      {item.name}
                    </Link>
                  : <span className="p-2">{item.name}</span>
                }
                </td>
              </tr>
            })
          }
        </tbody>
      </table>
  }

  customTotalBooking = (e) => {
    let data = e.data;
    return <table className="table-full-width">
        <tbody>
          { 
            data.experts.map((item, index) => {
              return <tr
                key={index}
                className={`d-center-flex max-height-35 ${index < data.experts.length - 1 ? 'border-bt-table' : ''}`}
                >
                <td>
                  <span className="p-2">{item.total_booking ? item.total_booking : 0}</span>
                </td>
              </tr>
            })
          }
        </tbody>
      </table>
  }


  customTotalRevenue = (e) => {
    let data = e.data;
    return <table className="table-full-width">
        <tbody>
          { 
            data.experts.map((item, index) => {
              return <tr
                key={index}
                className={`d-end-flex max-height-35 ${index < data.experts.length - 1 ? 'border-bt-table' : ''}`}
              >
                <td>
                  <span className="p-2">{item.transfer_count ? formatAmountUs(item.transfer_count) : 0}</span>
                </td>
              </tr>
            })
          }
        </tbody>
      </table>
  }

  customId = (e) => {
    let data = e.data
    return (
      checkPermission("ambassador", "read") ? 
      <Link to={`/sidekick-ambassadors/${data.id}/detail`}
      >
        {data.id}
      </Link>
      : <span>{data.id}</span>
    )
  }

  handleInput = (type, value) => {
    let filterData = { ...this.state.filterData }
    filterData[[type]] = value
    this.setState({ filterData: filterData }, () => {
      if (type == 'search' || type == 'ambassador_id' || (this.state.filterData.start_time && this.state.filterData.end_time && this.state.filterData.start_time < this.state.filterData.end_time)) {
        this.loadData();
      }
    });
  }

  onCellPrepared = (e) => {
    if(e.rowType === "data" && ["sidekicks", "total_booking", "transfer_count"].includes(e.column.dataField)) {
      e.cellElement.style.padding = 0;
    }
  }

  render() {
    return (
      <>
        <Row className="">
          <Col xl="2" className="pt-2 pb-2">
            <label className="mb-1 fw-bold me-2">Search By</label>
            <Input
              type="search"
              id="search"
              className="form-control"
              placeholder="Search..."
              value={this.state.filterData.search}
              onChange={e => {
                this.handleInput("search", e.target.value)
              }}
            />
          </Col>
          <Col xl="2" className="pt-2 pb-2">
            <label className="mb-1 fw-bold me-2">Ambassador:</label>
            <Select
              name="user"
              ref={this.selectAmbassador}
              classNamePrefix="select"
              defaultValue={this.state.filterData.ambassador_id ? this.state.filterData.ambassador_id : null}
              isClearable={true}
              isSearchable={true}
              options={this.state.ambassadorOptions}  
              onChange={e => {this.handleInput("ambassador_id", e?.value)}}
              placeholder="Ambassador"
            />
          </Col>
          <Col xl="2" className="pt-2 pb-2">
            <label className="mb-1 fw-bold me-2">Start Time:</label>
            <div className="d-flex">
              <DatePicker
                selected={this.state.filterData.start_time}
                onChange={e => {
                  this.handleInput("start_time", e)
                }}
                className="form-control"
                showTimeSelect
                dropdownMode="select"
                timeIntervals={10}
                timeFormat="HH:mm"
                dateFormat="MM/dd/yyyy HH:mm"
                placeholderText={'Start time'} 
              />
            </div>
          </Col>
          <Col xl="2" className="pt-2 pb-2">
            <label className="mb-1 fw-bold me-2">End Time:</label>
            <div className="d-flex">
              <DatePicker
                selected={this.state.filterData.end_time}
                onChange={e => {
                  this.handleInput("end_time", e)
                }}
                className="form-control"
                showTimeSelect
                dropdownMode="select"
                timeIntervals={10}
                timeFormat="HH:mm"
                dateFormat="MM/dd/yyyy HH:mm"
                placeholderText={'End time'} 
              />
            </div>
          </Col>
          <Col xl="2" md="6" xs="6" className="d-flex align-items-end pt-2 pb-2">
            <div>
            <Button
              color="primary"
              onClick={() => {
                this.onFilter()
              }}
            >
              Search
            </Button>
            </div>
            
            <Button
              onClick={() => {
                this.onReset()
              }}
              className="ml-10px"
            >
              Reset
            </Button>
          </Col>
          <Col xl="2" md="6" xs="6" className="d-flex justify-content-end align-items-end pt-2 pb-2">
            <UncontrolledDropdown>
              <DropdownToggle className="btn-custom-width d-flex justify-content-center align-items-center"
                caret color="primary"><i style={{paddingRight: '5px'}} className="bx bxs-download"></i> Export
              </DropdownToggle>
              <DropdownMenu style={{minWidth: '100px', inset: 'unset', right: '0', textAlign: 'center', padding: 0}}>
                <Button
                  onClick={() => {
                    this.onExportFile('excel')
                  }}
                  color="success"
                  className="btn-custom-width">
                  Excel
                </Button>
                <br />
                <Button
                  onClick={() => {
                    this.onExportFile('pdf')
                  }}
                  color="warning"
                  className="btn-custom-width"
                >
                  PDF
                </Button>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl="12" className="pt-1 ambassador-table">
            <DataGrid
              dataSource={this.state.dxStore}
              showBorders={true}
              remoteOperations={true}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
              onCellPrepared={this.onCellPrepared}
            >
              
              <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80} RowSpan="2" cellRender={this.customId}></Column>
              <Column dataField="name" name="name" caption="Name" cellRender={this.customName}></Column>
              <Column dataField="user_id" name="user_id" caption="User ID" cellRender={this.customUserId} alignment={'left'}></Column>
              <Column dataField="sidekicks" name="sidekicks" className="col-custom-table" allowSorting={false} allowFiltering={false} allowSearch={false} caption="Sidekicks" cellRender={this.customSidekicks}></Column>
              <Column dataField="total_booking" name="total_booking" allowSorting={false} allowFiltering={false} allowSearch={false} caption="Total Completed Session" cellRender={this.customTotalBooking}></Column>
              <Column dataField="transfer_count" name="transfer_count" allowSorting={false} allowFiltering={false} allowSearch={false} caption="Total Revenue" cellRender={this.customTotalRevenue}></Column>
              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
                allowedPageSizes={[10, 25, 50, 100]}
              />
            </DataGrid>
          </Col>
        </Row>
      </>
    )
  }
}

export default TableSidekickAmbassadorReport
