import React, { Component } from "react"
import PropTypes from "prop-types"
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import expertService from "services/expert"
import MetaTags from "react-meta-tags"
import FormEdit from "components/sidekicks/edit";
import { checkPermission } from 'helpers/supportFunction';
import toast from 'helpers/toast';

class EditUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      data: null,
    }
  }

  componentDidMount = async () => {
    if (localStorage.getItem('userSidekick') && !checkPermission("expert", "update")) {
      toast.warning("You do not have permission to manage sidekick", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      if (this.props.match && this.props.match.params && this.props.match.params.id) {
        this.setState({ id: this.props.match.params.id }, async () => 
          await this.loadData()
        );
      }
    }
  }

  loadData = async () => {
    let response = await expertService.getDetailExpert(this.state.id)
    if (response && response.data) {
      let data = response.data;
      if (!data.is_requesting && this.props.history.location.pathname.includes('sidekicks-request')) {
        this.props.history.push(`/sidekicks/${data.id}/edit`)
      } else if (data.is_requesting && this.props.history.location.pathname.includes('sidekicks/')) {
        this.props.history.push(`/sidekicks-request/${data.id}/edit`)
      }
      data.price = data.price ? parseFloat(data.price).toFixed(2) : 0;
      data.commission = data.commission ? parseFloat(data.commission).toFixed(2) : 0;
      // data.minimum_charge = data.minimum_charge ? parseFloat(data.minimum_charge).toFixed(2) : 0;
      data.title = data.title ? data.title : '';
      data.description = data.description ? data.description : '';
      this.setState({
        data: data,
      })
    } else {
      toast.warning(response.error ? response.error : "Sidekick not found");
      setTimeout(() => {
        this.goBack();
      }, 2000);
    }
  }

  goBack = () => {
    if (this.props.history.location.pathname.includes('sidekicks-request')) {
      this.props.history.push('/sidekicks-request')
    } else {
      this.props.history.push('/sidekicks')
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Update Sidekick | Sidekick </title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="6">
                    <h4 className="mt-2 mb-0 text-primary">Update Sidekick {this.state.data ? this.state.data.name : ''}</h4>
                  </Col>
                  <Col xl="6" className="text-right">
                    <a onClick={() => { this.goBack() }}>
                      <Button className="btn-custom-width">
                      Back
                      </Button>
                    </a>
                  </Col>
                  <Col xl="12" className="pt-2">
                    {this.state.data && typeof this.state.data.id != "undefined" ? 
                      <FormEdit
                        data={this.state.data}
                      />
                      : ''
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

EditUser.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any.isRequired
    })
  }),
  history: PropTypes.any
}

export default withRouter(EditUser)
