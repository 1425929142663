import axios from '../services/axios-service';

async function getAll(body) {
  let data = await axios.get( `/admin/user?${body ?? ''}`);
  return data
}

async function getDetailUser(body) {
  let url = `/admin/user/detail?user_id=${body}`;
  let data = await axios.get(url);
  return data
}

async function deleteUser(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post(`/admin/user/delete`, urlencoded);
  return data
}

async function updateUser(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post(`/admin/user/update`, urlencoded);
  return data
}

export default {
  getAll: getAll,
  getDetailUser: getDetailUser,
  deleteUser: deleteUser,
  updateUser: updateUser
}
