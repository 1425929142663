import React, { memo } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody } from "reactstrap"
import imageUser from "../../assets/images/image-user.jpg";
import { checkImage } from 'helpers/checkImage';
import moment from "moment";

const Detail = props => {
  const { detailReport } = props
  return (
    <>
      {detailReport && detailReport.id ?
        <Col xl="12">
          <h4 className="text-primary mb-3 text-center">
            Report Detail
          </h4>
          <Card className="border-card">
            <CardBody>
              <Row>
                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">ID:</span>
                    <p className="mb-0">
                      {detailReport.id}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Ref ID:
                    </span>
                    <p className="mb-0">
                      {detailReport.ref_id
                        ? detailReport.ref_id
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Ref Type:
                    </span>
                    <p className="mb-0">
                      {detailReport.ref_type
                        ? detailReport.ref_type
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Reason:
                    </span>
                    <p className="mb-0">
                      {detailReport.reason
                        ? detailReport.reason
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Created At:
                    </span>
                    <p className="mb-0">
                      {detailReport.created_at
                        ? moment(detailReport.created_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                        : ""}
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {detailReport.user ?
            <Card className="border-card">
              <CardBody>
                <h4 className="text-primary mb-3 text-center">
                  User
                </h4>
                <Row>
                  <Col xl="4">
                    <div className="d-block mb-3 text-center">
                      <img
                        id={`image_customer_detail_${typeof detailReport.user.id != "undefined" ? detailReport.user.id : ''}`}
                        src={
                          detailReport.user.image ?
                            checkImage(detailReport.user.image, `image_customer_detail_${typeof detailReport.user.user_id != "undefined" ? detailReport.user.user_id : ''}`, imageUser)
                            : imageUser
                        }
                        className="img-avatar"
                      />
                    </div>
                  </Col>
                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        User ID:
                      </span>
                      <p className="mb-0">
                        {detailReport.user.id
                          ? detailReport.user.id
                          : ""}
                      </p>
                    </div>

                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        User Name:
                      </span>
                      <p className="mb-0">
                        {detailReport.user.user_name
                          ? detailReport.user.user_name
                          : ""}
                      </p>
                    </div>

                    <div className="d-flex  mb-3">
                      <span className=" fw-bold me-2">
                        Name:
                      </span>
                      <p className="mb-0">
                        {detailReport.user.name
                          ? detailReport.user.name
                          : ""}
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" md="6">
                    <div className="d-flex mb-3 ">
                      <span className="  fw-bold me-2 me-2">
                        Email:
                      </span>
                      {
                        detailReport.user.email
                          ? <a className="mb-0" rel="noreferrer" target="_blank" href={"mailto:" + detailReport.user.email}>{detailReport.user.email}</a>
                          : ""
                      }
                    </div>
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Bio:
                      </span>
                      <p className="mb-0">
                        {detailReport.user.bio
                          ? detailReport.user.bio
                          : ""}
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            :
            ''}
          {detailReport.expert ?
            <Card className="border-card">
              <CardBody>
                <h4 className="text-primary mb-3 text-center">
                  Sidekick
                </h4>
                <Row>
                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        ID:
                      </span>
                      <p className="mb-0">
                        {detailReport.expert.id
                          ? detailReport.expert.id
                          : ""}
                      </p>
                    </div>

                    <div className="d-flex mb-3 ">
                      <span className="  fw-bold me-2 me-2">
                        Headline:
                      </span>
                      <p className="mb-0">
                        {detailReport.expert.title
                          ? detailReport.expert.title
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Country:
                      </span>
                      <p className="mb-0">
                        {detailReport.expert.country
                          ? detailReport.expert.country
                          : ""}
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Active:
                      </span>
                      <p className="mb-0">
                        {detailReport.expert.is_active
                          ? "Active"
                          : "Inactive"}
                      </p>
                    </div>
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Description:
                      </span>
                      <p className="mb-0">
                        {detailReport.expert.description
                          ? detailReport.expert.description
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Price:
                      </span>
                      <p className="mb-0">
                        {detailReport.expert.price
                          ? detailReport.expert.price
                          : "0"}
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" md="6" className="overflow-hidden">
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Total Rating:
                      </span>
                      <p className="mb-0">
                        {detailReport.expert.rating_total
                          ? detailReport.expert.rating_total
                          : "0"}
                      </p>
                    </div>
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Rating:
                      </span>
                      <p className="mb-0">
                        {detailReport.expert.rating
                          ? detailReport.expert.rating
                          : "0"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            : ''
          }
        </Col>
        : ''
      }
    </>
  )
}

Detail.propTypes = {
  detailReport: PropTypes.any,
}

export default memo(Detail)
