export function checkImage(imgUrl, imgId, img_undefined) {
  if (imgUrl && imgUrl != "") {
    if (imgUrl.includes("http")) {
      return imgUrl;
    } else {
      let img = new Image();
      imgUrl = process.env.REACT_APP_BASE_URL + imgUrl;
      img.onload = () => {
        let imgEle = document.getElementById(imgId);
        if (imgEle) {
          imgEle.classList.remove("d-none");
          imgEle.src = imgUrl;
        }
      };
      img.onerror = () => {
        let imgEle = document.getElementById(imgId);
        if (imgEle) {
          imgEle.src = img_undefined;
        }
      };
      img.src = imgUrl;
      return img.src
    }
  } else {
    return img_undefined;
  }
}