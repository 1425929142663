import axios from '../services/axios-service';

async function getAll(body) {
  let data = await axios.get(`/admin/appointment/getAppointments?${body ?? ''}`);
  return data
}

async function getDetailAppointment(body) {
  let url = `/admin/appointment/getDetail?appointment_id=${body ?? ''}`;
  let data = await axios.get(url);
  return data
}

async function getAppointmentLogs(body) {
  let url = `/admin/log/getAppointmentLog?${body ?? ''}`;
  let data = await axios.get(url);
  return data
}

async function getCancelAppointments(body) {
  let url = `/admin/appointment/getAppointmentsCancel?${body ?? ''}`;
  let data = await axios.get(url);
  return data
}

async function refundAppointment(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/appointment/refundAppointment", urlencoded);
  return data
}

async function retryRefundBooking(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/payment/refundBooking", urlencoded);
  return data
}

async function retryTransferBooking(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/payment/transferBooking", urlencoded);
  return data
}

async function refundBookingManual(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/payment/refundBookingManual", urlencoded);
  return data
}

async function getAppointmentsCompletedByExpert(body) {
  let url = `admin/appointment/getAppointments?${body ?? ''}`;
  let data = await axios.get(url);
  return data
}

export default {
  getAll: getAll,
  getDetailAppointment: getDetailAppointment,
  getAppointmentLogs: getAppointmentLogs,
  getCancelAppointments: getCancelAppointments,
  refundAppointment: refundAppointment,
  retryRefundBooking: retryRefundBooking,
  retryTransferBooking: retryTransferBooking,
  refundBookingManual: refundBookingManual,
  getAppointmentsCompletedByExpert: getAppointmentsCompletedByExpert
}
