import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap"
import classnames from 'classnames';
import MetaTags from "react-meta-tags"
import { checkPermission} from 'helpers/supportFunction';
import TableAppointments from 'components/appointments/tableAppointments';
import TableCancelAppointments from 'components/appointments/tableCancelAppointments';
import toast from 'helpers/toast';

class Appointment extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("appointment", "read")) {
      toast.warning("You do not have permission to manage sessions", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sessions | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody style={{minHeight: '600px'}}>
                <Row>
                  <Col xl="12" className="d-flex align-items-end pb-3">
                    <h4 className="text-primary mb-0">Manage Sessions</h4>
                  </Col>
                  <Col xl="12">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle('1'); }}
                        >
                          Sessions
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle('2'); }}
                        >
                          Canceled Sessions
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                  <TabContent activeTab={this.state.activeTab}>
                  {
                    this.state.activeTab == '1' ? 
                      <TabPane tabId="1">
                        <TableAppointments/>
                      </TabPane>
                    : ''
                  }
                  {
                    this.state.activeTab == '2' ? 
                      <TabPane tabId="2">
                        <TableCancelAppointments/>
                      </TabPane>
                    : ''
                  }
                  </TabContent>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Appointment
