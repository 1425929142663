import React, { Component } from "react"
import PropTypes from "prop-types"
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import expertAmbassadorService from "services/ambassador";
import MetaTags from "react-meta-tags"
import Detail from "components/sidekick-ambassadors/detail";
import { checkPermission } from 'helpers/supportFunction';
import toast from 'helpers/toast';

class ExpertFeaturedUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expert_ambassador_id: null,
      dataDetail: null,
    }
  }

  async componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("ambassador", "read")) {
      toast.warning("You do not have permission to manage ambassador", "Invalid Permission");
      setTimeout(() => {
        window.location = '/sidekicks-ambassador';
      }, 3000);
    } else {
      if (this.props.match && this.props.match.params && this.props.match.params.id) {
        this.setState({ expert_ambassador_id: this.props.match.params.id }, () => 
          this.loadData()
        );
      }
    }
  }

  loadData = async () => {
    let response = await expertAmbassadorService.getDetail('id=' + this.state.expert_ambassador_id);
    if (response && response.data) {
      this.setState({
        dataDetail: response.data,
      })
    } else {
      setTimeout(() => {
        window.location = '/sidekicks-ambassador';
      }, 2000);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sidekick Ambassador Detail | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="6" md="6" xs="6">
                    <h4 className="mt-2 mb-2 text-primary">Sidekick Ambassador Detail</h4>
                  </Col>
                  <Col xl="6" md="6" xs="6" className="text-right">
                    <Link to="/sidekick-ambassadors">
                      <Button className="btn-custom-width"> Back </Button>
                    </Link>
                  </Col>
                  <Col xl="12" className="pt-2">
                    {this.state.dataDetail && typeof this.state.dataDetail.id != "undefined" ? 
                      <Detail dataDetail={this.state.dataDetail}/>
                      : ''
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ExpertFeaturedUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any.isRequired
    })
  })
}

export default ExpertFeaturedUpdate
