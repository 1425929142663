import { checkImage } from 'helpers/checkImage';
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import { resizeImage, isEmpty, getVideoOptions } from 'helpers/supportFunction';
import { Row, Col, FormFeedback, Label, Button } from "reactstrap"
import categoryService from "../../services/category";
import expertFeaturedService from "../../services/expert-featured";
import expertService from "../../services/expert";
import PropTypes from "prop-types"
import React, { Component } from "react"
import Select from 'react-select'
import toast from 'helpers/toast';

const STATUS = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' }
]

const fileTypes = ["jpg", "jpeg", "png"];
const LIMIT_SIZE_IMAGE = 2;

class FormExpertFeatured extends Component {
  constructor(props) {
    super(props)
    this.goto = React.createRef();
    let { dataInput } = props;
    this.state = {
      isSubmit: false,
      dataForm: dataInput,
      listExpert: [],
      listCategory: [],
      messageError: null,
      file_image: null,
      status: null,
      waiting: false,
      type: 'expert'
    }
  }

  async componentDidMount() {
    try {
      if (this.state.dataForm.category_id) {
        this.setState({ type: 'category' })
      }
      await this.loadCategories();
      await this.loadExperts();
    } catch (error) {
      console.log(error);
    }
  }

  loadExperts = async () => {
    this.setState({ waiting: true });
    let response = await expertService.getAll(`offset=0&limit=-1&is_requesting=0&stripe_active=1&availability=1`);
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (response && response.data) {
      let listExpert = response.data.map((item) => {
        item.value = item.id;
        item.label = item.name + ' - ' + item.email;
        return item;
      })
      this.setState({ listExpert: listExpert })
    }
    this.setState({ waiting: false });
  }

  loadCategories = async () => {
    this.setState({ waiting: true });
    let response = await categoryService.getAll(`offset=0&limit=-1`);
    if (response && response.data) {
      let listCategory = response.data.map((item) => {
        item.value = item.id;
        item.label = item.name;
        return item;
      })
      this.setState({ listCategory: listCategory })
    }
    this.setState({ waiting: false });
  }

  handleInput = async (type, value) => {
    let dataForm = this.state.dataForm;
    dataForm[type] = value;
    if (type == "expert_id" && !this.state.dataForm.file_image) {
      await this.onSetImage(value);
    }
    this.setState({ dataForm: dataForm })
  }

  getSuggestMedia = (expert) => {
    let videoMedia = expert.media && expert.media.length ? expert.media.find((media) => { return media.file_type.includes("video") }) : null;
    if (videoMedia) {
      return {
        media_type: 'video',
        media: videoMedia.file_url
      }
    } else {
      let mediaImage = expert.media && expert.media.length ? expert.media.find((media) => { return media.file_type.includes("image") }) : null;
      if (mediaImage) {
        return {
          media_type: 'image',
          media: mediaImage.file_url
        }
      }
    }
    return { message_error: "The sidekick has no media. Please choose an image." }
  }

  onSetImage = async (expert_id) => {
    try {
      if (expert_id && !this.state.dataForm.file_image) {
        let expert = this.state.listExpert.find((item) => { return item.id == expert_id });
        let message_error = "";
        this.setState({ waiting: true });
        let dataForm = this.state.dataForm;
        const suggestMedia = this.getSuggestMedia(expert);
        if (suggestMedia.message_error) {
          dataForm.media = null;
          message_error = suggestMedia.message_error;
        } else {
          if (suggestMedia.media_type == 'video') {
            dataForm.media_type = 'video';
            dataForm.media = suggestMedia.media;
          } else {
            let urlMedia = suggestMedia.media;
            if (urlMedia.includes("res.cloudinary.com")) {
              if (urlMedia.indexOf("upload")) {
                urlMedia = urlMedia.replace("upload", "upload/c_fill,h_300")
              }
              urlMedia += '?auto=format&fit=crop&w=200&h=200';;
            } else {
              urlMedia = checkImage(urlMedia, "img_expert_featured");
            }
            try {
              const response = await fetch(urlMedia);
              const blob = await response.blob();
              if (!blob || response.status != 200) {
                message_error = "Sidekick profile media invalid. Please choose another image";
              } else {
                let filename = urlMedia.split("/");
                filename = filename[filename.length - 1];
                let file = await new File([blob], filename, { type: blob.type });
                if (!file) {
                  message_error = "Sidekick profile image invalid. Please choose another image";
                } else {
                  if ((file.size / 1024 / 1024) > LIMIT_SIZE_IMAGE) {
                    file = await resizeImage(file);
                  }
                  dataForm.media_type = 'image';
                  dataForm.media = suggestMedia.media;
                }
              }
            } catch (error) {
              message_error = "Sidekick profile image Invalid. Can not get image, please choose another image";
            }
          }
        }
        this.setState({ dataForm: dataForm, messageError: message_error });
        if (message_error) {
          toast.warning(message_error);
        } else {
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      }
    } catch (error) { }
    this.setState({ waiting: false })
  }

  onClickSubmit = async data => {
    let error = "";
    try {
      this.setState({ isSubmit: true, messageError: "" });
      if (this.state.type == 'expert') {
        if (!this.state.dataForm.expert_id) {
          toast.warning("Please choose a sidekick");
          return;
        }
        if (isEmpty(this.state.dataForm.media) && isEmpty(this.state.file_image)) {
          let expert = this.state.listExpert.find((item) => { return item.id == this.state.dataForm.expert_id });
          const suggestMedia = this.getSuggestMedia(expert);
          if (suggestMedia.message_error) {
            this.setState({ messageError: suggestMedia.message_error });
            toast.warning(suggestMedia.message_error);
            return;
          }
        }
      } else {
        if (!this.state.dataForm.media) {
          toast.warning("Please upload an image");
          return;
        }
      }
      const formData = new FormData();
      if (this.state.dataForm.expert_id) {
        formData.append("expert_id", this.state.dataForm.expert_id);
      } else {
        formData.append("category_id", this.state.dataForm.category_id);
      }
      if (this.state.file_image) {
        formData.append("image", this.state.file_image);
      } else if (this.state.dataForm.media && isEmpty(this.state.file_image)) {
        formData.append("media", this.state.dataForm.media);
      }
      this.setState({ waiting: true });

      let response = null;
      if (this.state.dataForm.id) {
        if (this.state.dataForm.status) {
          formData.append("expert_featured_id", this.state.dataForm.id);
          formData.append("status", this.state.dataForm.status);
          response = await expertFeaturedService.updateExpertFeatured(formData)
          if (response && response.success) {
            toast.success("Update banner featured success");
          } else {
            error = "Update banner featured failed";
          }
        }
      } else {
        response = await expertFeaturedService.storeExpertFeatured(formData)
        if (response && response.success) {
          toast.success("Create banner featured success");
        } else {
          error = "Create banner featured failed";
        }
      }
      if (response && response.error) {
        error = response.error
      } else {
        setTimeout(() => { this.goto.current.click() }, 100);
      }
    } catch (error) { }
    this.setState({ messageError: error, waiting: false })
  }

  handleChange = (file) => {
    if (file) {
      const file_type = file.type;
      const extension = file_type.substring(file_type.lastIndexOf("/") + 1).toLowerCase();
      if ((parseFloat(file.size) / 1024 / 1024) > LIMIT_SIZE_IMAGE) {
        toast.error(`Maximum size: ${LIMIT_SIZE_IMAGE}MB`);
        return false;
      }
      if (["png", "jpeg", "jpg"].includes(extension)) {
        var reader = new FileReader();
        reader.onload = (e) => {
          let dataForm = this.state.dataForm;
          dataForm.media = e.target.result;
          dataForm.media_type = 'image'
          this.setState({
            dataForm: dataForm,
            file_image: file,
            messageError: ""
          })
        };
        reader.readAsDataURL(file);
      } else {
        toast.error(`Allowed extensions are: png, jpeg, or jpg`);
      }
    }
  };

  removeFile = () => {
    let dataForm = this.state.dataForm;
    dataForm.media = null;
    this.setState({
      file_image: null,
      dataForm: dataForm,
    })
  }

  onChangType = (type) => {
    this.setState({ type: type }, () => {
      if (this.state.type == 'expert') {
        this.handleInput("category_id", null)
      } else {
        this.handleInput("expert_id", null)
      }
    })
  }

  render() {
    return (
      <>
        {this.state.dataForm ?
          <>
            <Row className={`pb-300 ${this.state.waiting ? 'waitLoadingData' : ''}`}>
              <Row>
                <Link ref={this.goto} to="/sidekicks-featured" className="d-none"></Link>
                <Col xl="4" md="12">
                  <Row>
                    <Col xl="12" md="12" style={{ textAlign: 'left' }} className="mb-3">
                      <div className="d-flex flex-wrap gap-3">
                        <span style={{ fontWeight: 500 }}>Type: </span>
                        <div className="btn-group" role="group">
                          <input
                            type="radio"
                            name="sidekick"
                            id="sidekick"
                            autoComplete="off"
                            checked={this.state.type == 'expert'}
                            onClick={() => { this.onChangType('expert') }}
                            onChange={() => { }}
                            className="c-radio cursor-pointer"
                          />
                          <label htmlFor="sidekick" className="label-input-radio cursor-pointer">
                            Sidekick
                          </label>

                          <input
                            type="radio"
                            name="category"
                            id="category"
                            autoComplete="off"
                            checked={this.state.type == 'category'}
                            onClick={() => { this.onChangType('category') }}
                            onChange={() => { }}
                            className="c-radio ml-10px cursor-pointer"
                          />
                          <label htmlFor="category" className="label-input-radio cursor-pointer">
                            Category
                          </label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {
                    this.state.type == 'expert' && <Row>
                      <Col xl="12" md="12" style={{ textAlign: 'left' }} className="mb-3">
                        <Label for="expert" style={{ "paddingLeft": "0" }}>Sidekick</Label>
                        <Select
                          classNamePrefix="select"
                          defaultValue={this.state.dataForm.expert_id ? this.state.dataForm.expert_id : null}
                          value={this.state.listExpert.find((item) => { return item.value == this.state.dataForm.expert_id })}
                          isClearable={false}
                          isSearchable={true}
                          options={this.state.listExpert ? this.state.listExpert : []}
                          onChange={e => { this.handleInput("expert_id", e.value) }}
                          placeholder="Choose sidekick"
                          className={this.state.isSubmit && !this.state.dataForm.expert_id ? 'is-invalid' : ''}
                        />
                        {
                          this.state.isSubmit && !this.state.dataForm.expert_id ?
                            <FormFeedback type="invalid" className="invalid-inline">
                              Sidekick is required
                            </FormFeedback>
                            : ''
                        }
                      </Col>
                    </Row>
                  }
                  {
                    this.state.type == 'category' && <Row>
                      <Col xl="12" md="12" style={{ textAlign: 'left' }} className="mb-3">
                        <Label for="expert" style={{ "paddingLeft": "0" }}>Category</Label>
                        <Select
                          classNamePrefix="select"
                          defaultValue={this.state.dataForm.category_id ? this.state.dataForm.category_id : null}
                          value={this.state.listCategory.find((item) => { return item.value == this.state.dataForm.category_id })}
                          isClearable={false}
                          isSearchable={true}
                          options={this.state.listCategory ? this.state.listCategory : []}
                          onChange={e => { this.handleInput("category_id", e.value) }}
                          placeholder="Choose category"
                          className={this.state.isSubmit && !this.state.dataForm.category_id ? 'is-invalid' : ''}
                        />
                        {
                          this.state.isSubmit && !this.state.dataForm.category_id ?
                            <FormFeedback type="invalid" className="invalid-inline">
                              Category is required
                            </FormFeedback>
                            : ''
                        }
                      </Col>
                    </Row>
                  }
                  <Row>
                    {this.state.dataForm.id ?
                      <Col xl="12" md="12" style={{ textAlign: 'left' }} className="mb-3" >
                        <Label for="_status" style={{ "paddingLeft": "0" }}>Status</Label>
                        <Select
                          classNamePrefix="select"
                          defaultValue={this.state.dataForm.status ? this.state.dataForm.status : null}
                          value={STATUS.find((item) => { return item.value == this.state.dataForm.status })}
                          isClearable={false}
                          isSearchable={false}
                          options={STATUS}
                          onChange={e => { this.handleInput("status", e.value); }}
                          placeholder="Choose status"
                          className={this.state.isSubmit && !this.state.dataForm.status ? 'is-invalid' : ''}
                        />
                        {
                          this.state.isSubmit && !this.state.dataForm.status ?
                            <FormFeedback type="invalid" className="invalid-inline">
                              Status is required
                            </FormFeedback>
                            : ''
                        }
                      </Col>
                      : ''}
                  </Row>
                  {this.state.messageError ?
                    <Row className="pb-2 text-danger">
                      <Col xl="12">
                        {this.state.messageError}
                      </Col>
                    </Row>
                    : ''}
                </Col>

                <Col xl="8" md="12">
                  <Row>
                    <span className="text-primary mb-2">Please upload an image file less than {LIMIT_SIZE_IMAGE}MB in size</span>
                    <Col xl="6" md="12">
                      <div
                        className={`d-block text-center upload-file`}
                      >
                        <FileUploader
                          multiple={false}
                          handleChange={this.handleChange}
                          name="file"
                          types={fileTypes}
                          maxSize={LIMIT_SIZE_IMAGE}
                          label="Upload or drop a image right here"
                          hoverTitle="Upload or drop a image here"
                          onSizeError={(file) => console.log(file)}
                        />
                      </div>
                    </Col>
                    {this.state.dataForm && this.state.dataForm.media ?
                      <Col xl="6" md="12">
                        <div className="d-center-flex">
                          {
                            this.state.dataForm.media_type == 'video' ?
                              <video
                                className="video-feature-form"
                                muted
                                playsInline
                                preload="metadata"
                                controls
                                style={{ backgroundColor: "#f2f5f9" }}
                                src={this.state.dataForm.media}
                                {...getVideoOptions(this.state.dataForm.media)}
                              ></video>
                              :
                              <img
                                id="img_expert_featured"
                                src={this.state.dataForm.media}
                                className="image-expert-featured-form"
                              />
                          }
                          <a
                            href="#"
                            className="remove-img"
                            onClick={() => { this.removeFile() }}
                          ><i className="bx bx-trash"></i> </a>
                        </div>
                      </Col>
                      : ''}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <Button
                    className="btn-custom-width; padding-bottom: 300px;"
                    disabled={this.state.waiting}
                    color="primary"
                    onClick={() => this.onClickSubmit(this.state.dataForm)}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Row>
          </>
          :
          ''}
      </>
    )
  }
}

FormExpertFeatured.propTypes = {
  dataInput: PropTypes.any
}

export default FormExpertFeatured
