import axios from '../services/axios-service';

async function getAll(body) {
  let data = await axios.get(`/admin/setting?${body ?? ''}`);
  return data;
}

async function updateSetting(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post("/admin/setting/update", urlencoded);
  return data;
}

async function getCommissionLog(body) {
  let data = await axios.get(`/admin/log/getCommissionLog?${body ?? ''}`);
  return data;
}

export default {
  getAll: getAll,
  updateSetting: updateSetting,
  getCommissionLog: getCommissionLog
}
