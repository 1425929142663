import axios from '../services/axios-service';

async function getAll(body) {
  let data = await axios.get(`/admin/expert?${body ?? ''}`);
  return data
}

async function approveExpert(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/expert/approve", urlencoded);
  return data
}

async function unapproveExpert(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/expert/unApprove", urlencoded);
  return data
}

async function unFeaturedExpert(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/expert/unFeatured", urlencoded);
  return data
}
async function featuredExpert(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/expert/featured", urlencoded);
  return data
}
async function getDetailExpert(body) {
  let url = `/admin/expert/detail?expert_id=${body ?? ''}`;
  let data = await axios.get(url);
  return data
}
async function updateCommission(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/expert/updateCommission", urlencoded);
  return data
}

async function addExpertCategory(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post("/admin/expert/addCategory", urlencoded);
  return data;
}

async function removeExpertCategory(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post("/admin/expert/removeCategory", urlencoded);
  return data;
}

async function getExpertCategories(body) {
  let url = `/admin/expert/categories?${body ?? ''}`;
  let data = await axios.get(url);
  return data
}

async function getExpertSkills(body) {
  let url = `/admin/expert/skills?${body ?? ''}`;
  let data = await axios.get(url);
  return data
}

async function addExpertSkill(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post("/admin/expert/addSkill", urlencoded);
  return data;
}

async function removeExpertSkill(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post("/admin/expert/removeSkill", urlencoded);
  return data;
}

async function updateExpert(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post("/admin/expert/update", urlencoded);
  return data;
}

export default {
  getAll: getAll,
  approveExpert: approveExpert,
  unapproveExpert: unapproveExpert,
  getDetailExpert: getDetailExpert,
  unFeaturedExpert: unFeaturedExpert,
  featuredExpert: featuredExpert,
  updateCommission: updateCommission,
  addExpertCategory: addExpertCategory,
  removeExpertCategory: removeExpertCategory,
  getExpertCategories: getExpertCategories,
  getExpertSkills: getExpertSkills,
  addExpertSkill: addExpertSkill,
  removeExpertSkill: removeExpertSkill,
  updateExpert: updateExpert
}
