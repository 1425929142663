import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Label,
  Modal,
  ModalHeader,
  FormFeedback,
  Card,
  CardBody
} from "reactstrap"
import { Link } from "react-router-dom";
import categoryService from "../../services/category"
import MetaTags from "react-meta-tags"
import { checkPermission, customUrlParams } from 'helpers/supportFunction';
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

class Category extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      isAdding: false,
      isEditCreate: false,
      isDelete: false,
      isForeDelete: false,
      currentCategory: null,
      isSubmit: false,
      search: '',
      dxStore: this.createDxStore()
    }
  }
  onclose = () => {
    this.setState({
      isAdding: false,
      currentCategory: null,
      isEditCreate: false,
      isSubmit: false
    })
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("category", "read")) {
      toast.warning("You do not have permission to manage categories", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.loadData()
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = ""
        if (this.state.search) {
          url_params += `&search=${this.state.search}`;
        }
        let params = customUrlParams(loadOptions, url_params, 'id', 'desc')
        var response = await categoryService.getAll(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  handleInput = (type, value) => {
    let currentCategory = { ...this.state.currentCategory }
    currentCategory[[type]] = value
    this.setState({ currentCategory: currentCategory })
  }

  onClickCreate = async () => {
    this.setState({
      isSubmit: true
    });
    if (this.state.currentCategory.name) {
      let response = await categoryService.createCategory(this.state.currentCategory)
      if (response.success) {
        toast.success("Create category success");
        this.onclose();
        this.loadData();
      }
    }
  }

  onCloseDelete = () => {
    this.setState({
      title: "",
      isEditCreate: false,
      isAdding: false,
      isDelete: false,
      isForeDelete: false,
      currentCategory: null,
    })
  }

  onDeleteCategory = async () => {
    if (this.state.currentCategory && this.state.currentCategory.id && this.state.isDelete) {
      let response = null;
      if (!this.state.isForeDelete) {
        response = await categoryService.deleteCategory(this.state.currentCategory.id)
      } else {
        response = await categoryService.forceDeleteCategory(this.state.currentCategory.id)
      }
      if (response && response.success) {
        toast.success("Delete category success");
        this.onCloseDelete();
        this.loadData();
      }
    }
  }

  linkUpdate = category => {
    if (category && category.id) {
      this.setState({
        title: "Update Category",
        isEditCreate: true,
        isAdding: true,
        currentCategory: {
          id: category.id,
          name: category.name,
          description: category.description ?? '',
        },
      })
    }
  }

  customId = (e) => {
    let category = e.data;
    return (
      <Link to="#" onClick={async () => {
          this.setState({
            isAdding: true,
            title: "Detail Category",
            isEditCreate: false,
            currentCategory: category,
          })
        }}
      >
        {category.id}
      </Link>
    )
  }

  linkEdit = (e) => {
    let category = e.data;
    return (
      <>
        { checkPermission("category", "update") ?
          <Button 
            className="btn mx-1 btn-custom-width"
            color="primary"
            onClick={() => this.linkUpdate(category)}
          >
            Update
          </Button>
          : ''
        }
        { checkPermission("category", "delete") ?
          <>
            <Button
              className="btn-danger mx-1 btn-custom-width"
              onClick={async () => {
                this.setState({
                  title: "Delete Category",
                  isDelete: true,
                  currentCategory: category,
                })
              }}
            >
              Delete
            </Button>
          </>
          : 
        ''}
      </>
    )
  }

  onClickUpdate = async () => {
    this.setState({
      isSubmit: true
    });
    if (this.state.currentCategory.name) {
      let response = await categoryService.updateCategory({
        category_id: this.state.currentCategory.id,
        name: this.state.currentCategory.name,
        description: this.state.currentCategory.description ?? null,
      })
      if (response.success) {
        toast.success("Update category success");
        this.onclose();
        this.loadData();
      }
    }
  }

  handleInputSearch = e => {
    this.setState({ search: e }, () => 
      this.loadData()
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Categories | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12" className="d-flex align-items-center pb-2">
                    <h4 className="mb-0 text-primary">Manage Categories</h4>
                  </Col>
                  <Col xl="4" className="pt-2 pb-2">
                    <Input
                      type="search"
                      id="search"
                      className="form-control"
                      placeholder="Search..."
                      value={this.state.search}
                      onChange={e => {
                        this.handleInputSearch(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xl="8" className="text-right pt-2 pb-2">
                    { checkPermission("category", "create") ?
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setState({
                            title: "Create Category",
                            isEditCreate: true,
                            isAdding: true,
                            currentCategory: {
                              name: "",
                              description: ""
                            },
                          })
                        }}
                      >
                        Create Category
                      </Button>
                    :'' }
                  </Col>
                </Row>
                <Row className="pt-1">
                  <Col xl="12">
                    <DataGrid
                      dataSource={this.state.dxStore}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                    >
                      <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80} cellRender={this.customId}></Column>
                      <Column dataField="name" name="name" caption="Name"></Column>
                      <Column dataField="description" name="description" caption="Description"></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'} width={235}></Column>

                      <Paging defaultPageSize={10} />
                      <Pager
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        allowedPageSizes={[10, 25, 50, 100]}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Modal
              isOpen={this.state.isAdding}
              role="dialog"
              autoFocus={true}
              centered
              data-toggle="modal"
              backdrop={this.state.isEditCreate ? 'static' : true}
              toggle={() => {
                this.onclose()
              }}
            >
              <div>
                <ModalHeader
                  className="border-bottom-0"
                  toggle={() => {
                    this.onclose()
                  }}
                ></ModalHeader>
              </div>
              <div className="modal-body pt-0">
                <div className="text-center mb-4">
                  <Row className=" justify-content-center">
                    {this.state.currentCategory ? 
                      <Col xl="11">
                        <h4 className="text-primary mb-3">{this.state.title}</h4>
                        {this.state.isEditCreate ? (
                          <>
                            <Row>
                              <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                                <Label for="name" style={{"paddingLeft": "0"}}>Name</Label>
                                <div className="input-group rounded bg-light">
                                  <Input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="form-control bg-transparent border-0"
                                    placeholder="Category name"
                                    value={this.state.currentCategory.name}
                                    onChange={e => {
                                      this.handleInput("name", e.target.value)
                                    }}
                                    invalid={
                                      this.state.isSubmit &&
                                      !this.state.currentCategory.name
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                                {
                                  this.state.isSubmit &&
                                  !this.state.currentCategory.name ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      Name is required
                                    </FormFeedback>
                                  : ''
                                }
                              </Col>
                              <Col xl="12" style={{textAlign: 'left'}}>
                                <Label for="description" style={{"paddingLeft": "0"}}>Description</Label>
                                <div className="input-group rounded bg-light mb-3">
                                  <Input
                                    type="textarea"
                                    id="description"
                                    name="description"
                                    className="form-control bg-transparent border-0"
                                    placeholder="Category Description"
                                    value={this.state.currentCategory.description}
                                    onChange={e => {
                                      this.handleInput("description", e.target.value)
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <>
                            <div className="d-flex">
                              <span className="me-2">Category:</span>
                              <p className="fw-bold"> {this.state.currentCategory.name}</p>
                            </div>
                            <div className="d-flex pt-3">
                              <span className="me-2">Description:</span>
                              <p className="fw-bold"> {this.state.currentCategory.description}</p>
                            </div>
                          </>
                        )}
                        {this.state.isEditCreate ? (
                          <>
                          {this.state.currentCategory && !this.state.currentCategory.id? (
                          <Button
                            className="mt-3 d-block mx-auto"
                            color="primary"
                            type="button"
                            id="button-create"
                            onClick={() => {
                              this.onClickCreate()
                            }}
                          >
                            Create
                          </Button>
                          ) : (
                            <Button
                              className="mt-3 d-block mx-auto"
                                color="primary"
                                type="button"
                                id="button-create"
                                onClick={() => {
                                  this.onClickUpdate(this.state.currentCategory)
                                }}
                              >
                                Update
                              </Button>
                          )}
                        </>) : (
                          <></>
                        )}
                      </Col>
                    : ''
                    }
                  </Row>
                </div>
              </div>
            </Modal>
            <Modal
              role="dialog"
              isOpen={this.state.isDelete}
              autoFocus={true}
              centered
              data-toggle="modal"
              backdrop={'static'}
              toggle={() => this.onCloseDelete()}
            >
              <div>
                <ModalHeader
                  className="border-bottom-0"
                  toggle={() => this.onCloseDelete()}
                ></ModalHeader>
              </div>
              <div className="modal-body" style={{"paddingTop": "0"}}>
                <div className="mb-4">
                  <Row className="justify-content-center">
                    <Col xl="11">
                      <h4 className="text-primary text-center mb-4">Confirmation Delete</h4>
                      <Row className="text-center">
                        <Label style={{"paddingLeft": "0"}}> { this.state.isForeDelete ? <> <span>This category will be remove from all Sidekicks.</span> <br></br></>
                          : '' } Are you sure delete category {this.state.currentCategory ? '"' + this.state.currentCategory.name + '"' : ''}?</Label>
                      </Row>
                      <Row className="text-left">
                        <Label for="forceDelete" className="mb-0">
                          <Input
                            id="forceDelete"
                            name="forceDelete"
                            type="checkbox"
                            defaultChecked={this.state.isForeDelete}
                            onClick={() => {
                              this.setState({
                                isForeDelete: !this.state.isForeDelete
                              });
                            }}
                            style={{marginRight: '10px'}}
                          />
                          Force Delete
                        </Label>
                      </Row>
                      <Row className="text-center">
                        <Button
                          className="mt-3 d-block mx-auto"
                          color="danger"
                          type="button"
                          onClick={() => {
                            this.onDeleteCategory()
                          }}
                        >
                          Delete
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Category
