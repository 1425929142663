import React, { Component } from "react"
import {
  Row,
  Col,
  Button,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap"
import Select from 'react-select'
import { Link } from "react-router-dom";
import reportService from "../../services/report";
import expertService from "../../services/expert";
import userService from "../../services/user";
import { checkPermission, customUrlParams, formatAmountUs } from 'helpers/supportFunction';
import moment from "moment";
import toast from 'helpers/toast';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

class tableReports extends Component {
  constructor(props) {
    super(props)
    this.selectCustomer = React.createRef();
    this.selectSidekick = React.createRef();
    this.state = {
      detailReport: null,
      filterData: {
        start_time: '',
        end_time: '',
        expert_id: '',
        customer_id: ''
      },
      dxStore: this.createDxStore(),
      userOptions: [],
      expertOptions: [],
      waiting: false,
      messageError: null
    }
  }

  componentDidMount = async () => {
    if (localStorage.getItem('userSidekick') && !checkPermission("report", "read")) {
      toast.warning("You do not have permission to manage reports", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.setState({waiting: true});
      this.loadData()
      var response = await expertService.getAll('template_account=0&status=accepted&limit=-1&offset=0');
      let experts = [];
      if(response.success) {
        experts = response.data.map(item => { item.value = item.id; item.label = item.name; return item  });
      }
      response = await userService.getAll('limit=-1&offset=0');
      let users = [];
      if(response.success) {
        users = response.data.map(item => { item.value = item.id; item.label = item.name; return item  });
      }
      this.setState({userOptions: users, expertOptions: experts, waiting: false});
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'appointment_id',
      load: async (loadOptions) => {
        let url_params = ""
        if (this.state.filterData) {
          if (this.state.filterData.start_time && this.state.filterData.end_time) {
            url_params += `&start_time=${moment(this.state.filterData.start_time).format("YYYY-MM-DD HH:mm")}&end_time=${moment(this.state.filterData.end_time).format("YYYY-MM-DD HH:mm")}`;
          }
          if (this.state.filterData.expert_id) {
            url_params += `&expert_id=${this.state.filterData.expert_id}`;
          }
          if (this.state.filterData.customer_id) {
            url_params += `&customer_id=${this.state.filterData.customer_id}`;
          }
        }
        let params = customUrlParams(loadOptions, url_params, 'appointment_id', 'desc')
        var response = await reportService.getSessionReportAll(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  handleInput = (type, value) => {
    let filterData = { ...this.state.filterData }
    filterData[[type]] = value
    this.setState({ filterData: filterData }, () => {
      this.onCheckValidTime();
      if (['expert_id', 'customer_id'].includes(type) || (this.state.filterData.start_time && this.state.filterData.end_time && this.state.filterData.start_time < this.state.filterData.end_time)) {
        this.loadData();
      }
    });
  }

  onCheckValidTime = () => {
    this.setState({messageError: ''})
    if (this.state.filterData.start_time && this.state.filterData.end_time) {
      if (this.state.filterData.start_time > this.state.filterData.end_time) {
        toast.warning("Please enter start time less than or equal to end time");
        this.setState({messageError: "Please enter start time less than or equal to end time" });
        return false;
      }
    }
    return true;
  }
  onFilter = () => {
    if (this.onCheckValidTime()) {
      this.loadData()
    }
  }

  onReset = () => {
    this.setState({
      filterData: {
        start_time: '',
        end_time: '',
        expert_id: null,
        customer_id: null
      }
    }, () => {
      this.selectSidekick.current.clearValue();
      this.selectCustomer.current.clearValue();
      this.loadData();
    });
  }

  customIdRow = (e) => {
    return <span> {e.rowIndex + 1} </span>
  }

  formatStartedAt = (e) => {
    let report = e.data
    return (
      <span>
        { moment(report.started_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)}
      </span>
    )
  }

  formatFinishedAt = (e) => {
    let report = e.data
    return (
      <span>
        { moment(report.finished_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)}
      </span>
    )
  }

  formatPrice = (e) => {
    let report = e.data
    return (
      <div className="text-right">
        { report ? formatAmountUs(report.price) : ''}
      </div>
    )
  }

  formatAmount = (e) => {
    let report = e.data
    return (
      <div className="text-right">
        { report ? formatAmountUs(report.amount) : ''}
      </div>
    )
  }

  customExpert = (e) => {
    let report = e.data
    return (
      checkPermission("expert", "read") && report.expert_id ? 
        <>
          <Link to={`/sidekicks/${report.expert_id}/detail`}>
            {report.expert_name}
          </Link>
        </> :
        <span> {report?.expert_name} </span>
    )
  }

  customCustomer = (e) => {
    let report = e.data
    if (report && report.customer_id) {
      return (
        <Link to={`/users/${report.customer_id}/detail`}>
          {report.customer_name}
        </Link>
      )
    } else {
      return (
        <span> {report.customer_name} </span>
      )
    }
  }

  customCustomer = (e) => {
    let report = e.data
    return (
      checkPermission("user", "read") && report.customer_id ? 
        <>
          <Link to={`/users/${report.customer_id}/detail`}>
            {report.customer_name}
          </Link>
        </> :
        <span> {report?.customer_name} </span>
    )
  }

  customAppointmentId = (e) => {
    let report = e.data
    return (
      <>{ report && report.appointment_id ?
        <Link to={`/sessions/${report.appointment_id}/detail`}
        >
          {report.appointment_id}
        </Link>
        : ''
      }
      </>
    )
  }

  onExportFile = async (params) => {
    let response = await reportService.exportSessionReport(params);
    if (response && response.success) {
      window.open(process.env.REACT_APP_BASE_URL +'/'+ response.data);
    }
  }

  render() {
    return (
      <div className={`${this.state.waiting ? 'waitLoadingData' : ''}`}>
        <Row>
          <Col xl="2" md="6" className="pt-2 pb-2">
            <label className="mb-1 fw-bold me-2">Start Time:</label>
            <div className="d-flex">
              <DatePicker
                selected={this.state.filterData.start_time}
                onChange={e => {
                  this.handleInput("start_time", e)
                }}
                className="form-control"
                showTimeSelect
                dropdownMode="select"
                timeIntervals={10}
                timeFormat="HH:mm"
                dateFormat="MM/dd/yyyy HH:mm"
                placeholderText={'Start time'} 
              />
            </div>
          </Col>
          <Col xl="2" md="6" className="pt-2 pb-2">
            <label className="mb-1 fw-bold me-2">End Time:</label>
            <div className="d-flex">
              <DatePicker
                selected={this.state.filterData.end_time}
                onChange={e => {
                  this.handleInput("end_time", e)
                }}
                className="form-control"
                showTimeSelect
                dropdownMode="select"
                timeIntervals={10}
                timeFormat="HH:mm"
                dateFormat="MM/dd/yyyy HH:mm"
                placeholderText={'End time'} 
              />
            </div>
          </Col>
          <Col xl="2" md="6" className="pt-2 pb-2">
            <label className="mb-1 fw-bold me-2">Sidekick:</label>
            <Select
              name="sidekick"
              ref={this.selectSidekick}
              classNamePrefix="select"
              defaultValue={this.state.filterData.expert_id ? this.state.filterData.expert_id : null}
              isClearable={true}
              isSearchable={true}
              options={this.state.expertOptions}  
              onChange={e => {this.handleInput("expert_id", e?.value)}}
              placeholder="Select Sidekick"
            />
          </Col>
          <Col xl="2" md="6" className="pt-2 pb-2">
            <label className="mb-1 fw-bold me-2">Customer:</label>
            <Select
              name="user"
              ref={this.selectCustomer}
              classNamePrefix="select"
              defaultValue={this.state.filterData.customer_id ? this.state.filterData.customer_id : null}
              isClearable={true}
              isSearchable={true}
              options={this.state.userOptions}  
              onChange={e => {this.handleInput("customer_id", e?.value)}}
              placeholder="Select Customer"
            />
          </Col>
          
          <Col xl="2" md="6" xs="6" className="d-flex align-items-end pt-2 pb-2">
            <Button
              color="primary"
              onClick={() => {
                this.onFilter()
              }}
              className="btn-custom-width"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                this.onReset()
              }}
              className="ml-10px btn-custom-width"
            >
              Reset
            </Button>
          </Col>
          <Col xl="2" md="6" xs="6" className="d-flex justify-content-end align-items-end pt-2 pb-2">
            <UncontrolledDropdown>
              <DropdownToggle className="btn-custom-width d-flex justify-content-center align-items-center"
                caret color="primary"><i style={{paddingRight: '5px'}} className="bx bxs-download"></i> Export
              </DropdownToggle>
              <DropdownMenu style={{minWidth: '100px', inset: 'unset', right: '0', textAlign: 'center', padding: 0}}>
                <Button
                  onClick={() => {
                    this.onExportFile('excel')
                  }}
                  color="success"
                  className="btn-custom-width">
                  Excel
                </Button>
                <br />
                <Button
                  onClick={() => {
                    this.onExportFile('pdf')
                  }}
                  color="warning"
                  className="btn-custom-width"
                >
                  PDF
                </Button>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        { this.state.messageError ? 
          <Row className="pb-2 text-danger">
            <Col xl="12">
              {this.state.messageError}
            </Col>
          </Row>
        : ''}
        <Row className="pt-1">
          <Col xl="12">
            <DataGrid
              dataSource={this.state.dxStore}
              showBorders={true}
              remoteOperations={true}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
            >
              <Column dataField="id_row" name="id_row" caption="#" alignment={'center'} allowSorting={false} width={80} cellRender={this.customIdRow}></Column>
              <Column dataField="appointment_id" name="appointment_id" caption="Session ID" alignment={'left'} cellRender={this.customAppointmentId}></Column>
              <Column dataField="call_by" name="call_by" caption="Call By"></Column>
              <Column dataField="finish_by" name="finish_by" caption="Finish By"></Column>
              <Column dataField="started_at" name="started_at" caption="Started At" cellRender={this.formatStartedAt}></Column>
              <Column dataField="finished_at" name="finished_at" caption="Finished At" cellRender={this.formatFinishedAt}></Column>
              <Column dataField="duration" name="duration" caption="Duration" allowSorting={false} alignment={'left'}></Column>
              <Column dataField="amount" name="amount" caption="Price" cellRender={this.formatAmount}></Column>
              <Column dataField="currency" name="currency" caption="Currency" alignment={'center'}></Column>
              <Column dataField="expert_name" name="expert_name" caption="Sidekick" cellRender={this.customExpert}></Column>
              <Column dataField="expert_email" name="expert_email" caption="Sidekick Email"></Column>
              <Column dataField="customer_name" name="customer_name" caption="Customer" cellRender={this.customCustomer}></Column>
              <Column dataField="customer_email" name="customer_email" caption="Customer Email"></Column>

              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
                allowedPageSizes={[10, 25, 50, 100]}
              />
            </DataGrid>
          </Col>
        </Row>
      </div>
    )
  }
}

export default tableReports
