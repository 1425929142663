import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Button,
  Label,
  FormFeedback,
  Modal,
  ModalHeader,
} from "reactstrap"
import Select from 'react-select'
import expertService from "../../services/expert"
import categoryService from "../../services/category"
import { checkPermission, customUrlParams } from 'helpers/supportFunction';
import toast from 'helpers/toast';
import ModalConfirm from "components/modals/modalConfirm"
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

class TableExpertCategories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expertCategories: [],
      categoriesList: [],
      categoriesOpt: [],
      currentCategory: {
        category_id: null,
        order: 1
      },
      isDelete: false,
      isNew: false,
      isSubmit: false,
      dxStore: this.createDxStore()
    }
  }

  componentDidMount = async () => {
    if (localStorage.getItem('userSidekick') && !checkPermission("category", "read") && this.props.expert_id) {
      toast.warning("You do not have permission to manage categories", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      await this.loadData();
      await this.loadDataCategories()
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = `&expert_id=${this.props.expert_id}`;
        loadOptions.take = '-1';
        let params = customUrlParams(loadOptions, url_params, 'category_id', 'desc')
        var response = await expertService.getExpertCategories(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          this.setState({ expertCategories: response.data});
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  loadDataCategories = async () => {
    var response = await categoryService.getAll(`&sortField=id&sortType=desc&limit=-1&offset=0`);
    if (response && response.success) {
      this.setState({
        categoriesList: response.data.map((item) => { item.value = item.id; item.label = item.name; return item }),
      })
    }
  }

  onClose = () => {
    this.setState({
      currentCategory: null,
      isDelete: false,
      isNew: false,
      isSubmit: false
    })
  }

  onActionRemove = async () => {
    var response = await expertService.removeExpertCategory({ expert_id: this.props.expert_id, expert_category_id: this.state.currentCategory.id});
    if (response && response.success) {
      toast.success("Remove category success");
      this.onClose();
      await this.loadData();
    }
  }

  onActionAdd = async () => {
    this.setState({ isSubmit: true });
    if (this.state.currentCategory.category_id && this.props.expert_id) {
      var response = await expertService.addExpertCategory({ expert_id: this.props.expert_id, category_id: this.state.currentCategory.category_id, order: 1});
      if (response && response.success) {
        toast.success("Add category success");
        this.onClose();
        await this.loadData();
      }
    }
  }

  handleInput = (type, value) => {
    let currentCategory = { ...this.state.currentCategory }
    currentCategory[[type]] = value
    this.setState({ currentCategory: currentCategory })
  }

  linkEdit = (e) => {
    let category = e.data;
    return (
      <>
        { checkPermission("category", "delete") ?
          <>
            <Button
              className="btn-danger mx-1"
              onClick={async () => {
                this.setState({
                  title: "Remove Category",
                  isDelete: true,
                  currentCategory: category,
                })
              }}
            >
              Remove
            </Button>
          </>
          : 
        ''}
      </>
    )
  }

  onOpenModelAddNew = () => {
    let categoriesOpt = this.state.categoriesList.filter(category => {
      return !this.state.expertCategories.find((item) => {return item.category_id == category.id})
    })
    this.setState({ categoriesOpt: categoriesOpt }, () =>
      this.setState({
        isNew: true,
        isSubmit: false,
        currentCategory: {
          category_id: null
        },
      })
    )
  } 

  render() {
    return (
      <>
        <Row>
          <Col xl="12" className="text-right">
            { checkPermission("category", "create") ?
              <Button
                color="primary"
                onClick={ () => this.onOpenModelAddNew()}
              >
                Add Category
              </Button>
            :'' }
          </Col>
        </Row>
        <Row className="pt-2">
          <Col xl="12">
            <DataGrid
              dataSource={this.state.dxStore}
              showBorders={true}
              remoteOperations={true}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
              className="max-h-350"
            >
              <Column dataField="category_id" name="category_id" caption="ID" alignment={'center'} width={80}></Column>
              <Column dataField="name" name="name" caption="Name"></Column>
              <Column dataField="description" name="description" caption="Description"></Column>
              <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'} width={95}></Column>
            </DataGrid>
          </Col>
        </Row>
        <ModalConfirm
          isOpen={this.state.isDelete}
          title={'Confirmation Remove'}
          message={'Are you sure remove category "' + (this.state.currentCategory && this.state.currentCategory.name ? this.state.currentCategory.name : '') + '" for this sidekick?'}
          textButton={'Remove'}
          colorButton={'danger'}
          onCloseModal={() => {
            this.onClose();
          }}
          onActionConfirm={ async () => {
            await this.onActionRemove()
          }}
        />

        <Modal
          isOpen={this.state.isNew}
          role="dialog"
          autoFocus={true}
          centered
          data-toggle="modal"
          backdrop={'static'}
          toggle={() => {
            this.onClose()
          }}
        >
          <div>
            <ModalHeader className="border-bottom-0" toggle={() => { this.onClose() }}></ModalHeader>
          </div>
          <div className="modal-body">
            <div className=" mb-4">
              <div className="row justify-content-center">
                {this.state.currentCategory ? 
                  <Col xl="11">
                    <h4 className="text-primary mb-3 text-center">
                      Add New Category For Sidekick
                    </h4>
                    <Row>
                      <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                        <Label for="category" style={{"paddingLeft": "0"}}>Category</Label>
                        <Select
                          name="category"
                          classNamePrefix="select"
                          value={this.state.currentCategory && this.state.currentCategory.category_id ? 
                            this.state.categoriesOpt.find((item) => {return item.value == this.state.currentCategory.category_id}): null }
                          isClearable={false}
                          isSearchable={true}
                          options={this.state.categoriesOpt}  
                          onChange={e => {this.handleInput("category_id", e.value)}}
                          placeholder="Select Category"
                          className={ this.state.isSubmit && !this.state.currentCategory.category_id ? 'is-invalid' : ''}
                        />
                          {
                            this.state.isSubmit && !this.state.currentCategory.category_id ? 
                              <FormFeedback type="invalid" className="invalid-inline">
                                Category is required
                              </FormFeedback>
                            : ''
                          }
                      </Col>
                    </Row>
                    <Button
                      className="mt-3 d-block mx-auto btn-custom-width"
                      color="primary"
                      type="button"
                      onClick={() => {
                        this.onActionAdd()
                      }}
                    >
                      Add
                    </Button>
                  </Col>
                  : ''
                }
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

TableExpertCategories.propTypes = {
  expert_id: PropTypes.any.isRequired
}

export default TableExpertCategories
