import React, { Component } from "react"
import { Container, Row, Col, Button, Input, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom";
import userService from "../../services/user"
import MetaTags from "react-meta-tags"
import { checkPermission, customUrlParams } from 'helpers/supportFunction';
import ModalConfirm from "components/modals/modalConfirm";
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import moment from "moment";

class User extends Component {
  constructor(props) {
    super(props)
    this.textInput = React.createRef()
    this.state = {
      users: [],
      isDelete: false,
      currentUser: null,
      search: '',
      dxStore: this.createDxStore()
    }
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("user", "read")) {
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.loadData()
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = ""
        if (this.state.search) {
          url_params += `&search=${this.state.search}`;
        }
        let params = customUrlParams(loadOptions, url_params, 'name', 'asc')
        var response = await userService.getAll(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  linkEdit = (e) => {
    let user = e.data;
    return (
      <>
        <Link to={`/users/${user.user_id}/detail`}>
          <Button className="mb-2 mx-1 btn-custom-width">
            View Detail
          </Button>
        </Link>
        
        {
          user.link_to_profile ?
            <a href={user.link_to_profile} target="`_blank`">
              <Button className="mb-2 mx-1 btn-custom-width btn-success">
                Profile
              </Button>
            </a>
          : ''
        }
        { checkPermission("user", "update") || checkPermission("user", "delete") ? 
          <br></br>
          : ''
        }
        {
          checkPermission("user", "update") ? 
            <Link to={`/users/${user.user_id}/edit`}>
              <Button className="mx-1 btn-custom-width" color="primary">
                Update
              </Button>
            </Link>
          : ''
        }
        {
          checkPermission("user", "delete") ? 
            <Button
              className="mx-1 btn-custom-width"
              color="danger"
              onClick={async () => {
                this.setState({
                  isDelete: true,
                  currentUser: user,
                })
              }}
            >
              Delete
            </Button>
          : ''
        }
      </>
    )
  }

  customId  = (e) => {
    let user = e.data;
    return (
      <Link to={`/users/${user.user_id}/detail`}
      >
        {user.user_id}
      </Link>
    )
  }

  customUser = (e) => {
    let expert = e.data;
    return (
      <Link to={`/users/${expert.user_id}/detail`}
      >
        {expert.user_name}
      </Link>
    )
  }

  customName = (e) => {
    return (
      <Link to={`/users/${e.data.user_id}/detail`}>
        {e.data.name}
      </Link>
    )
  }

  customLastTimeConnected = (e) => {
    let data = e.data
    return (
      <span>
        { data.last_connected_at ? moment(data.last_connected_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''}
      </span>
    )
  }

  onCloseDelete = () => {
    this.setState({
      isDelete: false,
      currentUser: null,
    })
  }

  onDeleteUser = async () => {
    if (this.state.currentUser && this.state.currentUser.user_id) {
      let response = await userService.deleteUser({id: this.state.currentUser.user_id});
      if (response.success) {
        toast.success("Delete user success");
        this.onCloseDelete();
        this.loadData();
      }
    }
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    setTimeout(() => {
      this.setState(() => ({
        page,
        sizePerPage,
      }))
      this.loadData(page, sizePerPage, sortField, sortOrder)
    }, 500)
  }

  handleInputSearch = e => {
    this.setState({ search: e }, () => 
      this.loadData(null, null)
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Users | Sidekick </title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12" className="d-flex align-items-center pb-2">
                    <h4 className="mb-0 text-primary">Manage Users</h4>
                  </Col>
                  <Col xl="4" className="pt-2 pb-2">
                    <Input
                      type="search"
                      id="search"
                      className="form-control"
                      placeholder="Search..."
                      value={this.state.search}
                      onChange={e => {
                        this.handleInputSearch(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
                <Row className="pt-1">
                  <Col xl="12">
                    <DataGrid
                      dataSource={this.state.dxStore}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                    >
                      <Column dataField="user_id" name="user_id" caption="ID" alignment={'center'} width={80} cellRender={this.customId}></Column>
                      <Column dataField="name" name="name" caption="Full Name" cellRender={this.customName}></Column>
                      <Column dataField="user_name" name="user_name" caption="User Name" cellRender={this.customUser}></Column>
                      <Column dataField="email" name="email" caption="Email"></Column>
                      <Column dataField="total_booking" name="total_booking" caption="Session" allowSorting={false} alignment={'center'}></Column>
                      <Column dataField="last_connected_at" name="last_connected_at" caption="Last Time Connected" cellRender={this.customLastTimeConnected} allowSorting={false}></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'left'} width={235}></Column>
                      <Paging defaultPageSize={10} />
                      <Pager
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        allowedPageSizes={[10, 25, 50, 100]}
                      />
                    </DataGrid>
                    <ModalConfirm
                      isOpen={this.state.isDelete}
                      title={'Confirmation Delete'}
                      message={
                        'Are you sure delete user ' + 
                        (this.state.currentUser ? (this.state.currentUser.user_name ? this.state.currentUser.user_name : this.state.currentUser.name) : '') 
                        + '?'
                      }
                      textButton={"Delete"}
                      colorButton={"danger"}
                      onCloseModal={() => {
                        this.onCloseDelete();
                      }}
                      onActionConfirm={ async () => {
                        await this.onDeleteUser()
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default User
