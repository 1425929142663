import React, { memo } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { Link } from "react-router-dom";
import imageUser from "../../assets/images/image-user.jpg";
import { checkImage, checkPermission, capitalizeFirstLetter, formatAmountUs, formatter } from 'helpers/supportFunction';
import moment from "moment"

const Detail = props => {
  const { data } = props;
  return (
    <>
      {data && typeof data.id != "undefined" ?
        <>
          <Card className="border-card">
            <CardBody>
              <Row>
                <Col xl="4">
                  <div className=" d-block text-center">
                    <img
                      id={`image_user_detail_${data && typeof data.id != "undefined" ? data.id : ''}`}
                      src={
                        data && data.image ?
                          checkImage(data.image, `image_user_detail_${data && typeof data.id != "undefined" ? data.id : ''}`, imageUser)
                          : imageUser
                      }
                      className="img-avatar"
                    />
                    <br />
                    <br />
                    {
                      data && data.link_to_profile ?
                        <a href={data.link_to_profile} target="`_blank`" className="btn btn-success btn-custom-width">Profile</a>
                        : ''
                    }
                    {
                      checkPermission('user', 'update') ?
                        <Link to={`/users/${data.id}/edit`} className="mb-0">
                          <Button
                            className="btn-custom-width mx-2"
                            color="primary"
                          >
                            Update
                          </Button>
                        </Link>
                        : ''
                    }
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <label className=" fw-bold me-2">
                      ID:
                    </label>
                    <p className="mb-0">
                      {data && data.id
                        ? data.id
                        : ""}
                    </p>
                  </div>
                  <div className="d-flex mb-3">
                    <label className=" fw-bold me-2">
                      Name:
                    </label>
                    <p className="mb-0">
                      {data && data.name
                        ? data.name
                        : ""}
                    </p>
                  </div>
                  <div className="d-flex mb-3">
                    <label className=" fw-bold me-2">
                      User Name:
                    </label>
                    <p className="mb-0">
                      {data && data.user_name
                        ? data.user_name
                        : ""}
                    </p>
                  </div>
                  <div className="d-flex mb-3">
                    <label className=" fw-bold me-2">
                      Created At:
                    </label>
                    <p className="mb-0">
                      {data && data.created_at
                        ? moment(data.created_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                        : ""}
                    </p>
                  </div>
                  <div className="d-flex mb-3">
                    <label className=" fw-bold me-2">
                      Bio:
                    </label>
                    <p className="mb-0">
                      {data && data.bio
                        ? data.bio
                        : ""}
                    </p>
                  </div>
                </Col>
                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <label className=" fw-bold me-2">
                      Email:
                    </label>
                    <p className="mb-0">
                      {
                        data && data.email
                          ? <a className="mb-0" rel="noreferrer" target="_blank" href={"mailto:" + data.email}>{data.email}</a>
                          : ""
                      }
                    </p>
                  </div>
                  <div className="d-flex mb-3">
                    <label className=" fw-bold me-2">
                      Status:
                    </label>
                    <p className="mb-0">
                      {data && data.status
                        ? capitalizeFirstLetter(data.status)
                        : ""}
                    </p>
                  </div>
                  <div className="d-flex mb-3">
                    <label className=" fw-bold me-2">
                      Country:
                    </label>
                    <p className="mb-0">
                      {data && data.country
                        ? data.country
                        : ""}
                    </p>
                  </div>
                  <div className="d-flex">
                    <label className=" fw-bold me-2">
                      Updated At:
                    </label>
                    <p className="mb-0">
                      {data && data.updated_at
                        ? moment(data.updated_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                        : ""}
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {data && data.customer ?
            <Card className="border-card">
              <CardBody>
                <Col xl="12">
                  <h4 className="text-primary text-center">
                    User
                  </h4>
                  <Row>
                    <Col xl="4" md="6" className="pt-2">
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          ID:
                        </label>
                        <p className="mb-0">
                          {data.customer.id
                            ? data.customer.id
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Stripe ID:
                        </label>
                        <p className="mb-0">
                          {data.customer.stripe_id
                            ? data.customer.stripe_id
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Stripe Payment ID:
                        </label>
                        <p className="mb-0">
                          {data.customer.stripe_payment_id
                            ? data.customer.stripe_payment_id
                            : ""}
                        </p>
                      </div>
                    </Col>
                    <Col xl="4" md="6" className="pt-2">
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Stripe Apple Pay:
                        </label>
                        <p className="mb-0">
                          {data.customer.stripe_apple_pay
                            ? data.customer.stripe_apple_pay
                            : "0"}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Session Total:
                        </label>
                        <p className="mb-0">
                          {data.customer.session_total
                            ? data.customer.session_total
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Stripe Payment Text:
                        </label>
                        <p className="mb-0">
                          {data.customer.payment_text
                            ? data.customer.payment_text
                            : ""}
                        </p>
                      </div>
                    </Col>
                    <Col xl="4" md="6" className="pt-2">
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Rating Total:
                        </label>
                        <p className="mb-0">
                          {data.customer.rating_total
                            ? data.customer.rating_total
                            : "0"}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Rating:
                        </label>
                        <p className="mb-0">
                          {data.customer.rating
                            ? data.customer.rating
                            : "0"}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Created At:
                        </label>
                        <p className="mb-0">
                          {data.customer.created_at
                            ? moment(data.customer.created_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                            : ""}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </CardBody>
            </Card>
            : ''
          }
          {data && data.expert ?
            <Card className="border-card">
              <CardBody>
                <Col xl="12">
                  <h4 className="text-primary text-center">
                    Sidekick
                  </h4>
                  <Row>
                    <Col xl="4" md="6" className="pt-2">
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          ID:
                        </label>
                        <p className="mb-0">
                          {data.expert.id && checkPermission('expert', 'read')
                            ? <Link to={`/sidekicks/${data.expert.id}/detail`} className="mb-0">{data.expert.id}</Link>
                            : <span>{data.expert.id}</span>}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Stripe Account:
                        </label>
                        <p className="mb-0">
                          {data.expert.stripe_account
                            ? data.expert.stripe_account
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Stripe Currency:
                        </label>
                        <p className="mb-0">
                          {data.expert.stripe_currency
                            ? data.expert.stripe_currency
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Stripe Country:
                        </label>
                        <p className="mb-0">
                          {data.expert.stripe_country
                            ? data.expert.stripe_country
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Stripe Active:
                        </label>
                        <p className="mb-0">
                          {data.expert.stripe_active
                            ? "Active"
                            : "Inactive"}
                        </p>
                      </div>
                    </Col>

                    <Col xl="4" md="6" className="pt-2">
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Country:
                        </label>
                        <p className="mb-0">
                          {data.expert.country
                            ? data.expert.country
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Price:
                        </label>
                        <p className="mb-0">
                          {data.expert.price
                            ? formatAmountUs(data.expert.price)
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Active:
                        </label>
                        <p className="mb-0">
                          {data.expert.is_active
                            ? "Active"
                            : "Inactive"}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Currency ID:
                        </label>
                        <p className="mb-0">
                          {data.expert.currency_id
                            ? data.expert.currency_id
                            : ""}
                        </p>
                      </div>
                    </Col>

                    <Col xl="4" md="6" className="pt-2">
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Rating Total:
                        </label>
                        <p className="mb-0">
                          {data.expert.rating_total
                            ? data.expert.rating_total
                            : "0"}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Rating:
                        </label>
                        <p className="mb-0">
                          {data.expert.rating
                            ? data.expert.rating
                            : "0"}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Requesting:
                        </label>
                        <p className="mb-0">
                          {data.expert.is_requesting
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Availability:
                        </label>
                        <p className="mb-0">
                          {data.expert.availability
                            ? "Active"
                            : "Inactive"}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Headline:
                        </label>
                        <p className="mb-0">
                          {data.expert.title
                            ? data.expert.title
                            : ""}
                        </p>
                      </div>
                    </Col>
                    <Col xl="12" md="12">
                      <div className="d-flex mb-3">
                        <label className=" fw-bold me-2">
                          Description:
                        </label>
                        <p className="mb-0">
                          {data.expert.description
                            ? data.expert.description
                            : ""}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </CardBody>
            </Card>
            : ''
          }
          {data && data.rating_report ?
            <Card className="border-card">
              <CardBody>
                <Col xl="12">
                  <h4 className="text-primary text-center">
                    Rating Report
                  </h4>
                  <Row>
                    <Col xl="6" className="text-center">
                      <Label className="total-statistics">
                        {data.rating_report.total_rating ? data.rating_report.total_rating : 0}
                      </Label>
                      <br></br>
                      <Label className="font-weight-bold">Total Number of ratings a user left</Label>
                    </Col>
                    <Col xl="6" className="text-center">
                      <Label className="total-statistics">
                        {data.rating_report.total_comment ? data.rating_report.total_comment : 0}
                      </Label>
                      <br></br>
                      <Label className="font-weight-bold">Total Number of comment a user left</Label>
                    </Col>
                  </Row>
                </Col>
              </CardBody>
            </Card>
            : ''
          }
          {data && data.appointment_report ?
            <Card className="border-card">
              <CardBody>
                <Col xl="12">
                  <h4 className="text-primary text-center">
                    Session Report
                  </h4>
                  <Row>
                    <Col xl="4" md="6" className="text-center pb-2">
                      <Label className="total-statistics">
                        {data.appointment_report.total_appointment_by_customer ? data.appointment_report.total_appointment_by_customer : 0}
                      </Label>
                      <br></br>
                      <Label className="font-weight-bold">Total session by user</Label>
                    </Col>
                    <Col xl="4" md="6" className="text-center pb-2">
                      <Label className="total-amount-statistics">
                        {data.payment_spent ? formatter.format(data.payment_spent) : 0}
                      </Label>
                      <br></br>
                      <Label className="font-weight-bold">Total Amount of payment a user spent</Label>
                    </Col>
                    <Col xl="4" md="6" className="text-center pb-2">
                      <Label className="active-statistics">
                        {data.appointment_report.appointments_completed_by_customer ? data.appointment_report.appointments_completed_by_customer : 0}
                      </Label>
                      <br></br>
                      <Label className="font-weight-bold">Sessions completed by user</Label>
                    </Col>
                    <Col xl="4" md="6" className="text-center pb-2">
                      <Label className="total-statistics">
                        {data.appointment_report.total_appointment_by_expert ? data.appointment_report.total_appointment_by_expert : 0}
                      </Label>
                      <br></br>
                      <Label className="font-weight-bold">Total session by sidekick</Label>
                    </Col>
                    <Col xl="4" md="6" className="text-center pb-2">
                      <Label className="active-statistics">
                        {data.appointment_report.appointments_completed_by_expert ? data.appointment_report.appointments_completed_by_expert : 0}
                      </Label>
                      <br></br>
                      <Label className="font-weight-bold">Sessions completed by sidekick</Label>
                    </Col>
                    <Col xl="4" md="6" className="text-center pb-2">
                      <Label className="inactive-statistics">
                        {data.appointment_report.appointments_cancelled_by_customer ? data.appointment_report.appointments_cancelled_by_customer : 0}
                      </Label>
                      <br></br>
                      <Label className="font-weight-bold">Sessions cancelled by user</Label>
                    </Col>
                    <Col xl="4" md="6" className="text-center pb-2">
                      <Label className="active-statistics" style={{ color: '#ffcd00' }}>
                        {data.appointment_report.appointments_refund_by_expert ? data.appointment_report.appointments_refund_by_expert : 0}
                      </Label>
                      <br></br>
                      <Label className="font-weight-bold">Sessions refund by sidekick</Label>
                    </Col>
                    <Col xl="4" md="6" className="text-center pb-2">
                      <Label className="active-statistics" style={{ color: '#ffcd00' }}>
                        {data.appointment_report.appointments_refund_by_customer ? data.appointment_report.appointments_refund_by_customer : 0}
                      </Label>
                      <br></br>
                      <Label className="font-weight-bold">Sessions refund by user</Label>
                    </Col>
                    <Col xl="4" md="6" className="text-center pb-2">
                      <Label className="inactive-statistics">
                        {data.appointment_report.appointments_cancelled_by_expert ? data.appointment_report.appointments_cancelled_by_expert : 0}
                      </Label>
                      <br></br>
                      <Label className="font-weight-bold">Sessions cancelled by sidekick</Label>
                    </Col>
                  </Row>
                </Col>
              </CardBody>
            </Card>
            : ''
          }

        </>
        : ''
      }
    </>

  )
}

Detail.propTypes = {
  data: PropTypes.any,
}

export default memo(Detail)
