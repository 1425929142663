import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Label,
  Modal,
  ModalHeader,
  FormFeedback,
  Card,
  CardBody
} from "reactstrap"
import { Link } from "react-router-dom";
import roleService from "../../services/role"
import MetaTags from "react-meta-tags"
import { checkPermission, customUrlParams } from 'helpers/supportFunction';
import moment from "moment";
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

const permission_names = {
  "account" : "Account",
  "appointment" : "Session",
  "category" : "Category",
  "expert" : "Sidekick",
  "expert-featured" : "Banner Featured",
  "ambassador": "Ambassador",
  "log" : "Log",
  "payment" : "Payment",
  "report" : "Report",
  "role" : "Role",
  "setting" : "Setting",
  "skill" : "Skill",
  "user" : "User",
};

const permissions_arr_default = 
[
  {
    name: "account",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "appointment",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "ambassador",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "category",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "expert",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "expert-featured",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "log",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "payment",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "report",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "role",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "setting",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "skill",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  },
  {
    name: "user",
    actions: {
      read: false,
      create: false,
      delete: false,
      update: false,
    },
  }
];

class Role extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      roles: [],
      isAdding: false,
      isDelete: false,
      isSubmit: false,
      search: '',
      currentRole: this.setDefaultRole(),
      dxStore: this.createDxStore()
    }
  }

  setDefaultRole = () => {
    return {
      id: null,
      name: "",
      permissions_arr: permissions_arr_default,
    };
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("role", "read")) {
      toast.warning("You do not have permission to manage roles", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.loadData()
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = ""
        if (this.state.search) {
          url_params += `&search=${this.state.search}`;
        }
        let params = customUrlParams(loadOptions, url_params, 'id', 'desc')
        var response = await roleService.getAll(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          let data = response.data.map(element => {
            let permissions_name = [];
            element.permissions.forEach((item) => {
              if (item.actions.read || item.actions.create || item.actions.delete || item.actions.update) {
                permissions_name.push(item.name);
              }
            });
            element.permissions_name = permissions_name.join(", ");
            return element
          })
          return { data: data, totalCount: response.total_count };
        }
      },
    });
  }

  handleInputText = (type, value) => {
    let currentRole = { ...this.state.currentRole }
    currentRole[[type]] = value
    this.setState({ currentRole: currentRole })
  }

  handleInputcheckbox = (actions, value, permissions, index) => {
    let _permissions_arr = this.state.currentRole.permissions_arr
    _permissions_arr[index].actions[actions] = value
    this.setState({
      currentRole: {
        id: this.state.currentRole.id,
        name: this.state.currentRole.name,
        permissions_arr: _permissions_arr,
      },
    })
  }

  formatCreatedAt = (e) => {
    let role = e.data;
    return (
      <span>
        { typeof role["created_at"] != "undefined" ? moment(role.created_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''}
      </span>
    )
  }

  formatUpdatedAt = (e) => {
    let role = e.data;
    return (
      <span>
        { typeof role["updated_at"] != "undefined" ? moment(role.updated_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''}
      </span>
    )
  }


  onCloseDelete = () => {
    this.setState({
      isDelete: false,
      currentRole: this.setDefaultRole(),
    })
  }

  onDeleteRole = async () => {
    if (this.state.currentRole && this.state.currentRole.id && this.state.isDelete) {
      let response = await roleService.deleteRole(this.state.currentRole.id)
      if (response && response.success) {
        toast.success("Delete role success");
        this.onCloseDelete();
        this.loadData();
      }
    }
  }

  onClose = async () => {
    this.setState({
      isAdding: !this.state.isAdding,
      currentRole: this.setDefaultRole(),
      isSubmit: false
    })
  }

  onClickCreate = async () => {
    this.setState({
      isSubmit: true
    });
    if (this.state.currentRole.name) {
      let response = await roleService.createRole({
        name: this.state.currentRole.name,
        permissions: JSON.stringify(this.state.currentRole.permissions_arr),
      })
      if (response.success) {
        toast.success("Create role success");
        this.onClose();
        this.loadData();
      }
    }
  }

  onClickUpdate = async role => {
    this.setState({
      isSubmit: true
    });
    if (this.state.currentRole.name) {
      let response = await roleService.updateRole({
        role_id: role.id,
        name: role.name,
        permissions: JSON.stringify(role.permissions_arr),
      })
      if (response.success) {
        toast.success("Update role success");
        this.onClose()
        this.loadData();
      }
    }
  }

  customId = (e) => {
    let role = e.data;
    return (
      <Link to="#" onClick={async () => {
          this.setState({
            isAdding: true,
            title: "Detail",
            currentRole: {
              id: role.id,
              name: role.name,
              permissions_arr: role.permissions,
            },
          })
        }}
      >
        {role.id}
      </Link>
    )
  }

  linkUpdate = role => {
    if (role.id) {
      this.setState({
        title: "Update Role",
        isAdding: true,
        currentRole: {
          id: role.id,
          name: role.name,
          permissions_arr: this.sortPermissions(role.permissions),
        },
      })
    }
  }

  sortPermissions = (permissions) => {
    let _permissions_arr = [];
    permissions_arr_default.forEach((item, index) => {
      let permission = permissions.find((permission) => {return permission.name == item.name});
      _permissions_arr.push(permission ? permission : item);
    })
    return _permissions_arr;
  }

  linkEdit = (e) => {
    return (
      <>
        { checkPermission("role", "read") ?
          <Button
            className="mx-1 btn-custom-width"
            onClick={async () => {
              let response = await roleService.getDetailRole(e.data.id)
              this.setState({
                isAdding: true,
                title: "Detail",
                currentRole: {
                  id: response.data.id,
                  name: response.data.name,
                  permissions_arr: this.sortPermissions(response.data.permissions),
                },
              })
            }}
          >
            View Detail
          </Button>
          : ''
        }
        { checkPermission("role", "update") ?
          <Button
            className="mx-1 btn-custom-width"
            color="primary"
            onClick={() => this.linkUpdate(e.data)}
          >
            Update
          </Button>
          : ''
        }
        { checkPermission("role", "delete") ?
          <Button
            className="mx-1 btn-custom-width"
            color="danger"
            onClick={async () => {
              await this.setState({
                isDelete: true,
                currentRole: {
                  id: e.data.id,
                  name: e.data.name,
                },
              })
            }}
          >
            Delete
          </Button>
          : ''
        }
      </>
    )
  }

  handleInputSearch = e => {
    this.setState({ search: e }, () => 
      this.loadData(null, null)
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Roles | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12" className="d-flex align-items-center pb-2">
                    <h4 className="mb-0 me-3 text-primary">Manage Roles</h4>
                  </Col>
                  <Col xl="4" className="pt-2 pb-2">
                    <Input
                      type="search"
                      id="search"
                      className="form-control"
                      placeholder="Search..."
                      value={this.state.search}
                      onChange={e => {
                        this.handleInputSearch(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xl="8" className="text-right pb-2 pt-2">
                    { checkPermission("role", "create") ? 
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setState({
                            isAdding: true,
                            title: "Create Role",
                          })
                        }}
                      >
                        Create Role
                      </Button>
                    : '' }
                  </Col>
                </Row>
                <Row className="pt-1">
                  <Col xl="12">
                    <DataGrid
                      dataSource={this.state.dxStore}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                    >
                      <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80} cellRender={this.customId}></Column>
                      <Column dataField="name" name="name" caption="Role"></Column>
                      <Column dataField="created_at" name="created_at" caption="Created At" cellRender={this.formatCreatedAt}></Column>
                      <Column dataField="updated_at" name="updated_at" caption="Updated At" cellRender={this.formatUpdatedAt}></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'} width={340}></Column>

                      <Paging defaultPageSize={10} />
                      <Pager
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        allowedPageSizes={[10, 25, 50, 100]}
                      />
                    </DataGrid>
                  </Col>
                  <Modal
                    isOpen={this.state.isAdding}
                    role="dialog"
                    autoFocus={true}
                    centered
                    data-toggle="modal"
                    backdrop={this.state.title !== "Detail" ? 'static' : true}
                    toggle={() => {
                      this.onClose()
                    }}
                    size="xl"
                  >
                    <div></div>
                    <div>
                      <ModalHeader
                        className="border-bottom-0"
                        toggle={() => {
                          this.onClose()
                        }}
                      ></ModalHeader>
                    </div>
                    <div className="modal-body">
                      <div>
                        {this.state.isAdding && this.state.currentRole ? 
                          <Row className=" justify-content-center">
                            <Col xl="12">
                              <h4 className="text-primary text-center mb-3">
                                {this.state.title}
                              </h4>
                              <Row>
                                <Col xl="12">
                                  {this.state.title !== "Detail" ? (
                                    <div className="mb-3">
                                      <Label for="name" style={{"paddingLeft": "0"}}>Role Name</Label>
                                      <div className="input-group rounded bg-light">
                                        <Input
                                          type="text"
                                          id="name"
                                          name="name"
                                          placeholder="Role name"
                                          value={this.state.currentRole.name}
                                          onChange={e => {
                                            this.handleInputText("name", e.target.value)
                                          }}
                                          invalid={
                                            this.state.isSubmit &&
                                            !this.state.currentRole.name
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                      {
                                        this.state.isSubmit &&
                                        !this.state.currentRole.name ? 
                                          <FormFeedback type="invalid" className="invalid-inline">
                                            Role name is required
                                          </FormFeedback>
                                        : ''
                                      }
                                    </div>
                                  ) : (
                                    <div className="d-flex">
                                      <span className=" fw-bold me-2">Role:</span>
                                      <p> {this.state.currentRole.name}</p>
                                    </div>
                                  )}
                                </Col>
                                <div className="col-12 ">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Permission</th>
                                        <th scope="col" className="text-center">
                                          Read
                                        </th>
                                        <th scope="col" className="text-center">
                                          Create
                                        </th>
                                        <th scope="col" className="text-center">
                                          Update
                                        </th>
                                        <th scope="col" className="text-center">
                                          Delete
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.currentRole.permissions_arr.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td className="text-left">
                                              { typeof permission_names[item.name] != "undefined" && permission_names[item.name] ? 
                                                permission_names[item.name] : item.name
                                              }
                                            </td>
                                            <td className="text-center">
                                              {this.state.title !== "Detail" ? (
                                                <Input
                                                  className="cursor-pointer custom-checkbox-input"
                                                  type="checkbox"
                                                  defaultChecked={item.actions.read}
                                                  onClick={() => {
                                                    this.handleInputcheckbox(
                                                      "read",
                                                      !item.actions.read,
                                                      item.name,
                                                      index
                                                    )
                                                  }}
                                                />
                                              ) : item.actions.read ? (
                                                <i className="bx bx-check font-weight-bold font-size-16"></i>
                                              ) : (
                                                <i className="bx bx-uncheck"></i>
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {this.state.title !== "Detail" ? (
                                                <Input
                                                  className="custom-checkbox-input"
                                                  type="checkbox"
                                                  defaultChecked={item.actions.create}
                                                  onClick={() => {
                                                    this.handleInputcheckbox(
                                                      "create",
                                                      !item.actions.create,
                                                      item.name,
                                                      index
                                                    )
                                                  }}
                                                />
                                              ) : item.actions.create ? (
                                                <i className="bx bx-check font-weight-bold font-size-16"></i>
                                              ) : (
                                                <i className="bx bx-uncheck"></i>
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {this.state.title !== "Detail" ? (
                                                <Input
                                                  className="custom-checkbox-input"
                                                  type="checkbox"
                                                  defaultChecked={item.actions.update}
                                                  onClick={() => {
                                                    this.handleInputcheckbox(
                                                      "update",
                                                      !item.actions.update,
                                                      item.name,
                                                      index
                                                    )
                                                  }}
                                                />
                                              ) : item.actions.update ? (
                                                <i className="bx bx-check font-weight-bold font-size-16"></i>
                                              ) : (
                                                <i className="bx bx-uncheck"></i>
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {this.state.title !== "Detail" ? (
                                                <Input
                                                  className="custom-checkbox-input"
                                                  type="checkbox"
                                                  defaultChecked={item.actions.delete}
                                                  onClick={() => {
                                                    this.handleInputcheckbox(
                                                      "delete",
                                                      !item.actions.delete,
                                                      item.name,
                                                      index
                                                    )
                                                  }}
                                                />
                                              ) : item.actions.delete ? (
                                                <i className="bx bx-check font-weight-bold font-size-16"></i>
                                              ) : (
                                                <i className="bx bx-un-check"></i>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Row>
                              {this.state.title !== "Detail" ? (
                                <div className="d-flex justify-content-center">
                                  {this.state.title === "Create Role" ? (
                                    <Button
                                      className="mt-3 mx-1"
                                      color="primary"
                                      type="button"
                                      id="button-create"
                                      onClick={() => {
                                        this.onClickCreate()
                                      }}
                                    >
                                      
                                      Create
                                    </Button>
                                  ) : (
                                    <Button
                                      className="mt-3 mx-1"
                                      color="primary"
                                      type="button"
                                      id="button-create"
                                      onClick={() => {
                                        this.onClickUpdate(this.state.currentRole)
                                      }}
                                    >
                                    
                                      Update
                                    </Button>
                                  )}
                                  <Button
                                    className="mt-3 mx-1"
                                    type="button"
                                    id="button-create"
                                    onClick={() => {
                                      this.onClose()
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        : 
                        ''}
                      </div>
                    </div>
                  </Modal>
                  <Modal
                    role="dialog"
                    isOpen={this.state.isDelete}
                    autoFocus={true}
                    centered
                    data-toggle="modal"
                    backdrop={'static'}
                    toggle={() => this.onCloseDelete()}
                  >
                    <div>
                      <ModalHeader
                        className="border-bottom-0"
                        toggle={() => this.onCloseDelete()}
                      ></ModalHeader>
                    </div>
                    <div className="modal-body" style={{"paddingTop": "0"}}>
                      <div className="mb-4">
                        <Row className="justify-content-center">
                          <Col xl="11">
                            <h4 className="text-primary text-center mb-4">Confirmation Delete</h4>
                            <Row>
                              <Label style={{"paddingLeft": "0"}}>Are you sure delete role {this.state.currentRole ? this.state.currentRole.name : ''}?</Label>
                              <Button
                                className="mt-3 d-block mx-auto"
                                color="danger"
                                type="button"
                                onClick={() => {
                                  this.onDeleteRole()
                                }}
                              >
                                Delete
                              </Button>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Modal>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Role
