import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Pages404 from "../pages/PageNotFound"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Expert from "../pages/Experts/index"
import EditExpert from "../pages/Experts/edit/index"
import ExpertRequesting from "../pages/Experts/requesting"
import ExpertDetail from "../pages/Experts/detail/index"
import ExpertDetailRequesting from "../pages/Experts/detail/requesting"

import ExpertFeatured from "../pages/ExpertsFeatured/index"
import ExpertFeaturedUpdate from "../pages/ExpertsFeatured/edit/index"
import ExpertFeaturedCreate from "../pages/ExpertsFeatured/create/index"

import ExpertAmbassadors from "../pages/expert-ambassadors/index"
import ExpertAmbassadorDetail from "../pages/expert-ambassadors/detail/index"

import User from "../pages/Users/index"
import UserDetail from "../pages/Users/detail/index"
import EditUser from "../pages/Users/edit/index"

import Role from "../pages/Roles/index"
import Skill from "../pages/Skills/index"
import Admin from "../pages/Admins/index"
import Appointment from "../pages/Appointments/index"
import AppointmentDetail from "../pages/Appointments/detail/index"
import Payment from "../pages/Payments/index"
import PaymentDetail from "../pages/Payments/detail/index"
import Setting from "pages/Settings/index"
import CommissionRateLogs from "pages/Settings/commission_rate_logs"
import UserReport from "pages/Reports/index"
import SessionReport from "pages/Reports/session_report"
import AmbassadorReport from "pages/Reports/ambassador_report"
import Categories from "../pages/Categories/index"

const Routes = [
  { path: "/logout", component: Logout, layout: false },
  { path: "/login", component: Login, layout: false },
  { path: "/forgot-password", component: ForgetPwd, layout: false },
  { path: "/register", component: Register, layout: false },
  { path: "/page-404", component: Pages404, layout: false },
  { path: "/dashboard", component: Dashboard, permissions: [], layout: true},
  { path: "/sidekicks", component: Expert, permissions: [{name: "expert", actions: ["read"]}], layout: true},
  { path: "/sidekicks/:id/detail", component: ExpertDetail, permissions: [{name: "expert", actions: ["read"]}], layout: true},
  { path: "/sidekicks/:id/edit", component: EditExpert, permissions: [{name: "expert", actions: ["update"]}], layout: true},
  { path: "/sidekicks-request", component: ExpertRequesting, permissions: [{name: "expert", actions: ['read']}], layout: true},
  { path: "/sidekicks-request/:id/detail", component: ExpertDetailRequesting, permissions: [{name: "expert", actions: ["read"]}], layout: true},
  { path: "/sidekicks-request/:id/edit", component: EditExpert, permissions: [{name: "expert", actions: ["update"]}], layout: true},
  { path: "/sidekicks-featured", component: ExpertFeatured, permissions: [{name: "expert-featured", actions: ["read"]}], layout: true},
  { path: "/sidekicks-featured/:id/edit", component: ExpertFeaturedUpdate, permissions: [{name: "expert-featured", actions: ["create"]}], layout: true},
  { path: "/sidekicks-featured/create", component: ExpertFeaturedCreate, permissions: [{name: "expert-featured", actions: ["update"]}], layout: true},
  { path: "/sidekick-ambassadors", component: ExpertAmbassadors, permissions: [{name: "ambassador", actions: ["read"]}], layout: true},
  { path: "/sidekick-ambassadors/:id/detail", component: ExpertAmbassadorDetail, permissions: [{name: "ambassador", actions: ["create"]}], layout: true},
  { path: "/users", component: User, permissions: [{name: "user", actions: ['read']}], layout: true},
  { path: "/users/:id/detail", component: UserDetail, permissions: [{name: "expert", actions: ["read"]}], layout: true},
  { path: "/users/:id/edit", component: EditUser, permissions: [{name: "expert", actions: ["update"]}], layout: true},
  { path: "/admins", component: Admin, permissions: [{name: "account", actions: ['read']}], layout: true},
  { path: "/roles", component: Role, permissions: [{name: "role", actions: ['read']}], layout: true},
  { path: "/profile", component: UserProfile, permissions: [{name: "account", actions: ['read']}], layout: true},
  { path: "/sessions", component: Appointment, permissions: [{name: "appointment", actions: ['read']}], layout: true},
  { path: "/sessions/:id/detail", component: AppointmentDetail, permissions: [{name: "appointment", actions: ['read']}], layout: true},
  { path: "/payments", component: Payment, permissions: [{name: "payment", actions: ['read']}], layout: true},
  { path: "/payments/:id/detail", component: PaymentDetail, permissions: [{name: "payment", actions: ['read']}], layout: true},
  { path: "/skills", component: Skill, permissions: [{name: "skill", actions: ['read']}], layout: true},
  { path: "/categories", component: Categories, permissions: [{name: "category", actions: ['read']}], layout: true},
  { path: "/settings", component: Setting, permissions: [{name: "setting", actions: ['read']}], layout: true },
  { path: "/settings/commission-rate-logs", component: CommissionRateLogs, permissions: [{name: "setting", actions: ['read']}], layout: true },
  { path: "/reports/user-report", component: UserReport, permissions: [{name: "user", actions: ['read']}, {name: "report", actions: ['read']}], layout: true },
  { path: "/reports/session-report", component: SessionReport, permissions: [{name: "appointment", actions: ['read']}, {name: "report", actions: ['read']}], layout: true },
  { path: "/reports/ambassador-report", component: AmbassadorReport, permissions: [{name: "ambassador", actions: ['read']}, {name: "report", actions: ['read']}], layout: true },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> , permissions: [{name: "user", actions: ['read']}], layout: true},
];

export { Routes }

