import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Card,
  CardBody
} from "reactstrap";
import { Link } from "react-router-dom";
import settingService from "../../services/setting";
import MetaTags from "react-meta-tags";
import { checkPermission, customUrlParams } from 'helpers/supportFunction';
import moment from "moment";
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      dxStore: this.createDxStore()
    }
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("setting", "read")) {
      toast.warning("You do not have permission to manage settings", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.loadData()
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = ""
        if (this.state.search) {
          url_params += `&search=${this.state.search}`;
        }
        let params = customUrlParams(loadOptions, url_params, 'id', 'desc')
        var response = await settingService.getCommissionLog(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count ? response.total_count : response.data.length };
        }
      },
    });
  }

  formatValue = (e) => {
    let log = e.data;
    return (
      <div className="text-right">
        { log ? parseFloat(log.value) : ''}
      </div>
    )
  }

  formatCreatedAt = (e) => {
    let log = e.data;
    return (
      <span>
        {log.created_at
          ? moment(log.created_at).format(
              process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT
            )
          : ""}
      </span>
    )
  }

  handleInputSearch = e => {
    this.setState({ search: e }, () => 
      this.loadData(null, null)
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Commission Rate Logs | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="6">
                    <h4 className="text-primary">Manage Commission Rate Logs</h4>
                  </Col>
                  <Col xl="6" className="text-right">
                    <Link to="/settings">
                      <Button className="btn-custom-width"> Back </Button>
                    </Link>
                  </Col>
                  <Col xl="4" className="pt-1">
                    <Input
                      type="search"
                      id="search"
                      className="form-control"
                      placeholder="Search..."
                      value={this.state.search}
                      onChange={e => {
                        this.handleInputSearch(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col xl="12">
                    <DataGrid
                      dataSource={this.state.dxStore}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                    >
                      <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80}></Column>
                      <Column dataField="email" name="email" caption="Admin"></Column>
                      <Column dataField="ip" name="ip" caption="IP"></Column>
                      <Column dataField="value" name="value" caption="Value" alignment={'left'} width={100}></Column>
                      <Column dataField="created_at" name="created_at" caption="Created At"cellRender={this.formatCreatedAt}></Column>
                      <Paging defaultPageSize={10} />
                      <Pager
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        allowedPageSizes={[10, 25, 50, 100]}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Payment
