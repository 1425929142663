import React, { memo, useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody, Modal, ModalHeader, Button } from "reactstrap"
import { Link } from "react-router-dom";
import imageUser from "../../assets/images/image-user.jpg";
import { checkImage, capitalizeFirstLetter, checkPermission, formatAmountUs } from 'helpers/supportFunction';
import moment from "moment";
import DetailAppointment from "components/appointments/detail";
import appointmentService from "services/appointment"

const Detail = props => {
  const { detailPayment } = props
  const [dataAppointmentDetail, setDataAppointmentDetail] = useState(null)
  const [openAppointmentDetail, setOpenAppointmentDetail] = useState(false)
  const [waitingLoadData, setWaitingLoadData] = useState(false)

  const openModalDetailAppointment = async (booking) => {
    setWaitingLoadData(true)
    try {
      let response = await appointmentService.getDetailAppointment(booking.appointment_id);
      if (response && response.data) {
        setDataAppointmentDetail(response.data)
        setOpenAppointmentDetail(true)
      }
    } catch (error) {
      console.log(error)
    }
    setWaitingLoadData(false)
  }

  return (
    <>
      {detailPayment && detailPayment.id ?
        <div className={`${waitingLoadData ? 'waitLoadingData' : ''}`}>
          <Card className="border-card">
            <CardBody>
              <Row>
                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">Payment ID:</span>
                    <p className="mb-0">
                      {detailPayment.id}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Stripe Status:
                    </span>
                    <p className="mb-0">
                      {detailPayment.stripe_status
                        ? capitalizeFirstLetter(detailPayment.stripe_status)
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Payment Intent ID:
                    </span>
                    <p className="mb-0">
                      {detailPayment.payment_intent_id
                        ? detailPayment.payment_intent_id
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Status:
                    </span>
                    <p className="mb-0">
                      {detailPayment.status
                        ? capitalizeFirstLetter(detailPayment.status)
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Stripe Currency:
                    </span>
                    <p className="mb-0">
                      {detailPayment.stripe_currency
                        ? detailPayment.stripe_currency
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Commission:
                    </span>
                    <p className="mb-0">
                      {detailPayment.commission
                        ? formatAmountUs(detailPayment.commission)
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Transfer:
                    </span>
                    <p className="mb-0">
                      {detailPayment.transfer
                        ? formatAmountUs(detailPayment.transfer)
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Stripe Fee:
                    </span>
                    <p className="mb-0">
                      {detailPayment.stripe_fee
                        ? formatAmountUs(detailPayment.stripe_fee)
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Charged At:
                    </span>
                    <p className="mb-0">
                      {detailPayment.charged_at
                        ? moment(detailPayment.charged_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Refund:
                    </span>
                    <p className="mb-0">
                      {detailPayment.refund
                        ? formatAmountUs(detailPayment.refund)
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Stripe Tax:
                    </span>
                    <p className="mb-0">
                      {detailPayment.stripe_tax
                        ? formatAmountUs(detailPayment.stripe_tax)
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Created At:
                    </span>
                    <p className="mb-0">
                      {detailPayment.created_at
                        ? moment(detailPayment.created_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Total Charge:
                    </span>
                    <p className="mb-0">
                      {detailPayment.total_charge
                        ? formatAmountUs(detailPayment.total_charge)
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Stripe Total Charge:
                    </span>
                    <p className="mb-0">
                      {detailPayment.stripe_total_charge
                        ? formatAmountUs(detailPayment.stripe_total_charge)
                        : ""}
                    </p>
                  </div>
                </Col>

                <Col xl="4" md="6">
                  <div className="d-flex mb-3">
                    <span className=" fw-bold me-2">
                      Updated At:
                    </span>
                    <p className="mb-0">
                      {detailPayment.updated_at
                        ? moment(detailPayment.updated_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                        : ""}
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {detailPayment.customer ?
            <Card className="border-card">
              <CardBody>
                <h4 className="text-primary text-center">
                  User
                </h4>
                <Row>
                  <Col xl="4">
                    <div className="d-block text-center">
                      <img
                        id={`image_customer_detail_${typeof detailPayment.customer.user_id != "undefined" ? detailPayment.customer.user_id : ''}`}
                        src={
                          detailPayment.customer.image ?
                            checkImage(detailPayment.customer.image, `image_customer_detail_${typeof detailPayment.customer.user_id != "undefined" ? detailPayment.customer.user_id : ''}`, imageUser)
                            : imageUser
                        }
                        className="img-avatar"
                      />
                    </div>
                  </Col>
                  <Col xl="4" md="6" className="pt-3">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        User ID:
                      </span>
                      <p className="mb-0">
                        {detailPayment.customer.user_id && checkPermission('user', 'read')
                          ? <Link to={`/users/${detailPayment.customer.user_id}/detail`} className="mb-0">{detailPayment.customer.user_id}</Link>
                          : <span>{detailPayment.customer.user_id}</span>}
                      </p>
                    </div>

                    <div className="d-flex  mb-3">
                      <span className=" fw-bold me-2">
                        Name:
                      </span>
                      <p className="mb-0">
                        {detailPayment.customer.name
                          ? detailPayment.customer.name
                          : ""}
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" md="6" className="pt-3">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        User Name:
                      </span>
                      <p className="mb-0">
                        {detailPayment.customer.user_name
                          ? detailPayment.customer
                            .user_name
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3 ">
                      <span className="  fw-bold me-2 me-2">
                        Email:
                      </span>
                      {
                        detailPayment.customer.email
                          ? <a className="mb-0" rel="noreferrer" target="_blank" href={"mailto:" + detailPayment.customer.email}>{detailPayment.customer.email}</a>
                          : ""
                      }
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            :
            ''}

          {detailPayment.expert ?
            <Card className="border-card">
              <CardBody>
                <h4 className="text-primary text-center">
                  Sidekick
                </h4>
                <Row>
                  <Col xl="4">
                    <div className="d-block text-center">
                      <br />
                      <img
                        id={`image_user_detail_${typeof detailPayment.expert.id != "undefined" ? detailPayment.expert.id : ''}`}
                        src={
                          detailPayment.expert.image ?
                            checkImage(detailPayment.expert.image, `image_user_detail_${typeof detailPayment.expert.id != "undefined" ? detailPayment.expert.id : ''}`, imageUser)
                            : imageUser
                        }
                        className="img-avatar"
                      />
                    </div>
                  </Col>
                  <Col xl="4" md="6" className="pt-3">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        ID:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.id && checkPermission('expert', 'read')
                          ? <Link to={`/sidekicks/${detailPayment.expert.id}/detail`} className="mb-0">{detailPayment.expert.id}</Link>
                          : <span>{detailPayment.expert.id}</span>}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        User ID:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.user_id
                          ? detailPayment.expert.user_id
                          : ""}
                      </p>
                    </div>

                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        User Name:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.user_name
                          ? detailPayment.expert.user_name
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3 ">
                      <span className="  fw-bold me-2 me-2">
                        Email:
                      </span>
                      {
                        detailPayment.expert.email
                          ? <a className="mb-0" rel="noreferrer" target="_blank" href={"mailto:" + detailPayment.expert.email}>{detailPayment.expert.email}</a>
                          : ""
                      }
                    </div>
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Name:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.name
                          ? detailPayment.expert.name
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Active:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.is_active
                          ? "Active"
                          : "Inactive"}
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" md="6" className="pt-3">
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Headline:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.title
                          ? detailPayment.expert.title
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Phone:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.phone
                          ? detailPayment.expert.phone
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Rating:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.rating
                          ? detailPayment.expert.rating
                          : "0"}
                      </p>
                    </div>
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Total Rating:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.rating_total
                          ? detailPayment.expert.rating_total
                          : "0"}
                      </p>
                    </div>
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Stripe Active:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.stripe_active
                          ? "Active"
                          : "Inactive"}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Availability:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.availability
                          ? "Active"
                          : "Inactive"}
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" md="0"></Col>
                  <Col xl="8" md="12">
                    <div className="d-flex mb-3 ">
                      <span className=" fw-bold me-2">
                        Bio:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.bio
                          ? detailPayment.expert.bio
                          : ""}
                      </p>
                    </div>
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Description:
                      </span>
                      <p className="mb-0">
                        {detailPayment.expert.description
                          ? detailPayment.expert.description
                          : ""}
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            : ''
          }
          {detailPayment && detailPayment.bookings ? (
            <Card className="border-card">
              <CardBody>
                <Col xl="12">
                  <h4 className="text-primary mb-3 text-center">
                    Sessions
                  </h4>
                  <Row>
                    <table className="table table align-middle table-nowrap table-check">
                      <thead>
                        <tr>
                          <th>Session ID</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Price</th>
                          <th>Amount</th>
                          <th>Created At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {detailPayment.bookings.map((booking, index) => (
                          <tr key={index}>
                            <td><Link to={`/sessions/${booking.appointment_id}/detail`}> {booking.appointment_id}</Link></td>
                            <td>{moment(booking.start_time).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)}</td>
                            <td>{moment(booking.end_time).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)}</td>
                            <td>{formatAmountUs(booking.price)}</td>
                            <td>{formatAmountUs(booking.amount)}</td>
                            <td>{moment(booking.created_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)}</td>
                            <td>
                              <Button
                                className="btn mx-1 btn-custom-width"
                                color="secondary"
                                onClick={() => openModalDetailAppointment(booking)}
                              >
                                View Detail
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Row>
                </Col>
                <Modal
                  isOpen={openAppointmentDetail}
                  size="xl"
                  role="dialog"
                  autoFocus={true}
                  centered
                  data-toggle="modal"
                  toggle={() => {
                    setDataAppointmentDetail(null);
                    setOpenAppointmentDetail(false);
                  }}
                >
                  <div>
                    <ModalHeader
                      className="border-bottom-0"
                      toggle={() => {
                        setDataAppointmentDetail(null);
                        setOpenAppointmentDetail(false);
                      }}
                    >Session Detail</ModalHeader>
                  </div>
                  <div className="modal-body pt-0">
                    <DetailAppointment
                      detailAppointment={dataAppointmentDetail}
                    />
                  </div>
                </Modal>
              </CardBody>
            </Card>
          ) : (
            <></>
          )}
        </div>
        : ''
      }
    </>
  )
}

Detail.propTypes = {
  detailPayment: PropTypes.any,
}

export default memo(Detail)
