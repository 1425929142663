import React, { Component } from "react"
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  Label,
  Input,
  FormFeedback
} from "reactstrap"
import { Link } from "react-router-dom";
import appointmentService from "../../services/appointment"
import ModalConfirm from "components/modals/modalConfirm"
import {
  checkPermission,
  customCustomer,
  customExpert,
  customExpertCategories,
  customLinkToStripe,
  customTransactionStatus,
  customUrlParams,
  formatAmount,
  formatDurationTime,
  formatEndTime,
  formatRefund,
  formatStartTime,
} from 'helpers/supportFunction';
import moment from "moment";
import toast from 'helpers/toast';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import DataGrid, { Column, Pager, Paging, Selection } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

class tableCancelAppointments extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this);
    this.state = {
      title: "",
      isUpdate: false,
      isSubmit: false,
      detailAppointment: null,
      activeTab: '1',
      filterData: {
        start_time: '',
        end_time: '',
        search: ''
      },
      dxStore: this.createDxStore(),
      dataAppointment: null,
      isOpenConfirm: false,
      actionConfirm: '',
      messageConfirm: '',
      waiting: false,
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = ""
        if (this.state.filterData) {
          if (this.state.filterData.start_time && this.state.filterData.end_time) {
            url_params += `&start_time=${moment(this.state.filterData.start_time).format("YYYY-MM-DD HH:mm:ss")}&end_time=${moment(this.state.filterData.end_time).format("YYYY-MM-DD HH:mm:ss")}`;
          }
          if (this.state.filterData.status) {
            url_params += `&type=${this.state.filterData.status}&current_time=${moment().format("YYYY-MM-DD HH:mm:ss")}`;
          }
          if (this.state.filterData.search) {
            url_params += `&search=${this.state.filterData.search}`;
          }
        }
        let params = customUrlParams(loadOptions, url_params, 'id', 'desc')
        var response = await appointmentService.getCancelAppointments(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  onFilter = () => {
    if (this.state.filterData.start_time && this.state.filterData.end_time) {
      if (this.state.filterData.start_time > this.state.filterData.end_time) {
        toast.warning("Please enter start time less than or equal to end time");
        return;
      }
    }
    this.loadData()
  }

  onReset = () => {
    this.setState({
      filterData: {
        start_time: '',
        end_time: '',
        search: ''
      }
    }, () => 
      this.loadData()
    );
  }

  renderRefund = (appointment) => {
    return (
      <>
        {
          appointment.transaction_status == 'refund_failed' && 
          <Button 
            className="mx-1 btn btn-info btn-custom-width"
            onClick={() => {
              this.setState({
                isOpenConfirm: true,
                actionConfirm: 'Retry Refund',
                messageConfirm: 'Are you sure want to retry refund for this session',
                dataAppointment: appointment,
                waiting: false,
              })
            }}
          >
            Retry Refund
          </Button>
        }
        {
          appointment.transaction_status == 'transfer_failed' && 
          <Button 
            className="mx-1 btn btn-info btn-custom-width"
            onClick={() => {
              this.setState({
                isOpenConfirm: true,
                actionConfirm: 'Retry Transfer',
                messageConfirm: 'Are you sure want to retry transfer for this session',
                dataAppointment: appointment,
                waiting: false,
              })
            }}
          >
            Retry Transfer
          </Button>
        }
      </>
    )
  }

  linkEdit = (e) => {
    let appointment = e.data
    return (
      <>
        { checkPermission("appointment", "read") ?
          <Link to={`sessions/${appointment.id}/detail`}>
            <Button className="mx-1 btn-custom-width">
              View Detail
            </Button>
          </Link>
        : ''}
        { checkPermission("appointment", "update") && appointment.transaction_status && this.renderRefund(appointment)}
      </>
    )
  }

  onCloseModalConfirm = () => {
    this.setState({
      isOpenConfirm: false,
      actionConfirm: '',
      messageConfirm: '',
      dataAppointment: null,
      waiting: false,
    })
  }


  onActionConfirm = async () => {
    let response = null;
    this.setState({ waiting: true });
    try {
      if (this.state.dataAppointment) {
        if (this.state.dataAppointment.transaction_status == 'refund_failed') {
          response = await appointmentService.retryRefundBooking({
            booking_id: this.state.dataAppointment.booking_id,
          })
        } else if (this.state.dataAppointment.transaction_status == 'transfer_failed') {
          response = await appointmentService.retryTransferBooking({
            booking_id: this.state.dataAppointment.booking_id,
          })
        }
      }
      if(response && response.success) {
        toast.success(this.state.actionConfirm + " successfully");
        this.loadData();
      }
    } catch (error) {
      console.log(error)
    }
    this.onCloseModalConfirm();
  }


  onClose = async () => {
    this.setState({
      isUpdate: false,
      appointmentDetail: null,
      isSubmit: false,
    })
  }

  handleInputText = (value) => {
    let detailAppointment = this.state.detailAppointment;
    detailAppointment.refund = value;
    this.setState({ 
      detailAppointment: detailAppointment
    })
  }

  onClickUpdate = async detailAppointment => {
    this.setState({isSubmit: true});
    if (this.state.detailAppointment && this.state.detailAppointment.refund && parseFloat(this.state.detailAppointment.refund) == parseFloat(this.state.detailAppointment.amount)) {
      let response = await appointmentService.refundAppointment({
        appointment_id: detailAppointment.id,
        refund: this.state.detailAppointment.refund,
      })
      if (response.success) {
        toast.success("Refund appointment success");
        this.onClose();
        this.loadData();
      }
    }
  }

  formatDuration = (e) => {
    return (
      <span>
        { formatDurationTime(e.data.duration) }
      </span>
    )
  }

  customId = (e) => {
    let appointment = e.data
    return (
      <Link to={`sessions/${appointment.id}/detail`}
      >
        {appointment.id}
      </Link>
    )
  }

  customPaymentId = (e) => {
    let appointment = e.data
    return (
      appointment.payment_id && <Link to={`/payments/${appointment.payment_id}/detail`}
        >
          {appointment.payment_id}
        </Link>
    )
  }

  handleInput = (type, value) => {
    let filterData = { ...this.state.filterData }
    filterData[[type]] = value
    this.setState({ filterData: filterData }, () => {
      if (type == 'search' || type == 'status' || (this.state.filterData.start_time && this.state.filterData.end_time && this.state.filterData.start_time < this.state.filterData.end_time)) {
        this.loadData();
      }
    });
  }

  transactionError = (e) => {
    let appointment = e.data;
    return (
      <span className="text-danger">
      { appointment.error }
      </span>
    )
  }

  render() {
    return (
      <div className={`${this.state.waiting ? 'waitLoadingData' : ''}` }>
        <Row className="pt-2">
        <Col xl="3" md="12" className="pb-2 pt-2">
            <label className="mb-1 fw-bold me-2">Search By</label>
            <Input
              type="search"
              id="search"
              className="form-control"
              placeholder="Search..."
              value={this.state.filterData.search}
              onChange={e => {
                this.handleInput("search", e.target.value)
              }}
            />
          </Col>
          <Col xl="2" md="6" className="pb-2 pt-2">
            <label className="mb-1 fw-bold me-2">Start Time:</label>
            <div className="d-flex">
              <DatePicker
                selected={this.state.filterData.start_time}
                onChange={e => {
                  this.handleInput("start_time", e)
                }}
                className="form-control"
                showTimeSelect
                dropdownMode="select"
                timeIntervals={10}
                timeFormat="HH:mm"
                dateFormat="MM/dd/yyyy HH:mm"
                placeholderText={'Start time'} 
              />
            </div>
          </Col>
          <Col xl="2" md="6" className="pb-2 pt-2">
            <label className="mb-1 fw-bold me-2">End Time:</label>
            <div className="d-flex">
              <DatePicker
                selected={this.state.filterData.end_time}
                onChange={e => {
                  this.handleInput("end_time", e)
                }}
                className="form-control"
                showTimeSelect
                dropdownMode="select"
                timeIntervals={10}
                timeFormat="HH:mm"
                dateFormat="MM/dd/yyyy HH:mm"
                placeholderText={'End time'} 
              />
            </div>
          </Col>
          <Col xl="2" md="12" className="d-flex align-items-end pb-2 mt-2">
            <Button
              color="primary"
              onClick={() => {
                this.onFilter()
              }}
              className="btn-custom-width"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                this.onReset()
              }}
              className="ml-10px btn-custom-width"
            >
              Reset
            </Button>
          </Col>
          <Col xl="12" className="pt-1">
            <DataGrid
              dataSource={this.state.dxStore}
              showBorders={true}
              remoteOperations={true}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
            >
              <Selection
                mode="multiple"
                selectAllMode={'page'}
                showCheckBoxesMode={'always'}
              />
              <Column dataField="payment_id" name="payment_id" caption="Payment ID" alignment={'center'} width={100} cellRender={this.customPaymentId}></Column>
              <Column dataField="id" name="id" caption="Session ID" alignment={'center'} width={100} cellRender={this.customId}></Column>
              <Column dataField="payment_intent_id" name="payment_intent_id" caption="Stripe Payment ID" cellRender={customLinkToStripe}></Column>
              <Column dataField="customer.name" name="customer.name" caption="User" allowSorting={false} cellRender={customCustomer}></Column>
              <Column dataField="expert.name" name="expert.name" caption="Sidekick" allowSorting={false} cellRender={customExpert}></Column>
              <Column dataField="expert.categories" name="expert.categories" caption="Categories" allowSorting={false} cellRender={customExpertCategories}></Column>
              <Column dataField="start_time" name="start_time" caption="Start Time" cellRender={formatStartTime}></Column>
              <Column dataField="end_time" name="end_time" caption="End Time" cellRender={formatEndTime}></Column>
              <Column dataField="duration" name="duration" caption="Duration" cellRender={this.formatDuration} alignment={'left'}></Column>
              <Column dataField="amount" name="amount" caption="Price" alignment={'right'} cellRender={formatAmount}></Column>
              <Column dataField="refund" name="refund" caption="Refund" cellRender={formatRefund}></Column>
              <Column dataField="transaction_status" name="transaction_status" caption="Transaction Status" cellRender={customTransactionStatus} allowSorting={false}></Column>
              <Column dataField="error" name="error" caption="Error" cellRender={this.transactionError} allowSorting={false}></Column>
              <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'left'} minWidth={130}></Column>
              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
                allowedPageSizes={[10, 25, 50, 100]}
              />
            </DataGrid>
          </Col>
          <Modal
            isOpen={this.state.isUpdate}
            role="dialog"
            autoFocus={true}
            centered
            data-toggle="modal"
            backdrop={'static'}
            toggle={() => {
              this.onClose()
            }}
          >
            <div>
              <ModalHeader
                className="border-bottom-0"
                toggle={() => {
                  this.onClose()
                }}
              ></ModalHeader>
            </div>
            <div className="modal-body">
              <div className=" mb-4">
                <Row className=" justify-content-center">
                  <Col xl="12">
                    <h4 className="text-primary text-center mb-3">
                      Refund Session
                    </h4>
                    {this.state.detailAppointment && this.state.detailAppointment.id ?
                      <>
                        <Row>
                          <Col xl="12" style={{textAlign: 'left'}} className="mb-3">
                            <Label for="refund" style={{"paddingLeft": "0"}}>Refund Amount</Label>
                            <div className="input-group rounded bg-light">
                              <Input
                                type="number"
                                id="refund"
                                name="refund"
                                className="form-control bg-transparent border-0"
                                placeholder="Input Refund"
                                value={this.state.detailAppointment.refund}
                                onChange={e => {
                                  this.handleInputText(e.target.value)
                                }}
                                invalid={
                                  this.state.isSubmit && !this.state.refund
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            {
                              this.state.isSubmit && !this.state.detailAppointment.refund ? 
                                <FormFeedback type="invalid" className="invalid-inline">
                                  Refund amount is required
                                </FormFeedback>
                              : ''
                            }
                            {
                              this.state.isSubmit && this.state.detailAppointment.refund && parseFloat(this.state.detailAppointment.refund) !== parseFloat(this.state.detailAppointment.amount) ? 
                                <FormFeedback type="invalid" className="invalid-inline">
                                  Value refund amount must equal the price
                                </FormFeedback>
                              : ''
                            }
                          </Col>
                        </Row>
                        <Row>
                          <div>
                            <Button
                              className="mt-3 mx-auto d-block"
                              color="primary"
                              type="button"
                              id="button-update"
                              onClick={() => {
                                this.onClickUpdate(this.state.detailAppointment)
                              }}
                            >
                              Update
                            </Button>
                          </div>
                        </Row>
                      </>
                      : ''
                    }
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
          <ModalConfirm
            isOpen={this.state.isOpenConfirm}
            title={'Confirmation ' + this.state.actionConfirm}
            message={this.state.messageConfirm}
            textButton={this.state.actionConfirm}
            colorButton={"info"}
            onCloseModal={() => {
              this.onCloseModalConfirm();
            }}
            onActionConfirm={ async () => {
              await this.onActionConfirm()
            }}
          />
        </Row>
      </div>
    )
  }
}

export default tableCancelAppointments
