import Axios from "axios";
import { toastError } from 'helpers/supportFunction';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const contentTypeJson = [
  '/admin/expert-featured/re-order'
];

axios.interceptors.request.use((config) => {
  if (contentTypeJson.includes(config.url)) {
    config.headers["Content-Type"] = "application/json";
  } else {
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";  
  }
  const token = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")) : null;
  if (token) {
    config.headers.common["Authorization"] = token
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    if(response.data && (!response.data.success || (response.data.code && response.data.code != 200))) {
      toastError(response.data.error ? response.data.error : 'System Error');
    }
    if (response.data && response.data.code === 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("userSidekick");
      if (location.pathname != "/login") {
        setTimeout(() => {
          window.location = "/login";
        }, 1500);
      }
    }
    return response?.data;
  },
  function (error) {
    if (error.response?.data?.message || error.message) {
      toastError(error.message ? error.message : error.response.data.message);
    }
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("userSidekick");
      if (location.pathname != "/login") {
        setTimeout(() => {
          window.location = "/login";
        }, 1500);
      }
    }
    return error.response;
  }
);

export default axios;
