import React, { memo } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom";
import imageUser from "../../assets/images/image-user.jpg";
import TableAppointmentLogs from 'components/appointments/tableAppointmentLogs';
import { checkImage, formatAmountUs, checkPermission, getAppointmentStatusLabel, capitalizeFirstLetter, formatDurationTime, formatReason } from 'helpers/supportFunction';
import moment from "moment";

const Detail = props => {
  const { detailAppointment } = props;

  function formatDuration() {
    return (
      <span>
        {formatDurationTime(detailAppointment.duration)}
      </span>
    )
  }
  return (
    <>
      {detailAppointment && detailAppointment.id ?
        <>
          <Card className="border-card">
            <CardBody>
              <Col xl="12">
                <Row>
                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">Session ID:</span>
                      <p className="mb-0">
                        {detailAppointment.id
                          ? detailAppointment.id
                          : ""}
                      </p>
                    </div>
                  </Col>

                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Price:
                      </span>
                      <p className="mb-0">
                        {detailAppointment.amount
                          ? formatAmountUs(detailAppointment.amount)
                          : ""}
                      </p>
                    </div>
                  </Col>

                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Start Time:
                      </span>
                      <p className="mb-0">
                        {detailAppointment.start_time
                          ? moment(detailAppointment.start_time).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                          : ""}
                      </p>
                    </div>
                  </Col>

                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Duration:
                      </span>
                      <p className="mb-0">
                        {formatDuration()}
                      </p>
                    </div>
                  </Col>

                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Type:
                      </span>
                      <p className="mb-0">
                        {detailAppointment?.is_video_call ? 'Video' : 'Call'}
                      </p>
                    </div>
                  </Col>

                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        End Time:
                      </span>
                      <p className="mb-0">
                        {detailAppointment.end_time
                          ? moment(detailAppointment.end_time).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                          : ""}
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Payment ID:
                      </span>
                      <p className="mb-0">
                        <Link className="fw-bold font-size-14" to={`/payments/${detailAppointment.payment_id}/detail`}>{detailAppointment.payment_id}</Link>
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Status:
                      </span>
                      <p className="mb-0">
                        {detailAppointment.status
                          ? capitalizeFirstLetter(getAppointmentStatusLabel(detailAppointment.status))
                          : ""}
                      </p>
                    </div>
                  </Col>
                  {
                    detailAppointment.status == 'expired' && <Col xl="4" md="6" >
                      <div className="d-flex mb-3">
                        <span className=" fw-bold me-2">
                          Reason:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.reason ? formatReason(detailAppointment.reason) : ""}
                        </p>
                      </div>
                    </Col>
                  }
                  <Col xl="4" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Transaction Status:
                      </span>
                      <p className="mb-0 text-danger">
                        {detailAppointment.transaction_status ?
                          capitalizeFirstLetter(detailAppointment.transaction_status).replaceAll("_", " ")
                          : ""}
                      </p>
                    </div>
                  </Col>
                  <Col xl="8" md="6">
                    <div className="d-flex mb-3">
                      <span className=" fw-bold me-2">
                        Transaction Error:
                      </span>
                      <p className="mb-0 text-danger">
                        {detailAppointment.error ? detailAppointment.error : ""}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </CardBody>
          </Card>

          {detailAppointment && detailAppointment.customer ?
            <Card className="border-card">
              <CardBody>
                <Col xl="12">
                  <h4 className="text-primary mb-1 text-center">
                    User
                  </h4>
                  <Row>
                    <Col xl="4">
                      <div className="d-block text-center">
                        <img
                          id={`image_customer_detail_${typeof detailAppointment.customer.user_id != "undefined" ? detailAppointment.customer.user_id : ''}`}
                          src={
                            detailAppointment.customer.image ?
                              checkImage(detailAppointment.customer.image, `image_customer_detail_${typeof detailAppointment.customer.user_id != "undefined" ? detailAppointment.customer.user_id : ''}`, imageUser)
                              : imageUser
                          }
                          className="img-avatar"
                        />
                      </div>
                    </Col>
                    <Col xl="4" md="6" className="pt-3">
                      <div className="d-flex mb-3">
                        <span className=" fw-bold me-2">
                          User ID:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.customer.user_id && checkPermission('user', 'read') ?
                            <Link to={`/users/${detailAppointment.customer.user_id}/detail`} className="mb-0">{detailAppointment.customer.user_id}</Link>
                            : <span>{detailAppointment.customer.user_id}</span>
                          }
                        </p>
                      </div>

                      <div className="d-flex  mb-3">
                        <span className=" fw-bold me-2">
                          Name:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.customer.name
                            ? detailAppointment.customer.name
                            : ""}
                        </p>
                      </div>
                    </Col>
                    <Col xl="4" md="6" className="pt-3">
                      <div className="d-flex mb-3">
                        <span className=" fw-bold me-2">
                          User Name:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.customer.user_name
                            ? detailAppointment.customer
                              .user_name
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3 ">
                        <span className="  fw-bold me-2 me-2">
                          Email:
                        </span>
                        <p className="mb-0">
                          {
                            detailAppointment.customer.email
                              ? <a className="mb-0" rel="noreferrer" target="_blank" href={"mailto:" + detailAppointment.customer.email}>{detailAppointment.customer.email}</a>
                              : ""
                          }
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </CardBody>
            </Card>
            : ''}

          {detailAppointment && detailAppointment.expert ?
            <Card className="border-card">
              <CardBody>
                <Col xl="12">
                  <h4 className="text-primary mb-1 text-center">
                    Sidekick
                  </h4>
                  <Row>
                    <Col xl="4" md="12">
                      <div className="d-block text-center">
                        <br />
                        <img
                          id={`image_user_detail_${typeof detailAppointment.expert.id != "undefined" ? detailAppointment.expert.id : ''}`}
                          src={
                            detailAppointment.expert.image ?
                              checkImage(detailAppointment.expert.image, `image_user_detail_${typeof detailAppointment.expert.id != "undefined" ? detailAppointment.expert.id : ''}`, imageUser)
                              : imageUser
                          }
                          className="img-avatar"
                        />
                      </div>
                    </Col>
                    <Col xl="4" md="12" className="pt-3">
                      <div className="d-flex mb-3">
                        <span className=" fw-bold me-2">
                          ID:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.id && checkPermission('expert', 'read')
                            ? <Link to={`/sidekicks/${detailAppointment.expert.id}/detail`} className="mb-0">{detailAppointment.expert.id}</Link>
                            : <span>{detailAppointment.expert.id}</span>
                          }
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <span className=" fw-bold me-2">
                          User ID:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.user_id
                            ? detailAppointment.expert.user_id
                            : ""}
                        </p>
                      </div>

                      <div className="d-flex mb-3">
                        <span className=" fw-bold me-2">
                          User Name:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.user_name
                            ? detailAppointment.expert.user_name
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3 ">
                        <span className="  fw-bold me-2 me-2">
                          Email:
                        </span>
                        {
                          detailAppointment.expert.email
                            ? <a className="mb-0" rel="noreferrer" target="_blank" href={"mailto:" + detailAppointment.expert.email}>{detailAppointment.expert.email}</a>
                            : ""
                        }
                      </div>
                      <div className="d-flex mb-3">
                        <span className=" fw-bold me-2">
                          Name:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.name
                            ? detailAppointment.expert.name
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <span className=" fw-bold me-2">
                          Active:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.is_active
                            ? "Active"
                            : "Inactive"}
                        </p>
                      </div>
                    </Col>
                    <Col xl="4" md="12" className="pt-3">
                      <div className="d-flex mb-3 ">
                        <span className=" fw-bold me-2">
                          Headline:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.title
                            ? detailAppointment.expert.title
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3 ">
                        <span className=" fw-bold me-2">
                          Phone:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.phone
                            ? detailAppointment.expert.phone
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3 ">
                        <span className=" fw-bold me-2">
                          Rating:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.rating
                            ? detailAppointment.expert.rating
                            : "0"}
                        </p>
                      </div>
                      <div className="d-flex mb-3 ">
                        <span className=" fw-bold me-2">
                          Total Rating:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.rating_total
                            ? detailAppointment.expert.rating_total
                            : "0"}
                        </p>
                      </div>
                      <div className="d-flex mb-3 ">
                        <span className=" fw-bold me-2">
                          Stripe Active:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.stripe_active
                            ? "Active"
                            : "Inactive"}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <span className=" fw-bold me-2">
                          Availability:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.availability
                            ? "Active"
                            : "Inactive"}
                        </p>
                      </div>

                    </Col>
                    <Col xl="4" md="0"></Col>
                    <Col xl="8" md="12">
                      <div className="d-flex mb-3 ">
                        <span className=" fw-bold me-2">
                          Bio:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.bio
                            ? detailAppointment.expert.bio
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex mb-3">
                        <span className=" fw-bold me-2">
                          Description:
                        </span>
                        <p className="mb-0">
                          {detailAppointment.expert.description
                            ? detailAppointment.expert.description
                            : ""}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </CardBody>
            </Card>
            : ''}

          {detailAppointment ?
            <Card className="border-card">
              <CardBody>
                <Col xl="12" className="mb-4">
                  <h4 className="text-primary mb-3 text-center">
                    Session Logs
                  </h4>
                  <TableAppointmentLogs
                    appointment_id={detailAppointment.id}
                  />
                </Col>
              </CardBody>
            </Card>
            : ''
          }
        </>
        : ''
      }
    </>
  )
}

Detail.propTypes = {
  detailAppointment: PropTypes.any,
}

export default memo(Detail)
