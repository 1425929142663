import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Card,
  CardBody,
  Modal,
  ModalHeader
} from "reactstrap";
import Select from 'react-select'
import { Link } from "react-router-dom";
import Detail from "components/reports/detail";
import reportService from "../../services/report";
import MetaTags from "react-meta-tags";
import { checkPermission, customUrlParams } from 'helpers/supportFunction';
import moment from "moment";
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

const types = [
  {value: 'expert', label: "Sidekick"},
  {value: 'rating', label: "Rating"}
];

class Report extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDetail: false,
      detailReport: null,
      type: "expert",
      search: '',
      dxStore: this.createDxStore()
    }
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("report", "read")) {
      toast.warning("You do not have permission to manage reports", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.loadData()
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = ""
        if (this.state.type) {
          url_params += `&type=${this.state.type}`;
        } else {
          url_params += `&type=expert`;
          this.setState({
            type: 'expert'
          })
        }
        let params = customUrlParams(loadOptions, url_params, 'user_id', 'desc')
        var response = await reportService.getAll(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  formatCreatedAt = (e) => {
    let report = e.data
    return (
      <span
      >
        { moment(report.created_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)}
      </span>
    )
  }

  customId = (e) => {
    let report = e.data
    return (
      <Link to="#"
        onClick={async () => {
          this.setState({
            detailReport: report,
          }, () => 
            this.setState({ isDetail: true })
          )
        }}
      >
        {report.id}
      </Link>
    )
  }

  customType = (e) => {
    let report = e.data
    return (
      <>
        { report.ref_type == 'expert' ? 'sidekick' : report.ref_type }
      </>
    )
  }

  onClose = () => {
    this.setState({
      detailReport: null,
      isDetail: false
    })
  }

  linkEdit = (e) => {
    let report = e.data
    return (
      <Button
        onClick={async () => {
          this.setState({
            detailReport: report,
          }, () => 
            this.setState({ isDetail: true })
          )
        }}
      >
        View Detail
      </Button>
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>User Report | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col sm="12" className="d-flex align-items-end pb-2">
                    <h4 className="mb-0 text-primary">User Report</h4>
                  </Col>
                  <Col xl="3" className="pt-2 pb-2">
                    <Select
                      classNamePrefix="select"
                      defaultValue={this.state.type}
                      value={ types.find((item) => {return item.value == this.state.type}) }
                      isClearable={false}
                      isSearchable={false}
                      options={types}  
                      onChange={e => {
                        this.setState({
                          type: e.value
                        }, () => this.loadData())
                      }}
                      placeholder="Select Type"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row className="pt-1">
                  <Col xl="12">
                    <DataGrid
                      dataSource={this.state.dxStore}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                    >
                      <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80} cellRender={this.customId}></Column>
                      <Column dataField="ref_type" name="ref_type" caption="Type" cellRender={this.customType}></Column>
                      <Column dataField="user.user_name" name="user.user_name" caption="User" allowSorting={false}></Column>
                      <Column dataField="reason" name="reason" caption="Reason"></Column>
                      <Column dataField="created_at" name="created_at" caption="Created At" cellRender={this.formatCreatedAt}></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'} width={120}></Column>

                      <Paging defaultPageSize={10} />
                      <Pager
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        allowedPageSizes={[10, 25, 50, 100]}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Modal
              role="dialog"
              size="xl"
              isOpen={this.state.isDetail}
              autoFocus={true}
              centered
              data-toggle="modal"
              toggle={() => this.onClose()}
            >
              <div>
                <ModalHeader
                  className="border-bottom-0"
                  toggle={() => this.onClose()}
                ></ModalHeader>
              </div>
              <div className="modal-body" style={{ paddingTop: "0" }}>
                <div className=" mb-4">
                  <Row className=" justify-content-center">
                    <Detail
                      detailReport={this.state.detailReport}
                    />
                  </Row>
                </div>
              </div>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Report
