import React, { Component }  from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom";
import { Row, Col, Card, CardBody, Input, Label, FormFeedback, Button} from "reactstrap"
import { keypressPrice, isNotEmpty } from 'helpers/supportFunction';
import expertService from "services/expert"
import settingService from "../../services/setting";
import toast from 'helpers/toast';
import Switch from "react-switch";

class EditUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      isSubmit: false,
      messageError: null,
      waiting: false,
      max_commission_rate: parseFloat(process.env.REACT_APP_MAX_COMMISSION_RATE ?? 20),
    }
  }

  componentDidMount = async () => {
    let response = await settingService.getAll();
    response = response.data.filter(item => {return item.key == 'commission_rate'});
    if (response.length > 0) {
      this.setState({
        max_commission_rate: parseFloat(response[0].value)
      })
    }
  }

  handleInput = (type, value) => {
    let data = this.state.data;
    if (typeof data[type] != "undefined") {
      data[type] = value;
      this.setState({data: data});
    }
  }

  onValidateForm = () => {
    return this.state.data.price && isNotEmpty(this.state.data.price) && parseFloat(this.state.data.price) >= 1 && (this.state.data.commission && isNotEmpty(this.state.data.commission))
  }

  onClickSubmit = async () => {
    this.setState({isSubmit: true});
    if (this.onValidateForm()) {
      if (this.state.data.commission && (parseFloat(this.state.data.commission) > this.state.max_commission_rate || parseFloat(this.state.data.commission) < 0)) {
        toast.warning('The commission rate must be greater than 0 and less than or equal to ' + this.state.max_commission_rate, 'Data Invalid')
      } else {
        this.setState({waiting: true});
        let dataForm = {
          expert_id: this.state.data.id,
          availability: this.state.data.availability ? 1 : 0,
          price: this.state.data.price ? parseFloat(this.state.data.price).toFixed(2) : '0',
          commission: this.state.data.commission ? parseFloat(this.state.data.commission) : '0',
          // minimum_charge: this.state.data.minimum_charge ? parseFloat(this.state.data.minimum_charge) : '0',
          title: this.state.data.title ? this.state.data.title : '',
          description: this.state.data.description ? this.state.data.description : '',
          ambassador_id: this.state.data.ambassador_id ? this.state.data.ambassador_id : '',
        };
        let response = await expertService.updateExpert(dataForm)
        if (response.success) {
          toast.success("Update sidekick success");
          this.setState({waiting: false}, () => {
            this.onCancel();
          });
        } else {
          this.setState({
            messageError: response.error ? response.error : 'Update sidekick failed',
            waiting: false
          });
        }
      }
    }
  }

  onCancel = () => {
    if (this.props.history.location.pathname.includes('sidekicks-request')) {
      this.props.history.push('/sidekicks-request')
    } else {
      this.props.history.push('/sidekicks')
    }
  }

  render() {
    return (
      <>
        <Card className="border-card">
          <CardBody>
            <Row>
              <Col xl="4" className="mb-3">
                <Label for="price" className="required">Price</Label>
                <div className="input-group rounded bg-light">
                  <Input
                    type="number"
                    id="price"
                    name="price"
                    min="1"
                    value={this.state.data.price}
                    onChange={e => {
                      this.handleInput("price", e.target.value)
                    }}
                    onKeyPress={(e) => keypressPrice(e)}
                    invalid={
                      this.state.isSubmit && (!isNotEmpty(this.state.data.price) || (this.state.data.price && parseFloat(this.state.data.price) < 1))
                    }
                  />
                </div>
                {
                 this.state.isSubmit && !isNotEmpty(this.state.data.price) ? 
                    <FormFeedback type="invalid" className="invalid-inline">
                      Price is required
                    </FormFeedback>
                  : ''
                }
                {
                 this.state.isSubmit && this.state.data.price && parseFloat(this.state.data.price) < 1 ? 
                    <FormFeedback type="invalid" className="invalid-inline">
                      The price must be greater than or equal 1
                    </FormFeedback>
                  : ''
                }
              </Col>
              <Col xl="4" className="mb-3">
                <Label for="commission_rate" className="required">Commission Rate</Label>
                <div className="input-group rounded bg-light">
                  <Input
                    type="number"
                    id="commission_rate"
                    name="commission_rate"
                    min="0"
                    value={this.state.data.commission}
                    onChange={e => {
                      this.handleInput("commission", e.target.value)
                    }}
                    onKeyPress={e => { keypressPrice(e)}}
                    invalid={
                      this.state.isSubmit && 
                      (!isNotEmpty(this.state.data.commission) || (this.state.data.commission 
                        && (parseFloat(this.state.data.commission) > this.state.max_commission_rate || parseFloat(this.state.data.commission) < 0)))
                    }
                  />
                </div>
                {
                  this.state.isSubmit && !isNotEmpty(this.state.data.commission) ? 
                    <FormFeedback type="invalid" className="invalid-inline">
                      Commission rate is required
                    </FormFeedback>
                  : ''
                }
                {
                  this.state.isSubmit && isNotEmpty(this.state.data.commission) && 
                  (parseFloat(this.state.data.commission) > this.state.max_commission_rate || parseFloat(this.state.data.commission) < 0) ? 
                    <FormFeedback type="invalid" className="invalid-inline">
                      Commission rate must be greater than 0 and less than or equal to {this.state.max_commission_rate}
                    </FormFeedback>
                  : ''
                }
              </Col>
              {/* <Col xl="4" className="mb-3">
                <Label for="minimum_charge">Minimum Charge</Label>
                <div className="input-group rounded bg-light">
                  <Input
                    type="number"
                    id="minimum_charge"
                    name="minimum_charge"
                    min="0"
                    value={this.state.data.minimum_charge}
                    onChange={e => {
                      this.handleInput("minimum_charge", e.target.value)
                    }}
                    onKeyPress={e => { keypressPrice(e)}}
                  />
                </div>
              </Col> */}
              <Col xl="12" className="mb-3">
                <Label for="title">Headline</Label>
                <div className="input-group rounded bg-light">
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    value={this.state.data.title}
                    onChange={e => {
                      this.handleInput("title", e.target.value)
                    }}
                  />
                </div>
              </Col>
              <Col xl="12" className="mb-3">
                <Label for="description">Description</Label>
                <div className="input-group rounded bg-light">
                  <Input
                    type="textarea"
                    id="description"
                    name="description"
                    value={this.state.data.description}
                    onChange={e => {
                      this.handleInput("description", e.target.value)
                    }}
                  />
                </div>
              </Col>
              <Col className="mb-3" xl="12">
                <label className="d-flex align-item-center">
                  <Switch 
                    onChange={e => {this.handleInput("availability", e)}}
                    checked={this.state.data.availability ? true : false}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={24}
                    width={50}
                  />
                  <span className="mx-2 cursor-pointer" onClick={e => {this.handleInput("availability", !this.state.data.availability )}}>Availability</span>
                </label>
              </Col>
            </Row>
            { this.state.messageError ? 
              <Row className="pb-2 text-danger">
                <Col xl="12">
                  {this.state.messageError}
                </Col>
              </Row>
            : ''}
            <Row>
              <Col xl="12">
                <Button
                  className="btn-custom-width"
                  color="secondary"
                  onClick={() => { this.onCancel() }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-custom-width mx-2"
                  disabled={this.state.waiting}
                  color="primary"
                  onClick={() => this.onClickSubmit()}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    )
  }
}

EditUser.propTypes = {
  data: PropTypes.any,
  history: PropTypes.any,
}

export default withRouter(EditUser);
