import axios from '../services/axios-service';

async function getAll(body) {
  let data = await axios.get(`/admin/category?${body ?? ''}`);
  return data
}

async function createCategory(body) {
  var urlencoded = new URLSearchParams(body);
  let data = await axios.post("/admin/category/create", urlencoded);
  return data
}

async function deleteCategory(body) {
  var urlencoded = new URLSearchParams({ category_id: body })
  let data = await axios.post("/admin/category/delete", urlencoded);
  return data;
}

async function forceDeleteCategory(body) {
  var urlencoded = new URLSearchParams({ category_id: body })
  let data = await axios.post("/admin/category/forceDelete", urlencoded);
  return data;
}

async function updateCategory(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post("/admin/category/update", urlencoded);
  return data;
}

async function getDetailCategory(body) {
  let url = `/admin/category/detail?category_id=${body}`;
  let data = await axios.get(url);
  return data
}

export default {
  getAll: getAll,
  createCategory: createCategory,
  deleteCategory: deleteCategory,
  forceDeleteCategory: forceDeleteCategory,
  getDetailCategory: getDetailCategory,
  updateCategory: updateCategory,
}
