import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom";
import { checkPermission, capitalizeFirstLetter, formatAmountUs } from 'helpers/supportFunction';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import moment from "moment";

class SideKickAmbassadorDetail extends Component {
  constructor(props) {
    super(props)
    let { dataDetail } = props;
    this.state = {
      dataDetail: dataDetail
    }
  }

  customUserName = (e) => {
    let data = e.data;
    return (
      checkPermission("ambassador", "read") ?
        <>
          <Link to={`/users/${data.id}/detail`}>
            {data.user_name}
          </Link>
        </> :
        <span> {data.user_name} </span>
    )
  }

  customName = (e) => {
    let data = e.data;
    return (
      checkPermission("ambassador", "read") ?
        <>
          <Link to={`/users/${data.id}/detail`}>
            {data.name}
          </Link>
        </> :
        <span> {data.name} </span>
    )
  }

  customStatus = (e) => {
    let data = e.data;
    return (
      <span> {capitalizeFirstLetter(data.status)} </span>
    )
  }

  formatAmount = (e) => {
    let data = e.data
    return (
      <div className="text-right">
        {data ? formatAmountUs(data.price) : ''}
      </div>
    )
  }

  render() {
    return (
      <>
        {this.state.dataDetail && typeof this.state.dataDetail.id != "undefined" ?
          <>
            <Card className="border-card">
              <CardBody>
                <Row>
                  {
                    this.state.dataDetail.user_id ? (
                      <Col xl="4" xs="4" md="6">
                        <div className="d-flex mb-3">
                          <label className=" fw-bold me-2">User ID: </label>
                          {checkPermission('user', 'read')
                            ? <Link to={`/users/${this.state.dataDetail.user_id}/detail`} className="mb-0">{this.state.dataDetail.user_id}</Link>
                            : this.state.dataDetail.user_id}
                        </div>
                      </Col>
                    ) : ''
                  }

                  <Col xl="4" xs="4" md="6">
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Name: </label>
                      <p className="mb-0">
                        {this.state.dataDetail.name
                          ? this.state.dataDetail.name
                          : ""}
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" xs="4" md="6">
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Email: </label>
                      {
                        this.state.dataDetail.email
                          ? <a className="mb-0" rel="noreferrer" target="_blank" href={"mailto:" + this.state.dataDetail.email}>{this.state.dataDetail.email}</a>
                          : ""
                      }
                    </div>
                  </Col>
                  <Col xl="4" xs="4" md="6">
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Status: </label>
                      <p className="mb-0">
                        {this.state.dataDetail.status
                          ? capitalizeFirstLetter(this.state.dataDetail.status)
                          : ""}
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" xs="4" md="6">
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Started At: </label>
                      <p className="mb-0">
                        {this.state.dataDetail.started_at ? moment(this.state.dataDetail.started_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''}
                      </p>
                    </div>
                  </Col>
                  <Col xl="4" xs="4" md="6">
                    <div className="d-flex mb-3">
                      <label className=" fw-bold me-2">Ended At: </label>
                      <p className="mb-0">
                        {this.state.dataDetail.ended_at ? moment(this.state.dataDetail.ended_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''}
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row>
              <Col xl="12" md="12">
                <Card className="border-card height-100">
                  <CardBody className="pb-0">
                    <h4 className="text-primary text-center mb-3">Sidekicks</h4>
                    {this.state.dataDetail.experts ? (
                      <DataGrid
                        id="dataGridContact"
                        dataSource={this.state.dataDetail.experts}
                        showBorders={true}
                        remoteOperations={true}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        hoverStateEnabled={true}
                      >
                        <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80}></Column>
                        <Column dataField="user_name" name="user_name" caption="User Name" cellRender={this.customUserName} alignment={'left'}></Column>
                        <Column dataField="name" name="name" caption="Full Name" cellRender={this.customName}></Column>
                        <Column dataField="email" name="email" caption="Email"></Column>
                        <Column dataField="status" name="status" caption="Status" cellRender={this.customStatus}></Column>
                        <Column dataField="price" name="price" caption="Price" cellRender={this.formatAmount}></Column>

                        <Paging defaultPageSize={10} />
                        <Pager
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                          allowedPageSizes={[10, 25, 50, 100]}
                        />
                      </DataGrid>
                    ) : (
                      <></>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
          :
          ''}
      </>
    )
  }
}

SideKickAmbassadorDetail.propTypes = {
  dataDetail: PropTypes.any
}

export default SideKickAmbassadorDetail
