import axios from './axios-service';

async function getData() {
  let data = await axios.get(`/admin/dashboard`);
  return data
}

export default {
  getData: getData
}
