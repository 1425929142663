import axios from './axios-service';

async function getAll(body) {
  let data = await axios.get(`/admin/expert-featured?${body ?? ''}`);
  return data
}

async function storeExpertFeatured(formData) {
  let data = await axios.post("/admin/expert-featured/create", formData);
  return data
}
async function updateExpertFeatured(formData) {
  let data = await axios.post("/admin/expert-featured/update", formData);
  return data
}
async function deleteExpertFeatured(formData) {
  var urlencoded = new URLSearchParams(formData);
  let data = await axios.post("/admin/expert-featured/delete", urlencoded);
  return data
}

async function detailExpertFeatured(expert_featured_id) {
  let data = await axios.get(`/admin/expert-featured/detail?expert_featured_id=${expert_featured_id}`);
  return data
}

async function updateReorder(formData) {
  let data = await axios.post(`/admin/expert-featured/re-order`, formData);
  return data
}

export default {
  getAll: getAll,
  storeExpertFeatured: storeExpertFeatured,
  updateExpertFeatured: updateExpertFeatured,
  deleteExpertFeatured: deleteExpertFeatured,
  detailExpertFeatured: detailExpertFeatured,
  updateReorder: updateReorder
}
