import React, { Component }  from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Input, Label, FormFeedback, Button, textA} from "reactstrap"
import { isInvalidLength } from 'helpers/supportFunction';
import userService from "services/user"
import toast from 'helpers/toast';

class EditUser extends Component {
  constructor(props) {
    super(props)
    this.goto = React.createRef();
    this.state = {
      data: this.props.data,
      isSubmit: false,
      messageError: null,
      waiting: false
    }
  }

  handleInput = (type, value) => {
    let data = this.state.data;
    if (typeof data[type] != "undefined") {
      data[type] = value;
      this.setState({data: data});
    }
  }

  onValidateForm = () => {
    return this.state.data.name && this.state.data.user_name && (this.state.data.user_name && !isInvalidLength(this.state.data.user_name))
  }

  onClickSubmit = async () => {
    this.setState({isSubmit: true});
    if (this.onValidateForm()) {
      this.setState({waiting: true});
      let dataForm = {
        user_id: this.state.data.id,
        name: this.state.data.name,
        user_name: this.state.data.user_name,
        bio: this.state.data.bio
      };
      let response = await userService.updateUser(dataForm)
      if (response.success) {
        toast.success("Update user success");
        this.setState({waiting: false}, () => {
          this.goto.current.click()
        });
      } else {
        this.setState({
          messageError: response.error ? response.error : 'Update user failed',
          waiting: false
        });
      }
    }
  }

  render() {
    return (
      <>
        <Card className="border-card">
          <CardBody>
            <Row>
              <Col xl="6">
                <div className="mb-3">
                  <Label for="name" className="required">Name</Label>
                  <div className="input-group rounded bg-light">
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={this.state.data.name}
                      onChange={e => {
                        this.handleInput("name", e.target.value)
                      }}
                      invalid={
                        this.state.isSubmit && !this.state.data.name
                      }
                    />
                  </div>
                  {
                    this.state.isSubmit &&
                    !this.state.data.name ? 
                      <FormFeedback type="invalid" className="invalid-inline">
                        Name is required
                      </FormFeedback>
                    : ''
                  }
                </div>
              </Col>
              <Col xl="6">
                <div className="mb-3">
                  <Label for="username" className="required">User Name</Label>
                  <div className="input-group rounded bg-light">
                    <Input
                      type="text"
                      id="username"
                      name="username"
                      placeholder="User Name"
                      value={this.state.data.user_name}
                      onChange={e => {
                        this.handleInput("user_name", e.target.value)
                      }}
                      invalid={
                        this.state.isSubmit && (!this.state.data.user_name
                        || (this.state.data.user_name && isInvalidLength(this.state.data.user_name)))
                      }
                    />
                  </div>
                  {
                    this.state.isSubmit &&
                    !this.state.data.user_name ? 
                      <FormFeedback type="invalid" className="invalid-inline">
                        User Name is required
                      </FormFeedback>
                    : ''
                  }
                  {
                    this.state.isSubmit && this.state.data.user_name && isInvalidLength(this.state.data.user_name) ? 
                      <FormFeedback type="invalid" className="invalid-inline">
                        User name character length must be greater than or equal to 4 and less than or equal to 30
                      </FormFeedback>
                    : ''
                  }
                </div>
              </Col>

              <Col xl="12" className="mb-3">
                <Label for="bio">Bio</Label>
                <div className="input-group rounded bg-light">
                  <Input
                    type="textarea"
                    id="bio"
                    name="bio"
                    value={this.state.data.bio}
                    onChange={e => {
                      this.handleInput("bio", e.target.value)
                    }}
                  />
                </div>
              </Col>
            </Row>
            { this.state.messageError ? 
              <Row className="pb-2 text-danger">
                <Col xl="12">
                  {this.state.messageError}
                </Col>
              </Row>
            : ''}
            <Row>
              <Col xl="12">
                <Link ref={this.goto} to={`/users`} className="mb-0">
                  <Button
                    className="btn-custom-width"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  className="btn-custom-width mx-2"
                  disabled={this.state.waiting}
                  color="primary"
                  onClick={() => this.onClickSubmit()}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    )
  }
}

EditUser.propTypes = {
  data: PropTypes.any,
}

export default EditUser;
