import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  Label,
  FormFeedback
} from "reactstrap";
import { Link } from "react-router-dom";
import adminService from "../../services/admin";
import MetaTags from "react-meta-tags";
import { checkPermission } from 'helpers/supportFunction';
import toast from 'helpers/toast';
import moment from "moment"

class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentAdmin: null,
      detailAdmin: null,
      isUpdate: false,
      isSubmit: false,
      isChangePassword: false,
      dataUpdatePassword: {
        old_password: '',
        password: '',
        confirm_password: '',
      },
      messageError: '',
    }
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("account", "read")) {
      toast.warning("You do not have permission to manage admins", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      const currentAdmin = localStorage.getItem("userSidekick") ? JSON.parse(localStorage.getItem("userSidekick")) : null;
      if (currentAdmin && currentAdmin.id) {
        this.setState({
          currentAdmin: currentAdmin
        }, () =>
          this.loadData()
        )
      }
    }
  }

  onClose = () => {
    this.setState({
      isUpdate: false,
      isChangePassword: false,
      isSubmit: false,
      detailAdmin: null
    })
  }

  loadData = async () => {
    var response = await adminService.getDetailAdmin(this.state.currentAdmin.id);
    if (response && response.success) {
      this.setState({
        currentAdmin: response.data,
      })
      let currentAdmin = localStorage.getItem("userSidekick") ? JSON.parse(localStorage.getItem("userSidekick")) : null;
      if (currentAdmin && currentAdmin.id) {
        currentAdmin.email = response.data.email;
        localStorage.setItem("userSidekick",  JSON.stringify(currentAdmin));
      }
    }
  }

  onCheckSubmitAdmin = () => {
    return this.state.currentAdmin && this.state.currentAdmin.email;
  }

  handleInput = (type, value) => {
    let detail = { ...this.state.detailAdmin }
    detail[[type]] = value
    this.setState({ detailAdmin: detail })
  }

  handleInputChangePassword = (type, value) => {
    let data = { ...this.state.dataUpdatePassword }
    data[[type]] = value
    this.setState({ dataUpdatePassword: data })
  }

  onClickUpdate = async admin => {
    this.setState({
      isSubmit: true
    });
    if (this.onCheckSubmitAdmin()) {
      let response = await adminService.updateAdmin({
        admin_id: admin.id,
        role_id: admin.role_id,
        status: admin.status,
        email: admin.email
      })
      if (response.success) {
        toast.success("Update profile success");
        this.onClose();
        this.loadData();
      }
    }
  }

  onCheckChangePassword = () => {
    return this.state.dataUpdatePassword && this.state.dataUpdatePassword.password && this.state.dataUpdatePassword.password.length >=8
          && !(this.state.dataUpdatePassword.password.startsWith(' ') || this.state.dataUpdatePassword.password.endsWith(' '))
          && this.state.dataUpdatePassword.confirm_password && this.state.dataUpdatePassword.password == this.state.dataUpdatePassword.confirm_password;
  }

  onClickUpdatePassword = async data => {
    this.setState({isSubmit: true});
    if (this.onCheckChangePassword()) {
      let response = await adminService.changePassword({
        old_password: data.old_password,
        password: data.password,
      })
      if (response.success) {
        toast.success("Change password success");
        this.onClose();
        this.setState({
          dataUpdatePassword: {
            old_password: '',
            password: '',
            confirm_password: '',
          }
        })
      } else {
        this.setState({
          messageError: response.error ? response.error : 'Change password failed'
        });
      }
    }
  }

  isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Profile Admin | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="6" className="d-flex align-items-center mb-3">
                    <h4 className="mb-0 me-3">Profile Admin</h4>
                  </Col>
                  <Col xl="6" className="text-right">
                    <Link to="/admins">
                      <Button className="btn-custom-width"> Back </Button>
                    </Link>
                  </Col>
                </Row>
                <Row className="pt-2">
                  { this.state.currentAdmin ?
                    <>
                    <Col xl="6" className="mb-2">
                      <Row>
                        <Col xl="6">
                          <div className="d-flex mb-3">
                            <label className=" fw-bold me-2">
                              ID: 
                            </label>
                            <p className="mb-0">
                              {this.state.currentAdmin.id
                                ? this.state.currentAdmin.id
                                : ""}
                            </p>
                          </div>
                          <div className="d-flex mb-3">
                            <label className=" fw-bold me-2">
                              Email: 
                            </label>
                            <span className="me-2"> {this.state.currentAdmin.email
                              ? this.state.currentAdmin.email
                              : ""}</span>
                            <a 
                              href="#"
                              title="Edit Email"
                              alt="Edit Email"
                              onClick={() =>
                                this.setState({
                                  isUpdate: true,
                                  detailAdmin: this.state.currentAdmin
                                })
                              }
                            >
                              <i className="bx bx-edit font-size-20"></i>
                            </a>
                          </div>
                          <div className="d-flex mb-3">
                            <label className=" fw-bold me-2">
                              Status: 
                            </label>
                            <p className="mb-0">
                              {this.state.currentAdmin.status
                                ? this.state.currentAdmin.status
                                : ""}
                            </p>
                          </div>
                        </Col>
                        <Col xl="6">
                          <div className="d-flex mb-3">
                            <label className=" fw-bold me-2">
                              Role: 
                            </label>
                            <p className="mb-0">
                              {this.state.currentAdmin.role_name
                                ? this.state.currentAdmin.role_name
                                : ""}
                            </p>
                          </div>
                          <div className="d-flex mb-3">
                            <label className=" fw-bold me-2">
                              Created At:
                            </label>
                            <p className="mb-0">
                              {this.state.currentAdmin.created_at
                                ? moment(this.state.currentAdmin.created_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                                : ""}
                            </p>
                          </div>
                          <div className="d-flex mb-3">
                            <label className=" fw-bold me-2">
                              Updated At:
                            </label>
                            <p className="mb-0">
                              {this.state.currentAdmin.updated_at
                                ? moment(this.state.currentAdmin.updated_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)
                                : ""}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    {checkPermission("account", "update") ?
                      <Row>
                        <Button
                            className="mx-1 btn-change-password"
                            color="primary"
                            onClick={() =>
                              this.setState({
                                isChangePassword: true
                              })
                            }
                          >
                          Change Password
                        </Button>
                      </Row>
                    :'' }
                    </>
                  :''}
                </Row>
                <Modal
                  isOpen={this.state.isUpdate}
                  role="dialog"
                  autoFocus={true}
                  centered
                  data-toggle="modal"
                  backdrop={'static'}
                  toggle={() => {
                    this.onClose()
                  }}
                >
                  <div>
                    <ModalHeader
                      className="border-bottom-0"
                      toggle={() => {
                        this.onClose()
                      }}
                    ></ModalHeader>
                  </div>
                  <div className="modal-body">
                    <div className="mb-4">
                      <div className="row justify-content-center">
                        {this.state.detailAdmin && this.state.detailAdmin.id ?
                          <Col xl="11">
                            <h4 className="text-primary mb-3 text-center">
                              Change Email
                            </h4>
                            <Row>
                              <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                                <Label for="email" style={{"paddingLeft": "0"}}>Email</Label>
                                <div className="input-group rounded bg-light">
                                  <Input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control bg-transparent border-0"
                                    placeholder="Email"
                                    value={this.state.detailAdmin.email}
                                    onChange={e => {
                                      this.handleInput("email", e.target.value)
                                    }}
                                    invalid={
                                      this.state.isSubmit &&
                                      (!this.state.detailAdmin.email || this.state.detailAdmin.email && !this.isValidEmail(this.state.detailAdmin.email))
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                                {
                                  this.state.isSubmit &&
                                  !this.state.detailAdmin.email ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      Email is required
                                    </FormFeedback>
                                  : ''
                                }
                                {
                                  this.state.isSubmit && this.state.detailAdmin.email && !this.isValidEmail(this.state.detailAdmin.email) ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      Email is invalid
                                    </FormFeedback>
                                  : ''
                                }
                              </Col>
                            </Row>
                            <Button
                              className="mt-3  d-block mx-auto"
                              color="primary"
                              type="button"
                              id="button-update"
                              onClick={() => {
                                this.onClickUpdate(this.state.detailAdmin)
                              }}
                            >
                              Update
                            </Button>
                          </Col>
                          : ''
                        }
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal
                  isOpen={this.state.isChangePassword}
                  role="dialog"
                  autoFocus={true}
                  centered
                  data-toggle="modal"
                  backdrop={'static'}
                  toggle={() => {
                    this.onClose()
                  }}
                >
                  <div>
                    <ModalHeader
                      className="border-bottom-0"
                      toggle={() => {
                        this.onClose()
                      }}
                    ></ModalHeader>
                  </div>
                  <div className="modal-body">
                    <div className="mb-4">
                      <div className="row justify-content-center">
                        {this.state.currentAdmin && this.state.currentAdmin.id && this.state.isChangePassword ?
                          <Col xl="11">
                            <h4 className="text-primary mb-3 text-center">
                              Change Password
                            </h4>
                            <Row>
                              <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                                <Label for="old_password" style={{"paddingLeft": "0"}}>Old Password</Label>
                                <div className="input-group rounded bg-light">
                                  <Input
                                    type="password"
                                    id="old_password"
                                    name="old_password"
                                    className="form-control bg-transparent border-0"
                                    placeholder="Old Password"
                                    value={this.state.dataUpdatePassword.old_password}
                                    onChange={e => {
                                      this.handleInputChangePassword("old_password", e.target.value)
                                    }}
                                    invalid={
                                      this.state.isSubmit && !this.state.dataUpdatePassword.old_password ? true : false
                                    }
                                  />
                                </div>
                                {
                                  this.state.isSubmit &&
                                  !this.state.dataUpdatePassword.old_password ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      Old Password is required
                                    </FormFeedback>
                                  : ''
                                }
                              </Col>
                              <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                                <Label for="password" style={{"paddingLeft": "0"}}>New Password</Label>
                                <div className="input-group rounded bg-light">
                                  <Input
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="form-control bg-transparent border-0"
                                    placeholder="New Password"
                                    value={this.state.dataUpdatePassword.password}
                                    onChange={e => {
                                      this.handleInputChangePassword("password", e.target.value)
                                    }}
                                    invalid={
                                      this.state.isSubmit && (!this.state.dataUpdatePassword.password || 
                                      (this.state.dataUpdatePassword.password && this.state.dataUpdatePassword.password.length < 8) ||
                                      (this.state.dataUpdatePassword.password.startsWith(' ') || this.state.dataUpdatePassword.password.endsWith(' '))
                                      ) ? true : false
                                    }
                                  />
                                </div>
                                {
                                  this.state.isSubmit &&
                                  !this.state.dataUpdatePassword.password ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      New Password is required
                                    </FormFeedback>
                                  : ''
                                }
                                {
                                  this.state.isSubmit &&
                                  this.state.dataUpdatePassword.password && this.state.dataUpdatePassword.password.length < 8 ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      At least minumum 8 characters
                                    </FormFeedback>
                                  : ''
                                }
                                {
                                  this.state.isSubmit &&
                                  this.state.dataUpdatePassword.password &&
                                   (this.state.dataUpdatePassword.password.startsWith(' ') || this.state.dataUpdatePassword.password.endsWith(' ')) ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      Please don’t begin or end your password with blank space.
                                    </FormFeedback>
                                  : ''
                                }
                              </Col>
                              <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                                <Label for="password" style={{"paddingLeft": "0"}}>Confirm Password</Label>
                                <div className="input-group rounded bg-light">
                                  <Input
                                    type="password"
                                    id="confirm_password"
                                    name="confirm_password"
                                    className="form-control bg-transparent border-0"
                                    placeholder="New Password"
                                    value={this.state.dataUpdatePassword.confirm_password}
                                    onChange={e => {
                                      this.handleInputChangePassword("confirm_password", e.target.value)
                                    }}
                                    invalid={
                                      this.state.isSubmit && (!this.state.dataUpdatePassword.confirm_password || 
                                      (this.state.dataUpdatePassword.confirm_password && this.state.dataUpdatePassword.password && 
                                          this.state.dataUpdatePassword.confirm_password != this.state.dataUpdatePassword.password)
                                      ) ? true : false
                                    }
                                  />
                                </div>
                                {
                                  this.state.isSubmit &&
                                  !this.state.dataUpdatePassword.confirm_password ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      Confirm Password is required
                                    </FormFeedback>
                                  : ''
                                }
                                {
                                  this.state.isSubmit &&
                                  this.state.dataUpdatePassword.confirm_password && this.state.dataUpdatePassword.password && 
                                  this.state.dataUpdatePassword.confirm_password != this.state.dataUpdatePassword.password ? 
                                    <FormFeedback type="invalid" className="invalid-inline">
                                      Password does not match
                                    </FormFeedback>
                                  : ''
                                }
                              </Col>
                            </Row>
                            { this.state.messageError ? 
                               <Row className="pt-2 pb-2 text-danger">
                                {this.state.messageError}
                               </Row>
                            : ''}
                            <Button
                              className="mt-3  d-block mx-auto"
                              color="primary"
                              type="button"
                              id="button-update"
                              onClick={() => {
                                this.onClickUpdatePassword(this.state.dataUpdatePassword)
                              }}
                            >
                              Submit
                            </Button>
                          </Col>
                          : ''
                        }
                      </div>
                    </div>
                  </div>
                </Modal>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UserProfile

