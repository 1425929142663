import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SideKickBar from "./SideKickBar"

import { Link } from "react-router-dom"

import logo from "../../assets/images/logo.svg"
import logo_xl from "../../assets/images/logo_xl.svg"

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/dashboard">
            <div className="content-logo-sidebar">
              <img src={logo_xl} alt="Sidekick" className="logo-sidekick-xl"/>
            </div>
          </Link>
          <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="Sidekick" height="25" className="logo-sidekick"/>
            </span>
            <span className="logo-lg">
              <img src={logo} alt="Sidekick" height="25" className="logo-sidekick"/>
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SideKickBar /> : <SideKickBar />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
