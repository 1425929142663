import PropTypes from "prop-types"
import React from "react"

import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { Routes } from "./routes"
import { checkExitsRoute } from "helpers/supportFunction"

// Import all middleware
import AuthMiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import Pages404 from "pages/PageNotFound"

// Import scss
import "./assets/scss/theme.scss"

import { name, version } from "../package.json";

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  window.version = name + ": " + version;

  const Layout = getLayout()
  return (
    <React.Fragment>
      {
        checkExitsRoute(Routes) ?
          <Router>
            <Switch>
              {Routes.map((route, idx) => (
                <AuthMiddleware
                  route={route}
                  path={route.path}
                  layout={route.layout ? Layout : NonAuthLayout}
                  isPublicRoute={!route.layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={route.permissions ? true : false}
                  exact
                />
              ))}
            </Switch>
          </Router>
          : <Route component={Pages404}></Route>
      }
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
