import axios from '../services/axios-service';

async function getAll(body) {
  let data = await axios.get(`/admin/payment/?${body ?? ''}`);
  return data
}

async function getDetailPayment(body) {
  let url = `/admin/payment/detail?payment_id=${body ?? ''}`;
  let data = await axios.get(url);
  return data
}

export default {
  getAll: getAll,
  getDetailPayment: getDetailPayment,
}
