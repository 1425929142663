import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  FormFeedback,
  Label,
  Modal,
  ModalHeader
 } from "reactstrap";
import Select from 'react-select'
import { Link } from "react-router-dom";
import expertAmbassadorService from "../../services/ambassador";
import userService from "../../services/user"
import { checkPermission, customUrlParams, capitalizeFirstLetter, isValidEmail } from 'helpers/supportFunction';
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import moment from "moment";
import Switch from "react-switch";

const STATUS_ARRAY = [{value: 'active', label: 'Active'}, {value: 'inactive', label: 'Inactive'}];

class ExpertAmbassadorDatatable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userOptions: [],
      isSubmit: false,
      isAdding: false,
      isEditing: false,
      waiting: false,
      currentSearch: {
        search: '',
      },
      currentData: { },
      dxStore: this.createDxStore(),
      is_company_ambassador: false
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore});
  }

  createDxStore () {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = '';
        if (this.state.currentSearch) {
          if (this.state.currentSearch.search) {
            url_params += `&search=${this.state.currentSearch.search}`;
          }
        }
        let params = customUrlParams(loadOptions, url_params, 'name', 'asc')
        var response = await expertAmbassadorService.getAll(params);
        if(!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  componentDidMount = async () => {
    if (localStorage.getItem('userSidekick') && !checkPermission("ambassador", "read")) {
      toast.warning("You do not have permission to manage ambassadors", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.setState({ waiting: true });
      await this.loadData();
      let users = [];
      var response = await userService.getAll('&limit=-1&offset=0');
      if(response.success) {
        users = response.data.map(item => { item.value = item.user_id; item.label = item.name; return item  });
      }
      this.setState({userOptions: users, waiting: false});
    }
  }

  customUserId = (e) => {
    let data = e.data;
    return (
      checkPermission("ambassador", "read") ? 
        <>
          <Link to={`/users/${data.user_id}/detail`}>
            { data.user_id }
          </Link>
        </> :
        <span> {data.user_id} </span>
    )
  }

  customName = (e) => {
    let data = e.data;
    return (
      checkPermission("ambassador", "read") ? 
        <>
          <Link to={data.user_id ? `/users/${data.user_id}/detail` : `sidekick-ambassadors/${data.id}/detail`}>
            { data.name }
          </Link>
        </> :
        <span> {data.name} </span>
    )
  }

  customStatus = (e) => {
    let data = e.data;
    return (
      <span> { capitalizeFirstLetter(data.status)} </span>
    )
  }

  formatStartAt = (e) => {
    let data = e.data
    return (
      <span>
        { data.started_at ? moment(data.started_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''}
      </span>
    )
  }

  formatEndAt = (e) => {
    let data = e.data
    return (
      <span>
        { data.ended_at ? moment(data.ended_at).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT) : ''}
      </span>
    )
  }

  linkEdit = (e) => {
    let expertAmbassador = e.data;
    return (
      <>
      <Link to={`/sidekick-ambassadors/${expertAmbassador.id}/detail`}>
        <Button className="mx-1 btn-custom-width">
          View Detail
        </Button>
      </Link>
      { checkPermission("ambassador", "update") ?
        <>
          <Button
            className="mx-1 btn-custom-width"
            color="primary"
            onClick={() => {
              this.setState({
                isEditing: true,
                currentData: {
                  id: expertAmbassador.id,
                  status: expertAmbassador.status
                },
                messageError: ''
              })
            }}  
          >
            Update
          </Button>
        </>
        : ''
      }
      </>
    )
  }

  handleInputSearchData = (type, value) => {
    let currentSearch = { ...this.state.currentSearch }
    currentSearch[[type]] = value
    this.setState({ currentSearch: currentSearch }, () => {
      this.loadData()
    });
  }

  onclose = () => {
    this.setState({
      isAdding: false,
      isEditing: false,
      currentData: { },
      is_company_ambassador: false
    })
  }

  handleInput = (type, value) => {
    let currentData = { ...this.state.currentData }
    currentData[[type]] = value
    this.setState({ currentData: currentData })
  }

  onClickCreate = async () => {
    this.setState({isSubmit: true, messageError: ''});
    if ((this.state.is_company_ambassador && this.state.currentData.name && this.state.currentData.email && isValidEmail(this.state.currentData.email))
      || (!this.state.is_company_ambassador && this.state.currentData.user_id)
    ) {
      let data = {};
      if (this.state.is_company_ambassador) {
        data = {
          name: this.state.currentData.name,
          email: this.state.currentData.email,
        }
      } else {
        data = {user_id: this.state.currentData.user_id }
      }
      this.setState({ waiting: true });
      let response = await expertAmbassadorService.create(data);
      if (response.success) {
        toast.success("Create sidekick ambassador success");
        this.loadData();
        this.onclose();
      } else {
        let error = "Create sidekick ambassador failed";
        if (response.error) {
          error = response.error
        }
        this.setState({messageError: error})
      }
      this.setState({isSubmit: false, waiting: false});
    }
  }

  onClickUpdate = async () => {
    this.setState({isSubmit: true, messageError: ''});
    if (this.state.currentData.status) {
      this.setState({ waiting: true });
      let response = await expertAmbassadorService.update({id: this.state.currentData.id, status: this.state.currentData.status});
      if (response.success) {
        toast.success("Update sidekick ambassador success");
        this.loadData();
        this.onclose();
      } else {
        let error = "Update banner featured failed";
        if (response.error) {
          error = response.error
        }
        this.setState({messageError: error})
      }
      this.setState({isSubmit: false, waiting: false});
    }
  }

  onClickChange = () => {
    this.setState({is_company_ambassador: !this.state.is_company_ambassador});
  }

  render() {
    return (
      <div className={`${this.state.waiting ? 'waitLoadingData' : ''}` }>
        <Row>
          <Col xl="4" className="pt-2 pb-2">
            <Input
              type="search"
              id="search"
              className="form-control"
              placeholder="Search..."
              value={this.state.currentSearch.search}
              onChange={e => {
                this.handleInputSearchData("search", e.target.value)
              }}
            />
          </Col>
          <Col xl="8" className="text-right pt-2 pb-2">
            { checkPermission("ambassador", "create") ?
              <Button
                color="primary"
                onClick={() => {
                  this.setState({
                    isAdding: true,
                    isSubmit: false,
                    currentData: {
                      user_id: "",
                      name: "",
                      email: ""
                    },
                    messageError: '',
                    is_company_ambassador: false
                  })
                }}
              >
                Create New
              </Button>
            :'' }
          </Col>
        </Row>
        <Row className="pt-1">
          <Col xl="12">
            <DataGrid
              dataSource={this.state.dxStore}
              showBorders={true}
              remoteOperations={true}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              hoverStateEnabled={true}
            >
              <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80}></Column>
              <Column dataField="user_id" name="user_id" caption="User ID" cellRender={this.customUserId} alignment={'left'}></Column>
              <Column dataField="name" name="name" caption="Full Name" cellRender={this.customName}></Column>
              <Column dataField="email" name="email" caption="Email"></Column>
              <Column dataField="status" name="status" caption="Status"  cellRender={this.customStatus}></Column>
              <Column dataField="started_at" name="started_at" caption="Started At" cellRender={this.formatStartAt}></Column>
              <Column dataField="ended_at" name="ended_at" caption="Ended at" cellRender={this.formatEndAt}></Column>
              <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'}></Column>
              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
                allowedPageSizes={[10, 25, 50, 100]}
              />
            </DataGrid>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isAdding}
          role="dialog"
          autoFocus={true}
          centered
          data-toggle="modal"
          backdrop='static'
          toggle={() => {
            this.onclose()
          }}
        >
          <div>
            <ModalHeader
              className="border-bottom-0"
              toggle={() => {
                this.onclose()
              }}
            ></ModalHeader>
          </div>
          <div className="modal-body pt-0">
            <div className="text-center mb-4">
              <Row className="justify-content-center">
                {this.state.currentData ? 
                  <Col xl="11">
                    <h4 className="text-primary mb-3">Add New Sidekick Ambassador</h4>
                    <Row className="pt-2">
                      <Col xl="12" style={{textAlign: 'left'}} className="mt-2 mb-4">
                        <Label for="user" style={{"paddingLeft": "0"}}>Choose User</Label>
                        <Select
                          name="user"
                          classNamePrefix="select"
                          defaultValue={this.state.currentData.user_id ? this.state.currentData.user_id : null}
                          value={ this.state.userOptions.find((item) => {return item.value == this.state.currentData?.user_id}) }
                          isClearable={true}
                          isSearchable={true}
                          options={this.state.userOptions}  
                          onChange={e => {this.handleInput("user_id", e ? e.value : null)}}
                          placeholder="Select User"
                          className={this.state.isSubmit && !this.state.is_company_ambassador && !this.state.currentData.user_id ? 'is-invalid' : ''}
                          isDisabled={this.state.is_company_ambassador}
                        />
                          {
                            this.state.isSubmit && !this.state.is_company_ambassador && !this.state.currentData.user_id ? 
                              <FormFeedback type="invalid" className="invalid-inline">
                                User is required
                              </FormFeedback>
                            : ''
                          }
                      </Col>
                    </Row>

                    <label className="d-flex mb-2">
                      <Switch 
                        onChange={e => {this.onClickChange(e)}}
                        checked={this.state.is_company_ambassador ? true : false}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={24}
                        width={50}
                      />
                      <label  className="mx-2 cursor-pointer" onClick={e => {this.onClickChange(e)}}>Is Company Ambassador</label>
                    </label>

                    <Row>
                      <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                        <Label for="user" style={{"paddingLeft": "0"}}>Ambassador Name</Label>
                        <Input
                          type="text"
                          id="name"
                          placeholder="Ambassador Name"
                          defaultValue={this.state.currentData.name}
                          onChange={e => {this.handleInput("name", e.target.value)}}
                          disabled={!this.state.is_company_ambassador}
                          className={'form-control ' + (this.state.isSubmit && this.state.is_company_ambassador && !this.state.currentData.name ? 'is-invalid' : '')}
                        />
                          {
                            this.state.isSubmit && this.state.is_company_ambassador && !this.state.currentData.name ? 
                              <FormFeedback type="invalid" className="invalid-inline">
                                Ambassador name is required
                              </FormFeedback>
                            : ''
                          }
                      </Col>

                      <Col xl="12" style={{textAlign: 'left'}} className="mb-2">
                        <Label for="user" style={{"paddingLeft": "0"}}>Ambassador Email</Label>
                        <Input
                          type="text"
                          id="email"
                          placeholder="Ambassador Email"
                          defaultValue={this.state.currentData.email}
                          onChange={e => {this.handleInput("email", e.target.value)}}
                          disabled={!this.state.is_company_ambassador}
                          className={'form-control ' + ((this.state.isSubmit && this.state.is_company_ambassador && 
                            (!this.state.currentData.email || (this.state.currentData.email && !isValidEmail(this.state.currentData.email))))
                            ? 'is-invalid' : '')}
                        />
                        {
                          this.state.isSubmit && this.state.is_company_ambassador ? (
                            <>
                              {
                                !this.state.currentData.email ? 
                                  <FormFeedback type="invalid" className="invalid-inline">
                                    Ambassador email is required
                                  </FormFeedback>
                                : ''
                              }
                              {
                                this.state.currentData.email && !isValidEmail(this.state.currentData.email) ?
                                  <FormFeedback type="invalid" className="invalid-inline">
                                    Ambassador email is invalid
                                  </FormFeedback>
                                : ''
                              }
                            </>
                          ) : ''
                        }
                      </Col>
                    </Row>
                    
                    { this.state.messageError ? 
                      <Row className="pb-2 text-danger">
                        <Col xl="12">
                          {this.state.messageError}
                        </Col>
                      </Row>
                    : ''}
                    <Button
                      className="mt-3 d-block mx-auto"
                      color="primary"
                      type="button"
                      id="button-create"
                      onClick={() => {
                        this.onClickCreate()
                      }}
                    >
                      Create
                    </Button>
                  </Col>
                : ''
                }
              </Row>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.isEditing}
          role="dialog"
          autoFocus={true}
          centered
          data-toggle="modal"
          backdrop='static'
          toggle={() => {
            this.onclose()
          }}
        >
          <div>
            <ModalHeader
              className="border-bottom-0"
              toggle={() => {
                this.onclose()
              }}
            ></ModalHeader>
          </div>
          <div className="modal-body pt-0">
            <div className="text-center mb-4">
              <Row className=" justify-content-center">
                {this.state.currentData ? 
                  <Col xl="11">
                    <h4 className="text-primary mb-3">Update Sidekick Ambassador</h4>
                    <Row>
                      <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                        <Label for="password" style={{"paddingLeft": "0"}}>Status</Label>
                        
                        <Select
                          name="status"
                          classNamePrefix="select"
                          defaultValue={this.state.currentData.status ? this.state.currentData.status : null}
                          value={ STATUS_ARRAY.find((item) => {return item.value == this.state.currentData?.status}) }
                          isClearable={false}
                          isSearchable={true}
                          options={STATUS_ARRAY}  
                          onChange={e => {this.handleInput("status", e ? e.value : null)}}
                          placeholder="Select Status"
                          className={ this.state.isSubmit && !this.state.currentData.status ? 'is-invalid' : ''}
                        />
                          {
                            this.state.isSubmit && !this.state.currentData.status ? 
                              <FormFeedback type="invalid" className="invalid-inline">
                                Status is required
                              </FormFeedback>
                            : ''
                          }
                      </Col>
                    </Row>
                    { this.state.messageError ? 
                      <Row className="pb-2 text-danger">
                        <Col xl="12">
                          {this.state.messageError}
                        </Col>
                      </Row>
                    : ''}
                    <Button
                      className="mt-3 d-block mx-auto"
                      color="primary"
                      type="button"
                      id="button-create"
                      onClick={() => {
                        this.onClickUpdate()
                      }}
                    >
                      Update
                    </Button>
                  </Col>
                : ''
                }
              </Row>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default ExpertAmbassadorDatatable
