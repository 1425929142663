import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import classname from "classnames"
// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import { checkPermissions, setActiveClassMenu } from 'helpers/supportFunction';
import { menu , menu_admin} from 'helpers/menuBar';
import { useState } from "react"

const SidebarContent = props => {
  const ref = useRef();
  const [refSubMenu, setRefSubMenu] = useState('')

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  function permissions(route) {
    if (route.permissions) {
      return checkPermissions(route.permissions);
    }
    return false;
  }

  function setShowSubmenu (subMenu) {
    let location_split = location.pathname.split("/");
    if (location_split.length > 1) {
      if (refSubMenu == subMenu.name || subMenu.multi_links.includes(location_split[2])) {
        $('.no-sub.mm-active').removeClass('mm-active');
        return true;
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {menu.map((item, index) => (
               permissions(item) || !item.permissions || (item.permissions && item.permissions.length == 0) ?
               item.subMenu ? 
                  <li className={"nav-item dropdown " + setActiveClassMenu(item.path)} key={index} >
                    <a
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={(e) => {
                        refSubMenu != item.subMenu.name ? setRefSubMenu(item.subMenu.name) : setRefSubMenu('')
                      }}
                    >
                      <i className={ 'bx ' + item.icon}></i>
                      <span>{item.name}</span>
                      <div className="arrow-down ml-2"></div>
                    </a>
                    <div
                      className={classname("dropdown-menu", { show: setShowSubmenu(item.subMenu) })}
                    >
                      <ul className="nav-group-items metismenu list-unstyled">
                      {item.subMenu.menu.map((subItem, inx) => (
                        <li
                          className={'nav-item ' + setActiveClassMenu(subItem.path, 2)}
                          key={inx}
                        >
                          <Link to={subItem.path}>
                            <i className={ 'bx ' + subItem.icon}></i>
                            <span>{props.t(subItem.name)}</span>
                          </Link>
                         </li>
                      ))}
                      </ul>
                    </div>
                  </li>
                :
                  <li key={index} className={'no-sub ' + setActiveClassMenu(item.path)}>
                    <Link key={index} to={item.path}>
                      <i className={ 'bx ' + item.icon}></i>
                      <span>{props.t(item.name)}</span>
                    </Link>
                  </li>
                : ''
            ))}

          <hr style={{width: '75%', height: '1.5px', margin: '13px auto'}}/>
            {menu_admin.map((item, index) => (
               permissions(item) || !item.permissions || (item.permissions && item.permissions.length == 0) ?
               <li key={index} className={'no-sub ' + setActiveClassMenu(item.path)}>
                <Link to={item.path}>
                  <i className={ 'bx ' + item.icon}></i>
                  <span>{props.t(item.name)}</span>
                </Link>
              </li> : ''
            ))}
          </ul>
        </div>

      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
