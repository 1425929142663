import React, { Component } from "react";
import {
  Container,
  Row, Col,
  Button,
  Input,
  Card,
  CardBody,
  Label,
  FormFeedback,
  Modal,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown
} from "reactstrap";
import Select from 'react-select'
import { Link } from "react-router-dom";
import reportService from "../../services/report";
import expertService from "../../services/expert";
import expertAmbassadorService from "../../services/ambassador";
import MetaTags from "react-meta-tags";
import ModalConfirm from "components/modals/modalConfirm"
import { checkPermission, customUrlParams } from 'helpers/supportFunction';
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';

const types = [
  { value: '', label: "All" },
  { value: '1', label: "Fake Sidekick" },
  { value: '0', label: "Real Sidekick" }
];

class Expert extends Component {
  constructor(props) {
    super(props)
    this.state = {
      experts: [],
      isDetail: false,
      currentData: null,
      isOpenConfirm: false,
      isAddAmbassador: false,
      actionConfirm: '',
      messageConfirm: '',
      isSubmit: false,
      waiting: false,
      currentSearch: {
        type: '',
        template_account: '',
      },
      dxStore: this.createDxStore()
    }
  }

  loadData() {
    const dxStore = this.createDxStore();
    this.setState({ dxStore: dxStore });
  }

  createDxStore() {
    return new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let url_params = 'is_requesting=0';
        if (this.state.currentSearch) {
          if (this.state.currentSearch.search) {
            url_params += `&search=${this.state.currentSearch.search}`;
          }
          if (this.state.currentSearch.template_account) {
            url_params += `&template_account=${this.state.currentSearch.template_account}`;
          }
        }
        let params = customUrlParams(loadOptions, url_params, 'name', 'asc')
        var response = await expertService.getAll(params);
        if (!response.success) {
          return { data: [], totalCount: 0 };
        } else {
          return { data: response.data, totalCount: response.total_count };
        }
      },
    });
  }

  componentDidMount = async () => {
    if (localStorage.getItem('userSidekick') && !checkPermission("expert", "read")) {
      toast.warning("You do not have permission to manage sidekicks", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.setState({ waiting: true });
      await this.loadData();
      let ambassadors = [];
      var response = await expertAmbassadorService.getAll('status=active&limit=-1&offset=0');
      if (response.success) {
        ambassadors = response.data.map(item => { item.value = item.id; item.label = item.name; return item });
      }
      this.setState({ ambassadorOptions: ambassadors, waiting: false });
    }
  }

  customUser = (e) => {
    let expert = e.data;
    return (
      <Link to={`/sidekicks/${expert.id}/detail`}
      >
        {expert.user_name}
      </Link>
    )
  }

  customStripStatus = (e) => {
    let expert = e.data;
    return (
      <span>
        {expert.stripe_active ? "Active" : 'Inactive'}
      </span>
    )
  }

  customName = (e) => {
    return (
      <Link to={`/sidekicks/${e.data.id}/detail`}>
        {e.data.name}
      </Link>
    )
  }

  customAmbassador = (e) => {
    let data = e.data;
    return (
      checkPermission("ambassador", "read") && data.ambassador_id ?
        <>
          <Link to={`/sidekick-ambassadors/${data.ambassador_id}/detail`}>
            {data.ambassador_name}
          </Link>
        </> :
        <span> {data.ambassador_name} </span>
    )
  }

  linkEdit = (e) => {
    let expert = e.data;
    return (
      <>
        {checkPermission("expert", "read") ?
          <Link to={`/sidekicks/${expert.id}/detail`}>
            <Button className="mx-1 btn-custom-width">
              View Detail
            </Button>
          </Link>
          : ''
        }
        {checkPermission("expert", "update") ?
          <>
            <Link to={`/sidekicks/${expert.id}/edit`}>
              <Button className="mx-1 btn-custom-width" color="primary">
                Update
              </Button>
            </Link>
            <Button
              className="mx-1 btn-custom-width"
              color="danger"
              onClick={() => {
                this.setState({
                  isOpenConfirm: true,
                  actionConfirm: 'UnApprove',
                  currentData: expert,
                  messageConfirm: 'Are you sure want to unapprove for ' + expert.name + '?'
                })
              }}
            >
              UnApprove
            </Button>
            {
              !expert.ambassador_id ?
                <Button
                  className="mx-1"
                  color="success"
                  onClick={() => {
                    this.setState({
                      isAddAmbassador: true,
                      actionConfirm: 'Add Ambassador',
                      currentData: JSON.parse(JSON.stringify(expert)),
                    })
                  }}
                >
                  Add Ambassador
                </Button>
                :
                <Button
                  className="mx-1"
                  color="danger"
                  onClick={() => {
                    this.setState({
                      isOpenConfirm: true,
                      actionConfirm: 'Delete Ambassador',
                      messageConfirm: 'Are you sure want to delete ambassador for ' + expert.name + '?',
                      currentData: JSON.parse(JSON.stringify(expert)),
                    })
                  }}
                >
                  Delete Ambassador
                </Button>
            }
          </>
          : ''
        }
      </>
    )
  }

  onCloseModalConfirm = () => {
    this.setState({
      isOpenConfirm: false,
      actionConfirm: '',
      messageConfirm: '',
      currentData: null,
      isAddAmbassador: false,
      isSubmit: false,
      waiting: false,
    })
  }


  onActionConfirm = async () => {
    let response = null;
    if (this.state.actionConfirm == 'UnApprove') {
      this.setState({ waiting: true });
      response = await expertService.unapproveExpert({
        expert_id: this.state.currentData.id,
      })
      this.setState({ waiting: false });
      if (response && response.success) {
        toast.success(this.state.actionConfirm + " success");
        this.onCloseModalConfirm();
        this.loadData();
      }
    } else if (this.state.actionConfirm == 'Delete Ambassador') {
      await this.onClickAddAmbassador();
      this.onCloseModalConfirm();
    }
  }

  handleInputSearchData = (type, value) => {
    let currentSearch = { ...this.state.currentSearch }
    currentSearch[[type]] = value
    this.setState({ currentSearch: currentSearch }, () => {
      this.loadData()
    });
  }

  handleInput = (type, value) => {
    let currentData = { ...this.state.currentData }
    currentData[[type]] = value
    this.setState({ currentData: currentData })
  }

  onClickAddAmbassador = async () => {
    this.setState({ isSubmit: true, messageError: '' });
    if (this.state.currentData.ambassador_id) {
      this.setState({ waiting: true });
      let dataForm = {
        expert_id: this.state.currentData.id,
        availability: this.state.currentData.availability ? 1 : 0,
        price: this.state.currentData.price ? parseFloat(this.state.currentData.price).toFixed(2) : '0',
        commission: this.state.currentData.commission ? parseFloat(this.state.currentData.commission) : '0',
        // minimum_charge: this.state.data.minimum_charge ? parseFloat(this.state.data.minimum_charge) : '0',
        title: this.state.currentData.title ? this.state.currentData.title : '',
        description: this.state.currentData.description ? this.state.currentData.description : '',
        ambassador_id: this.state.currentData.ambassador_id ? this.state.currentData.ambassador_id : '',
      };
      if (this.state.actionConfirm == 'Delete Ambassador') {
        dataForm.ambassador_id = '';
      }
      let response = await expertService.updateExpert(dataForm);
      if (response.success) {
        toast.success(this.state.actionConfirm + " success");
        this.loadData();
        this.onCloseModalConfirm();
      } else {
        let error = this.state.actionConfirm + " failed";
        if (response.error) {
          error = response.error
        }
        this.setState({ messageError: error })
      }
      this.setState({ isSubmit: false, waiting: false });
    }
  }

  onExportFile = async (type) => {
    let response = await reportService.exportExpertReport(type);
    if (response && response.success) {
      window.open(process.env.REACT_APP_BASE_URL + '/' + response.data);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sidekicks | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card className={`${this.state.waiting ? 'waitLoadingData' : ''}`}>
              <CardBody>
                <Row>
                  <Col xl="12" className="d-flex align-items-center pb-2">
                    <h4 className="mb-0 text-primary">Manage Sidekicks</h4>
                  </Col>
                  <Col xl="4" className="pt-2 pb-2">
                    <Input
                      type="search"
                      id="search"
                      className="form-control"
                      placeholder="Search..."
                      value={this.state.currentSearch.search}
                      onChange={e => {
                        this.handleInputSearchData("search", e.target.value)
                      }}
                    />
                  </Col>
                  <Col xl="3" className="pt-2 pb-2">
                    <Select
                      classNamePrefix="select"
                      defaultValue={this.state.currentSearch.template_account}
                      value={types.find((item) => { return item.value == this.state.currentSearch.template_account })}
                      isClearable={false}
                      isSearchable={false}
                      options={types}
                      onChange={e => { this.handleInputSearchData("template_account", e.value); }}
                      placeholder="Select Type"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col xl="5" className="d-flex justify-content-end align-items-end pt-2 pb-2">
                    <UncontrolledDropdown>
                      <DropdownToggle className="btn-custom-width d-flex justify-content-center align-items-center"
                        caret color="primary"><i style={{ paddingRight: '5px' }} className="bx bxs-download"></i> Export
                      </DropdownToggle>
                      <DropdownMenu style={{ minWidth: '100px', inset: 'unset', right: '0', textAlign: 'center', padding: 0 }}>
                        <Button
                          onClick={() => {
                            this.onExportFile('excel')
                          }}
                          color="success"
                          className="btn btn-secondary text-primary btn-custom-width cursor-pointer">
                          Excel
                        </Button>
                        <br />
                        <Button
                          onClick={() => {
                            this.onExportFile('pdf')
                          }}
                          color="warning"
                          className="btn btn-custom-width cursor-pointer"
                        >
                          PDF
                        </Button>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </Row>
                <Row className="pt-1">
                  <Col xl="12">
                    <DataGrid
                      dataSource={this.state.dxStore}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                    >
                      <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80}></Column>
                      <Column dataField="name" name="name" caption="Full Name" cellRender={this.customName}></Column>
                      <Column dataField="user_name" name="user_name" caption="User Name" cellRender={this.customUser}></Column>
                      <Column dataField="email" name="email" caption="Email"></Column>
                      <Column dataField="ambassador_name" name="ambassador_name" caption="Ambassador" cellRender={this.customAmbassador}></Column>
                      <Column dataField="categories_name" name="categories_name" width={200} caption="Categories"></Column>
                      <Column dataField="skills_name" name="skills_name" caption="Skills" width={250}></Column>
                      <Column dataField="commission" name="commission" caption="Commission" alignment={'center'}></Column>
                      <Column dataField="total_appointment_completed" name="total_appointment_completed" caption="Total Sessions Completed" alignment={'center'}></Column>
                      <Column dataField="stripe_active" name="stripe_active" width={150} caption="Stripe Status" cellRender={this.customStripStatus} alignment={'center'}></Column>
                      <Column dataField="rating" name="rating" caption="Rating" alignment={'center'}></Column>
                      <Column dataField="rating_total" name="rating_total" caption="Comment" alignment={'center'}></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'left'}></Column>
                      <Paging defaultPageSize={10} />
                      <Pager
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        allowedPageSizes={[10, 25, 50, 100]}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <ModalConfirm
              isOpen={this.state.isOpenConfirm}
              title={'Confirmation ' + this.state.actionConfirm}
              message={this.state.messageConfirm}
              textButton={this.state.actionConfirm}
              colorButton={"danger"}
              onCloseModal={() => {
                this.onCloseModalConfirm();
              }}
              onActionConfirm={async () => {
                await this.onActionConfirm()
              }}
            />
            <Modal
              isOpen={this.state.isAddAmbassador}
              role="dialog"
              autoFocus={true}
              centered
              data-toggle="modal"
              backdrop='static'
              toggle={() => {
                this.onCloseModalConfirm()
              }}
            >
              <div>
                <ModalHeader
                  className="border-bottom-0"
                  toggle={() => {
                    this.onCloseModalConfirm()
                  }}
                ></ModalHeader>
              </div>
              <div className="modal-body pt-0">
                <div className="text-center mb-4">
                  <Row className=" justify-content-center">
                    {this.state.currentData ?
                      <Col xl="11">
                        <h4 className="text-primary mb-3">Add Ambassador</h4>
                        <Row>
                          <Col xl="12" style={{ textAlign: 'left' }} className="mb-4">
                            <Label for="user" style={{ "paddingLeft": "0" }}>Ambassador</Label>
                            <Select
                              name="user"
                              classNamePrefix="select"
                              defaultValue={this.state.currentData.ambassador_id ? this.state.currentData.ambassador_id : null}
                              value={this.state.ambassadorOptions.find((item) => { return item.value == this.state.currentData?.ambassador_id })}
                              isClearable={false}
                              isSearchable={true}
                              options={this.state.currentData && this.state.currentData.user_id ?
                                this.state.ambassadorOptions.filter(item => { return item.user_id != this.state.currentData.user_id })
                                : this.state.ambassadorOptions
                              }
                              onChange={e => { this.handleInput("ambassador_id", e.value) }}
                              placeholder="Select Ambassador"
                              className={this.state.isSubmit && !this.state.currentData.ambassador_id ? 'is-invalid' : ''}
                            />
                            {
                              this.state.isSubmit && !this.state.currentData.ambassador_id ?
                                <FormFeedback type="invalid" className="invalid-inline">
                                  Ambassador is required
                                </FormFeedback>
                                : ''
                            }
                          </Col>
                        </Row>
                        {this.state.messageError ?
                          <Row className="pb-2 text-danger">
                            <Col xl="12">
                              {this.state.messageError}
                            </Col>
                          </Row>
                          : ''}
                        <Button
                          className="mt-3 d-block mx-auto"
                          color="primary"
                          type="button"
                          id="button-create"
                          onClick={() => {
                            this.onClickAddAmbassador()
                          }}
                        >
                          Add Ambassador
                        </Button>
                      </Col>
                      : ''
                    }
                  </Row>
                </div>
              </div>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Expert
