import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { checkPermission} from 'helpers/supportFunction';
import TableSidekickAmbassadorReport from 'components/reports/tableSidekickAmbassadorReport';
import toast from 'helpers/toast';

class AmbassadorReport extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("report", "read")) {
      toast.warning("You do not have permission to manage reports", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Ambassador Report | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody style={{minHeight: '600px'}}>
                <Row>
                  <Col xl="12" className="d-flex align-items-end pb-2">
                    <h4 className="text-primary mb-0">Ambassador Report</h4>
                  </Col>
                </Row>
                <TableSidekickAmbassadorReport/> 
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default AmbassadorReport
