import axios from '../services/axios-service';

async function loginAdmin(body) {
  var urlencoded = new URLSearchParams(body)
  let data = await axios.post('/authAdmin', urlencoded);
  if (data.success && data.data) {
    localStorage.setItem("userSidekick", JSON.stringify(data.data));
    localStorage.setItem("authUser", JSON.stringify(data.data.sidekick_token));
  }
  return data;
}
export default {
  loginAdmin: loginAdmin,
}
