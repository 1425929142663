
const sub_menu_report = [
  { path: "/reports/user-report", name: "User Report", icon: "bx-user-circle", permissions: [{name: "user", actions: ['read']}, {name: "report", actions: ['read']}]},
  { path: "/reports/session-report", name: "Session Report", icon: "bx-history", permissions: [{name: "appointment", actions: ['read']}, {name: "report", actions: ['read']}]},
  { path: "/reports/ambassador-report", name: "Ambassador Report", icon: "bx bx-user-pin", permissions: [{name: "ambassador", actions: ['read']}, {name: "report", actions: ['read']}]},
];

const menu = [
  { path: "/dashboard", name: "Dashboard", icon: "bx-home", permissions: []},
  { path: "/sidekicks-request", name: "Sidekicks Requesting", icon: "bx bx-user-voice", permissions: [{name: "expert", actions: ['read']}]},
  { path: "/sidekicks", name: "Sidekicks", icon: "bxs-user-voice", permissions: [{name: "expert", actions: ["read"]}]},
  { path: "/sidekicks-featured", name: "Banner Featured", icon: "bx bx-images", permissions: [{name: "expert-featured", actions: ['read']}]},
  { path: "/sidekick-ambassadors", name: "Sidekick Ambassadors", icon: "bx bx-user-pin", permissions: [{name: "ambassador", actions: ['read']}]},
  { path: "/users", name: "Users", icon: "bx-user-circle", permissions: [{name: "user", actions: ['read']}]},
  { path: "/sessions", name: "Sessions", icon: "bx-history", permissions: [{name: "appointment", actions: ['read']}]},
  { path: "/payments", name: "Payments", icon: "bx-credit-card", permissions: [{name: "payment", actions: ['read']}]},
  { path: "/reports", name: "Reports", icon: "bxs-report", permissions: [{name: "report", actions: ['read']}], 
    subMenu: {name: 'report', multi_links: ['user-report', 'session-report', 'ambassador-report'], menu: sub_menu_report}},
  { path: "/skills", name: "Skills", icon: "bx-cube-alt", permissions: [{name: "skill", actions: ['read']}]},
  { path: "/categories", name: "Categories", icon: "bx bx-buoy", permissions: [{name: "category", actions: ['read']}]},
];

const menu_admin = [
  { path: "/admins", name: "Admins", icon: "bxs-user-badge", permissions: [{name: "expert", actions: ['read']}]},
  { path: "/roles", name: "Roles", icon: "bx-link", permissions: [{name: "role", actions: ['read']}]},
  { path: "/settings", name: "Settings", icon: "bx-cog", permissions: [{name: "setting", actions: ['read']}]},
];

export {menu, menu_admin};