import React, { Component } from "react"
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags"
import FormExpertFeatured from "components/experts_featured/FormExpertFeatured";
import { checkPermission } from 'helpers/supportFunction';
import toast from 'helpers/toast';

class ExpertFeaturedCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataForm: {
        image: null,
        expert_id: null,
        category_id: null
      },
    }
  }

  async componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("expert-featured", "create")) {
      toast.warning("You do not have permission to manage banner featured", "Invalid Permission");
      setTimeout(() => {
        window.location = '/sidekicks-featured';
      }, 3000);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Create Banner Featured | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="6" md="6" xs="6">
                    <h4 className="mt-2 mb-2 text-primary">Create Banner Featured</h4>
                  </Col>
                  <Col xl="6" md="6" xs="6" className="text-right">
                    <Link to="/sidekicks-featured">
                      <Button className="btn-custom-width"> Back </Button>
                    </Link>
                  </Col>
                  <Col xl="12" className="pt-2">
                    <FormExpertFeatured
                      dataInput={this.state.dataForm}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
export default ExpertFeaturedCreate;
