import React, { memo } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  Label,
  Button
} from "reactstrap"

const modalConfirm = props => {
  const { isOpen, title, message, textButton, colorButton, onCloseModal, onActionConfirm, showCancel, textCancel } = props
  
  return (
    <>
      <Modal
        role="dialog"
        isOpen={isOpen}
        autoFocus={true}
        centered
        data-toggle="modal"
        backdrop={'static'}
        toggle={() => onCloseModal()}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => onCloseModal()}
          ></ModalHeader>
        </div>
        <div className="modal-body" style={{"paddingTop": "0"}}>
          <div className="mb-4">
            <Row className="justify-content-center">
              <Col xl="11">
                <h4 className="text-primary text-center mb-4">{title}</h4>
                <Row>
                  <Label style={{"paddingLeft": "0"}} className="text-center">{message}</Label>
                  {
                    showCancel ? 
                    <div className="col-6">
                      <Button
                        className="mt-3 d-block mx-auto w-100"
                        color={'secondary'}
                        type="button"
                        onClick={() => {
                          onCloseModal()
                        }}
                      >
                        {textCancel ? textCancel : 'Cancel'}
                      </Button>
                    </div>
                    
                    : ''
                  }
                  <div className={showCancel ? 'col-6' : 'col-12'}>
                    <Button
                      className="mt-3 d-block mx-auto w-100"
                      color={colorButton ?? 'primary'}
                      type="button"
                      onClick={() => {
                        onActionConfirm()
                      }}
                    >
                      {textButton}
                    </Button>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  )
}

modalConfirm.propTypes = {
  isOpen: PropTypes.any,
  title: PropTypes.any,
  textButton: PropTypes.any,
  colorButton: PropTypes.any,
  message: PropTypes.any,
  onActionConfirm: PropTypes.any,
  onCloseModal: PropTypes.any,
  showCancel: PropTypes.any,
  textCancel: PropTypes.any,
}

export default memo(modalConfirm)
